/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import LaunchIcon from "@mui/icons-material/Launch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { useDialog } from "../../common/providers/DialogProvider";
import { ExhibitPreview } from "@cirrux888/huseby-client-exhibit-editor";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { getExhibitIcon } from "../../../hooks/useFileIcons";
import ImageVideoViewer from "../../../components/common/ViewerModal/ImageVideoViewer";

// Exhibit Modes
const ADVANCED_MODE = 1;
const SIMPLE_MODE = 2;
const NO_EXHIBITS = 3;

const useStyles = makeStyles(() => ({
  exhibitCell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  nameColumn: {
    width: "100%",
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ExhibitMenuButton = ({
  fileId,
  exhibitName,
  description,
  permissions,
  fileType,
  folderId,
  bookmarked = false,
  fileSize,
  allowRetract,
  onExhibitSelected,
  isDeleteEnabled,
  currentFolder = null,
  setShowPreview,
  setPublishExhibitData,
  exhibitNumber,
}) => {
  const classes = useStyles();
  const {
    setExhibitForm,
    setExhibitEditor,
    setDownloadExhibit,
    setDeleteExhibits,
    setRetractExhibit,
    setShareExhibits,
  } = useDialog();
  const { data, setData, addBookmark, removeBookmark, downloadFile, editorObject } =
    React.useContext(ExhibitsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isBookmarked, setIsBookmarked] = useState(bookmarked);
  const [openPreview, setOpenPreview] = useState(false);
  const isOnline = useOnlineStatus();

  const handleExhibitClick = async () => {
    setData({ clear: "selectedExhibit" });
    await setData({
      selectedExhibit: {
        fileId: fileId,
        name: exhibitName,
        description: description,
        fileSize: fileSize,
      },
    });

    setOpenPreview(true); // open preview dialog
  };

  const handleOnClosePreview = () => {
    setOpenPreview(false);
  };

  const handleExhibitMenuClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditExhibitClick = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setExhibitForm({
      open: true,
      exhibit: {
        fileId: fileId,
        name: exhibitName,
        description: description,
        fileSize: fileSize,
      },
    });
  };

  const handleDownloadExhibit = async () => {
    setAnchorEl(null);
    setDownloadExhibit({
      open: true,
      file: {
        fileId: fileId,
        name: exhibitName,
        description: description,
        fileType: fileType,
        fileSize: fileSize,
      },
    });
  };

  const handleShareExhibit = async () => {
    setAnchorEl(null);
    setShareExhibits({
      open: true,
      selectedFiles: [
        {
          fileId: fileId,
          name: exhibitName,
          description: description,
          fileType: fileType,
          fileSize: fileSize,
        },
      ],
    });
  };

  const handleExhibitEditor = () => {
    setAnchorEl(null);
    setExhibitEditor({
      open: true,
      fileId: fileId,
    });
    setData({ clear: "launchedExhibit" });
    setData({
      launchedExhibit: {
        fileId: fileId,
        name: exhibitName,
        description: description,
        fileSize: fileSize,
      },
    });
  };

  const handlePublishExhibit = (e) => {
    setAnchorEl(null);
    setOpenPreview(false);
    setPublishExhibitData({
      fileId: fileId,
      name: exhibitName,
      description: description,
      fileSize: fileSize,
      fileType: fileType,
      folderId: folderId,
      existingExhibitNumber: exhibitNumber,
    });

    setShowPreview(true);
  };

  const handleRetractExhibit = () => {
    setAnchorEl(null);
    setRetractExhibit({
      open: true,
      selectedFiles: [
        {
          fileId: fileId,
          name: exhibitName,
          description: description,
          fileSize: fileSize,
          fileType: fileType,
          folderId: folderId,
        },
      ],
      folderId: folderId,
    });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteExhibits({
      open: true,
      selectedFiles: [
        {
          fileId: fileId,
          name: exhibitName,
          description: description,
          fileSize: fileSize,
          fileType: fileType,
          folderId: folderId,
        },
      ],
      folderId: folderId,
    });
  };

  const handleAddBookmark = async () => {
    await addBookmark([fileId]);
    setIsBookmarked(true);
  };

  const handleRemoveBookmark = async () => {
    await removeBookmark([fileId]);
    setIsBookmarked(false);
  };

  const hasAnyPermissions = () => {
    return (
      permissions?.grantCreateUpdate ||
      permissions?.grantShare ||
      permissions?.grantDelete ||
      permissions?.grantDownload
    );
  };

  const imageIcon = useMemo(() => {
    return getExhibitIcon(exhibitName);
  }, [exhibitName]);

  return (
    <>
      <Box className={classes.nameColumn}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          draggable={permissions?.grantCreateUpdate}
        >
          <Tooltip title="Click to preview Exhibit." placement="right-start">
            <IconButton
              color="primary"
              aria-label="preview-exhibit"
              component="span"
              size="small"
              onClick={handleExhibitClick}
            >
              {imageIcon}
            </IconButton>
          </Tooltip>
          <Link id={`exhibit:${fileId}`} className={classes.link} onClick={handleExhibitClick}>
            {exhibitName}
          </Link>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80px",
            justifyContent: "flex-end",
          }}
        >
          {editorObject.isMeetingRoom &&
            data?.eventData.exhibits === ADVANCED_MODE &&
            exhibitName?.includes(".pdf") &&
            !currentFolder?.isUnderFinalExhibit && (
              <Tooltip
                title={
                  isOnline
                    ? "Launch Exhibit Editor"
                    : "There was an issue connecting to the internet. Please check your connection."
                }
                placement="right-start"
              >
                <Box component="span">
                  <IconButton
                    color="primary"
                    aria-label="launch-exhibit-editor"
                    component="span"
                    size="small"
                    onClick={handleExhibitEditor}
                    disabled={!isOnline}
                  >
                    <LaunchIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}

          {permissions?.grantShare && !currentFolder?.isUnderFinalExhibit && (
            <Tooltip
              title={
                isOnline
                  ? "Publish Exhibit"
                  : "There was an issue connecting to the internet. Please check your connection."
              }
              placement="right-start"
            >
              <Box component="span">
                <IconButton
                  color="primary"
                  aria-label="publish-exhibit"
                  component="span"
                  size="small"
                  onClick={handlePublishExhibit}
                  disabled={!isOnline}
                >
                  <FolderSpecialIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}

          {/* {isBookmarked ? (
            <Tooltip title="Remove bookmark" placement="right-start">
              <IconButton
                color="primary"
                aria-label="remove-bookmark"
                component="span"
                size="small"
                onClick={handleRemoveBookmark}
              >
                <StarIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add bookmark" placement="right-start">
              <IconButton
                color="primary"
                aria-label="remove-bookmark"
                component="span"
                size="small"
                onClick={handleAddBookmark}
              >
                <StarBorderIcon />
              </IconButton>
            </Tooltip>
          )} */}
          {(hasAnyPermissions() || allowRetract) && (
            <Tooltip title="More options" placement="right-start">
              <IconButton
                color="primary"
                aria-label="exhibit-menu"
                component="span"
                size="small"
                onClick={handleExhibitMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          )}

          {exhibitName && exhibitName?.includes(".pdf") && openPreview ? (
            <ExhibitPreview
              open={openPreview}
              eventId={data.eventId}
              fileId={fileId}
              presenter={data.identity.username}
              onLaunchExhibitEditor={handleExhibitEditor}
              onClosePreview={handleOnClosePreview}
              isMeetingRoom={editorObject.isMeetingRoom}
              exhibitMode={data?.eventData.exhibits}
              onPublishExhibitInSimpleMode={handlePublishExhibit}
            />
          ) : (
            <ImageVideoViewer
              eventId={data.eventId}
              fileId={fileId}
              open={openPreview}
              onClosePreview={handleOnClosePreview}
              exhibitNumber={exhibitNumber}
              exhibitName={exhibitName}
            />
          )}
        </Box>
      </Box>

      <Menu
        id="exhibit-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {permissions?.grantCreateUpdate && (
          <MenuItem onClick={handleEditExhibitClick}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}
        {permissions?.grantDownload && (
          <MenuItem onClick={handleDownloadExhibit}>
            <ListItemIcon>
              <CloudDownloadIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </MenuItem>
        )}
        {/* {isBookmarked ? (
          <MenuItem onClick={handleRemoveBookmark}>
            <ListItemIcon>
              <StarIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Remove Bookmark" />
          </MenuItem>
        ) : (
          <MenuItem onClick={handleAddBookmark}>
            <ListItemIcon>
              <StarBorderIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Add Bookmark" />
          </MenuItem>
        )} */}

        {permissions?.grantShare && (
          <MenuItem onClick={handleShareExhibit}>
            <ListItemIcon>
              <ShareIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}

        {permissions?.grantShare &&
          data?.eventData.exhibits === ADVANCED_MODE &&
          exhibitName?.includes(".pdf") &&
          editorObject.isMeetingRoom && (
            <MenuItem onClick={handleExhibitEditor} disabled={!isOnline}>
              <ListItemIcon>
                <LaunchIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Launch Exhibit Editor" />
            </MenuItem>
          )}

        {/* If we are in simple mode, then add the "Publish Exhibit" menu item */}
        {permissions?.grantShare &&
          // data?.eventData.exhibits === SIMPLE_MODE &&
          !currentFolder?.isUnderFinalExhibit && (
            <MenuItem onClick={handlePublishExhibit} disabled={!isOnline}>
              <ListItemIcon>
                <FolderSpecialIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Publish Exhibit" />
            </MenuItem>
          )}
        {allowRetract && currentFolder?.isUnderFinalExhibit && (
          <MenuItem onClick={handleRetractExhibit} disabled={!isOnline}>
            <ListItemIcon>
              <KeyboardBackspaceIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Retract" />
          </MenuItem>
        )}
        {isDeleteEnabled && permissions?.grantDelete && <Divider />}
        {isDeleteEnabled && permissions?.grantDelete && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

ExhibitMenuButton.defaultProps = {
  isDeleteEnabled: true,
};

export default ExhibitMenuButton;
