import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, CircularProgress, CssBaseline, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import Exhibits from "./exhibitsList/Exhibits";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { ExhibitsContext } from "../../services/ExhibitsService";
import { useSocketIOService } from "@cirrux888/huseby-client-exhibit-editor";
import socket, {
  TYPE_MESSAGE,
  TYPE_EXHIBIT_LAUNCHED,
  TYPE_EXHIBIT_EDITOR_CLOSED,
  TYPE_BROWSING_EXHIBITS,
} from "./useSocket";
import { DialogProvider } from "../../components/common/providers/DialogProvider";

// Exhibit Modes
const ADVANCED_MODE = 1;
const SIMPLE_MODE = 2;
const NO_EXHIBITS = 3;

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "calc(100vh - 55px)",
    width: "100vw",
  },
  splashTitle: {
    fontSize: 50,
  },
  subTitle: {
    fontSize: 20,
    textAlign: "center",
  },
  progress: {
    color: "#71ee33",
  },
});

const Index = ({
  eventId,
  onExhibitEditorLaunched,
  style,
  isMeetingRoom,
  onExhibitManagerClosed,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { getIdentity } = useContext(AuthContext);
  const { data, setData, initializeApp } = useContext(ExhibitsContext);
  const [selectedExhibit, setSelectedExhibit] = useState(null);
  const [launchedExhibit, setLaunchedExhibit] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [openExhibitEditor, setOpenExhibitEditor] = useState(false);
  // const [isHost, setIsHost] = React.useState(null);
  const { data: socketData, setData: setSocketData } = useSocketIOService();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [folderId, setFolderId] = useState(-1);
  const [forbidden, setForbidden] = useState(false);

  React.useEffect(() => {
    async function fetchData() {
      let folderId = new URLSearchParams(location.search).get("folderId");
      setFolderId(folderId);
      try {
        await initializeApp({ eventId, folderId, isMeetingRoom });
      } catch (error) {
        console.log("Error detected while trying to listing exhibits!", error);
        if (error?.response?.status === 403) {
          setForbidden(true);
        }
      }
    }
    fetchData();
  }, [eventId]);

  React.useEffect(() => {
    const _folderId = new URLSearchParams(location.search).get("folderId");
    setData({ folderId: _folderId });
  }, [location.search]);

  React.useEffect(() => {
    setData({ folderId: data.folderId });
  }, [data.folderId]);

  React.useEffect(() => {
    // console.log("Web sockets are enabled.");
    socket.on(TYPE_MESSAGE, (msg) => {
      console.log("Web socket message received...", msg);
      if (msg.type === TYPE_EXHIBIT_LAUNCHED) {
        setFileId(msg.data.fileId);
        setOpenExhibitEditor(true);
      } else if (msg.type === TYPE_EXHIBIT_EDITOR_CLOSED) {
        setOpenExhibitEditor(false);
      } else if (msg.type === TYPE_BROWSING_EXHIBITS) {
        console.log("Display splash screen");
      }
    });
  }, []);

  React.useEffect(() => {
    setSelectedExhibit(data.selectedExhibit);
  }, [data.selectedExhibit]);

  React.useEffect(() => {
    // Check data.launchedExhibit for undefined since we set it to 'null' to close it.
    // We still want a null launchedExhibit to be used to close the ExhibitEditor.
    if (data.launchedExhibit === undefined) return;
    setLaunchedExhibit(data.launchedExhibit);

    if (data.isHost && !isNil(data.launchedExhibit)) {
      const message = {
        username: getIdentity().username,
        type: TYPE_EXHIBIT_LAUNCHED,
        data: {
          fileId: data.launchedExhibit.fileId,
        },
      };
      socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
    } else if (data.isHost && isNil(data.launchedExhibit)) {
      handleExhibitEditorClose();
    }

    onExhibitEditorLaunched(data.launchedExhibit);
  }, [data.launchedExhibit]);

  /**
   * Emit the Exhibit Editor closed event.
   *
   * @param {*} e
   */
  const handleExhibitEditorClose = (e) => {
    if (socketData.isHost) {
      setOpenExhibitEditor(false);
      const message = {
        username: getIdentity().username,
        type: TYPE_EXHIBIT_EDITOR_CLOSED,
        data: null,
      };
      socket.emit(TYPE_MESSAGE, { data: message, roomId: eventId });
    }
  };

  return (
    <>
      <div style={style}>
        <CssBaseline />
        {/* If the user does not have permissions to list the exhibits, then the forbidden 
            flag is set and they do not have permissions to view the exhibits. */}
        {forbidden && (
          <Box
            style={{
              color: "#000000",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/images/error_403.jpeg" height="25%" width="25%" />
            <br />
            <br />
            <h2 style={{ width: "500px", textAlign: "center" }}>
              Unfortunately, you do not have permissions to view this exhibit.
              <br />
              <br />
              Please contact Huseby Support for assistance.
            </h2>
          </Box>
        )}

        {!forbidden && data.isHost === false && data.isAuthorized === false && (
          <NotAuthorizedMessage />
        )}

        {/* If the ExhibitManager is being launched in the Event application, then check if the 
            logged-in user has permissions and is a host.  If they are, then allow them to access 
            the ExhibitManager. 

            If the ExhibitManager is being launched in the Portal application, then check if the 
            logged-in user has permissions.  They do not have to be a meeting host because the 
            folder link could be shared out with them.
          */}
        {!forbidden &&
          data.isAuthorized === true &&
          (!isMeetingRoom ||
            (isMeetingRoom && data.isHost) ||
            data?.eventData?.exhibits === SIMPLE_MODE) && (
            <DialogProvider style={style}>
              <Box
                style={{ height: "calc(100% - 20px)", width: "100%" }}
                display="flex"
                flexDirection="row"
              >
                <Exhibits
                  folderId={data.folderId}
                  onExhibitManagerClosed={onExhibitManagerClosed}
                />
              </Box>
            </DialogProvider>
          )}
        {!forbidden && data.isHost === null && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
          >
            <CircularProgress size={50} className={classes.progress} />
            <br />
            <br />
            <br />
            Loading...
          </Box>
        )}
        {!forbidden && data.isHost === false && data.isAuthorized === true && isMeetingRoom && (
          <ParticipantSplash />
        )}
      </div>
    </>
  );
};

/**
 * Not Authorized message.
 *
 * @returns
 */
export const NotAuthorizedMessage = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
      >
        <Typography className={classes.splashTitle}>Not Authorized for Event</Typography>
        <Typography className={classes.subTitle}>
          You do not have permissions to access this Event. Please contact the Event Host to add you
          as a participant.
          <br />
          <br />
        </Typography>
      </Box>
    </>
  );
};

/**
 * The Participant Splash screen.
 *
 * @returns
 */
export const ParticipantSplash = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
      >
        <Typography className={classes.splashTitle}>Your Host is selecting an Exhibit.</Typography>
        <Typography className={classes.subTitle}>
          Thank you for your patience while your host selects an Exhibit to share with you.
          <br />
          <br />
        </Typography>
      </Box>
    </>
  );
};

export { Index };
