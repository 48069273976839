/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Breadcrumbs, IconButton, Link, Tooltip } from "@mui/material";
import { forEach, isNil, merge } from "lodash";
import { makeStyles } from "@mui/styles";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    fontSize: "10pt",
  },
  link: {
    display: "flex",
    fontSize: 12,
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
}));

const BreadcrumbTrail = ({ onBreadcrumbClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = React.useContext(ExhibitsContext);
  const [breadcrumbTrail, setBreadcrumbTrail] = React.useState([]);
  const [rootFolderId, setRootFolderId] = React.useState(-1);

  useEffect(() => {
    if (data.rootResource != null) setRootFolderId(data.rootResource.fileId);
  }, [data.rootResource]);

  useEffect(() => {
    let tmp = [];
    forEach(data.breadcrumbs, (bc) => {
      tmp.push(
        <Link
          key={bc.fileId}
          color="primary"
          // onClick={(e) => history.push(`/exhibits/${bc.fileId}`)}
          onClick={(e) => history.push(`?folderId=${bc.fileId}`)}
          className={classes.link}
        >
          {bc.fileType === 0 && <FolderIcon className={classes.icon} />}
          {bc.fileType === 1 && <PictureAsPdfIcon className={classes.icon} />}
          {bc.name}
        </Link>
      );
    });
    setBreadcrumbTrail(tmp);
  }, [data.breadcrumbs]);

  const handleUpOneLevel = async () => {
    const last = data.breadcrumbs.length - 1;
    if (last > 0) {
      const folderId = data.breadcrumbs[last].folderId;
      // history.push(`/exhibits/${folderId}`)
      history.push(`?folderId=${folderId}`);
    }
  };

  const isRootLevel = () => {
    return data?.breadcrumbs?.length - 1 === 0;
  };

  return (
    <Box display="flex" flexDirection="row">
      {!isRootLevel() && (
        <Tooltip title="Up one level..." placement="right-start">
          <Link onClick={handleUpOneLevel}>
            <SubdirectoryArrowLeftIcon
              color="primary"
              className={classes.icon}
              style={{ transform: "rotate(90deg)" }}
            />
          </Link>
        </Tooltip>
      )}
      <Breadcrumbs>{breadcrumbTrail}</Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbTrail;
