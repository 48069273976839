/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { io } from "socket.io-client";

const URL = `${process.env.REACT_APP_SOCKETIO_URL}`;
const socket = io(URL, { autoConnect: false });

// Socket IO Event Types
export const TYPE_MESSAGE = "ExhibitManager.message";

// Socket IO Event Subtypes
export const TYPE_EXHIBIT_LAUNCHED = "exhibitLaunched";
export const TYPE_EXHIBIT_EDITOR_CLOSED = "exhibitEditorClosed";
export const TYPE_BROWSING_EXHIBITS = "browsingExhibits";
export const TYPE_EXHIBIT_NUMBER_UPDATED = "exhibitNumberUpdated";

// export let room = {
//   roomId: null,
//   name: null,
//   username: null,
//   role: null,
//   attendees: [],
// };

// export let attendees = [];

// socket.on("connect", () => {
//   setIsConnected(true);
// });
// socket.on("disconnect", () => {
//   setIsConnected(false);
// });

// socket.on("users", (users) => {
//   console.log("users", users);
//   attendees = users;
// });

// socket.on("user connected", (user) => {
//   console.log("user connected", user);
// });

// socket.on("roleChanged", (data) => {
//   console.log("role changed", data);
//   // if (data.username === room.username) setPermissions(data.role);
// });

// export const emitRoleChanged = (e) => {
//   console.log("emitRoleChanged", e);
//   const message = {
//     userID: e.userID,
//     username: e.username,
//     role: e.role,
//   };
//   socket.emit("roleChanged", { data: message, roomId: room.roomId });
// };

export default socket;
