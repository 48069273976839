import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Close, Check, Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useExhibitsService } from "../../../services/ExhibitsService";
import socket, { TYPE_MESSAGE, TYPE_EXHIBIT_NUMBER_UPDATED } from "../useSocket";

// Exhibit Modes
const SIMPLE_MODE = 2;

const CssTextField = withStyles({
  root: {
    "& .MuiInput-input:focus": {
      paddingBottom: "14px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#af1730",
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  textField: {
    marginLeft: 2,
    marginRight: 2,
    width: 450,
    color: "black",
    fontSize: "0.875rem",
    opacity: 1,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
    "&:after": {
      color: "red",
    },
  },
  disabled: {
    color: "black",
    borderBottom: 0,
    textAlign: "center",
    "&:before": {
      borderBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  btnIcons: {
    marginLeft: 10,
  },
}));

const EditableTextField = ({ value, name, handleUpdateExhibitNumber, fileId }) => {
  const classes = useStyles();
  const { data } = useExhibitsService();
  const [editMode, setEditMode] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [inputVal, setInputVal] = useState(value);
  const [currentInput, setCurrentInput] = useState(value);

  React.useEffect(() => {
    setCurrentInput(value);
  }, [value]);

  const textAreaRef = useCallback(
    (ref) => {
      !editMode ? ref?.focus() : ref?.blur();
    },
    [fileId]
  );

  const handleChange = (e) => {
    console.log("handleChange");
    setCurrentInput(e.target.value);
  };

  const handleMouseOver = (event) => {
    if (!mouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseOut = (event) => {
    if (mouseOver) {
      setMouseOver(false);
    }
  };

  const handleClick = () => {
    if (!editMode) {
      setEditMode(true);
      setMouseOver(false);
      textAreaRef();
    }
  };

  const handleSave = async (e) => {
    try {
      await handleUpdateExhibitNumber(value, currentInput, fileId);
      setInputVal(currentInput);
      textAreaRef();

      // Emit the TYPE_EXHIBIT_NUMBER_UPDATED msg
      console.log(
        "Emit the TYPE_EXHIBIT_NUMBER_UPDATED msg...",
        data?.eventData?.exhibits,
        SIMPLE_MODE
      );
      if (data?.eventData?.exhibits === SIMPLE_MODE) {
        const message = {
          username: data.identity,
          type: TYPE_EXHIBIT_NUMBER_UPDATED,
          data: {
            fileId: fileId,
            exhibitNumber: currentInput,
          },
        };
        console.log("Sending web socket message....", message);
        socket.emit(TYPE_MESSAGE, { data: message, roomId: data.eventId });
      }
    } catch {
      setInputVal(value);
      setCurrentInput(value);
    } finally {
      setEditMode(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setInputVal(value);
    setCurrentInput(value);
    textAreaRef();
  };

  const textField = (
    <CssTextField
      key={`${name}-${fileId}`}
      onClick={() => {
        handleClick();
      }}
      inputRef={textAreaRef}
      name={name}
      defaultValue={inputVal}
      value={currentInput}
      onChange={handleChange}
      onKeyPress={async (e) => {
        if (e.key === "Enter") {
          try {
            handleSave(e);
          } catch {
            setInputVal(value);
          } finally {
            setEditMode(false);
          }
        }
      }}
      sx={{
        border: 0,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "black",
        },
      }}
      disabled={!editMode}
      className={classes.textField}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      InputProps={{
        classes: {
          disabled: classes.disabled,
          notchedOutline: classes.noBorder,
        },
        endAdornment:
          mouseOver && !editMode ? (
            <InputAdornment position="start">
              <IconButton onClick={handleClick} size="small">
                <Edit fontSize="small" sx={{ color: "#af1730" }} />
              </IconButton>
            </InputAdornment>
          ) : editMode ? (
            <InputAdornment position="start">
              <IconButton size="small">
                <Close onClick={handleCancel} fontSize="small" sx={{ color: "#af1730" }} />
              </IconButton>
              <IconButton size="small">
                <Check onClick={handleSave} fontSize="small" sx={{ color: "#af1730" }} />
              </IconButton>
            </InputAdornment>
          ) : (
            ""
          ),
      }}
    />
  );

  return (
    <>
      {!editMode ? (
        <Tooltip title="Click to edit the Exhibit Number" placement="right">
          {textField}
        </Tooltip>
      ) : (
        textField
      )}
    </>
  );
};

export default EditableTextField;
