/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { merge } from "lodash";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  fileId: null,
  filePermissions: []  
};

const FilePermissionsContext = React.createContext();

const FilePermissionsProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  

  /**
   * List File Permissions.
   *
   * @param {*} fileId
   * @param {*} clearCache
   * @returns
   */
  const LIST_PAGE_LENGTH = 15;
  const listFilePermissions = async (
    {fileId,
    searchQuery = "",
    pageNumber = 0,
    pageLength = LIST_PAGE_LENGTH,
    sortParam = "user.firstName:asc",
    clearCache = true}) => {
    // Now get the list of file permissions for the current folder
    const config = {
      method: "get",
      url: `/sf/permissions/${fileId}?pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,
    };

    clearCache && setData({ clear: "filePermissions" });
    clearCache && setData({ clear: "filePermissionsListParams" });
    if (searchQuery.indexOf(" ") != -1) { 
      config.url += `&firstName=${searchQuery.split(" ")[0]}`;
      config.url += `&lastName=${searchQuery.split(" ")[1]}`;
      config.url += `&matchAll=true`;
    } else if (searchQuery.indexOf(" ") == -1) {
      config.url += `&firstName=${searchQuery}`;
      config.url += `&lastName=${searchQuery}`;
      config.url += `&email=${searchQuery}`;
      config.url += `&matchAll=false`;
    }

    try {
      setData({ loading: true });
       const { data: filePermissions } = await http(config);
       setData({ 
        filePermissions: filePermissions.content,
        filePermissionsListParams: {
           searchQuery,
           pagination: {
             totalElements: filePermissions .totalElements,
             totalPages: filePermissions .totalPages,
             pageNumber: filePermissions .number,
           },
         },
       });

      return filePermissions;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Fetch a single FilePermissions entry.
   * 
   * @param {*} fileId 
   * @param {*} contactId 
   * @returns 
   */
  const getFilePermissions = async (fileId, contactId) => {
    const config = {
      method: "get",
      url: `/sf/permissions/${fileId}/${contactId}`,
    };

    try {
      setData({ loading: true });
      const { data: filePermissions } = await http(config);
      return filePermissions;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Fetch the FilePermissions entry for the root folder that the fileId is in.
   * 
   * @param {*} fileId 
   * @param {*} contactId 
   * @returns 
   */
     const getRootFilePermissions = async (fileId) => {
      const config = {
        method: "get",
        url: `/sf/permissions/${fileId}/root`,
      };
  
      try {
        setData({ loading: true });
        const { data: filePermissions } = await http(config);
        return filePermissions;
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => setData({ loading: false }), DELAY);
      }
    };

  /**
   * Add an array of FilePermissions.
   * 
   * @param {*} fileId 
   * @param {*} filePermissions 
   */
  const addFilePermissions = async (fileId, filePermissions = []) => {
    const config = {
      method: "post",
      url: `/sf/permissions/${fileId}`,
      data: filePermissions
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Update an array of FilePermissions.
   * 
   * @param {*} fileId 
   * @param {*} filePermissions 
   */
   const updateFilePermissions = async (fileId, filePermissions = []) => {
    const config = {
      method: "put",
      url: `/sf/permissions/${fileId}`,
      data: filePermissions
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Remove an array of Contacts from the specified File.
   * 
   * @param {*} fileId 
   * @param {*} contactIds
   */
  const removeFilePermissions = async (fileId, contactIds = []) => {
    const config = {
      method: "delete",
      url: `/sf/permissions/${fileId}`,
      data: contactIds
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * List the firm members for the specified firm. 
   * 
   * @param {*} param0 
   * @returns 
   */
  const listFirmMembers = async ({ pageIndex = 0, pageLength = 1000, sortParam = "firstName:asc", query, firmId, status }) => {
    const _contacts = await listContacts({
      pageIndex,
      pageLength,
      sortParam,
      firstName: query,
      lastName: query,
      email: query,
      firmName: query,
      firmId,
      contactType: "firm-contacts",
      rbNumber: query,
      firmContactType: query,
      status
    });
    setData({ firmContacts: _contacts });
    return _contacts;
  };

  const listContacts = async ({
    pageIndex = 0,
    pageLength = 1000,
    sortParam = "firstName:asc", 
    firstName,
    lastName,
    email,
    firmName,
    firmId,
    contactType,
    contactTypeId,
    rbNumber,
    firmContactType,
    status
  }) => {
    console.log("XXX listContacts", sortParam);
    const config = {
      method: "get",
      url: `/hc/contacts`,
    };

    if (contactType) {
      config.url += `/${contactType}?`;
    } else {
      config.url += `?`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (pageLength) {
      config.url += `&pageLength=${pageLength}`;
    }

    if (sortParam) { 
      config.url += `&sortParam=${sortParam}`;
    }

    if (contactTypeId) {
      config.url += `&contactTypeId=${contactTypeId}`;
    }

    if (firstName) {
      config.url += `&firstName=${firstName}`;
      // config.url += `&name=${firstName}`;
    }

    if (firstName || lastName) {
      // config.url += `&firstName=${firstName}`;
      config.url += `&name=${firstName}`;
    }

    if (config.url.indexOf("?") != -1) config.url += "&pageLength=1000";
    else config.url += "?pageLength=1000";
    if (lastName) {
      config.url += `&lastName=${lastName}`;
      // config.url += `&name=${lastName}`;
    }

    if (email) {
      config.url += `&email=${email}`;
    }

    if (firmName) {
      config.url += `&firmName=${firmName}`;
    }

    if (firmId) {
      config.url += `&firmId=${firmId}`;
    }

    // if (rbNumber) {
    //   config.url += `&rbNumber=${rbNumber}`;
    // }

    if (firmContactType) {
      config.url += `&firmContactType=${firmContactType}`;
    }
    if (status) {
      config.url += `&status=${status}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listUserParams: {
          // searchQuery,
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };

  const listEventParticipantContacts = async ({
    query,
    pageIndex = 0,
    pageLength = 1000,
    sortParam , 
    eventId,
  }) => {
    console.log("XXX listEventParticipantContacts");
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants`,
    };

    if (pageLength) {
      config.url += `?pageLength=${pageLength}`;
    }

    if (pageIndex) {
      config.url += `&pageIndex=${pageIndex}`;
    }

    if (query) {
      config.url += `&firstName=${query}`;
      config.url += `&name=${query}`;
      config.url += `&email=${query}`;
    }

    try {
      const { data: contacts } = await http(config);
      setData({
        contacts: contacts.content,
        listUserParams: {
          pagination: {
            totalElements: contacts.totalElements,
            totalPages: contacts.totalPages,
            pageNumber: contacts.number,
          },
        },
        loading: false,
      });

      return contacts;
    } catch (error) {
      console.error("error", { error });
      setData((prevState) => ({ ...prevState, contacts: [], loading: false, error: true }));
      return [];
    }
  };


  return (
    <FilePermissionsContext.Provider
      value={{
        data,
        setData,
        listFilePermissions,
        getFilePermissions,
        getRootFilePermissions,
        addFilePermissions,
        updateFilePermissions, 
        removeFilePermissions, 
        listFirmMembers,
        listEventParticipantContacts
      }}
    >
      {props.children}
    </FilePermissionsContext.Provider>
  );
};

const useFilePermissionsService = () => React.useContext(FilePermissionsContext);

export { FilePermissionsContext, FilePermissionsProvider, useFilePermissionsService };
