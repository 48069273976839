import React, { useEffect, useState, useContext, useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty, isNil } from "lodash";
import Joi from "joi-browser";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { validate, validateProperty, setErrorMessages } from "../../common/validateUtilities";
import { RecordingsContext } from "../../../services/RecordingsService";

const ERR_MSG_DUPLICATE_RECORDING_NAME =
  "There is a recording with this name already.   Please choose another name.";

const RecordingFormDialog = ({ open, handleClose, onSuccess, folder = null }) => {
  console.log("Inside recordingformDialog folder is", folder);
  const [dialogTitle, setDialogTitle] = useState("New Recording");
  const { updateRecording, listRecordings } = useContext(RecordingsContext);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = React.useState(null);
  const [serverError, setServerError] = React.useState(null);
  const [loading, setLoading] = useState(false);

  // submit button reference
  const submitBtn = useRef(null);

  useEffect(() => {
    if (folder?.fileId) setDialogTitle("Edit Recording");

    setErrors(null);
    setServerError(null);
  }, [open]);

  const schema = {
    name: Joi.string().required().trim(true).min(0).max(512).label("Name"),
    description: Joi.string().allow("").optional().trim(true).min(0).max(512).label("Description"),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const description = e.target.description.value;

    try {
      setLoading(true);
      const folderData = {
        name,
        description,
      };

      const errs = validate(folderData, schema);
      if (errs != null) {
        setErrors(errs);
        return;
      }

      let operation = null;
      let folderId;
      if (folder.fileId != null) {
        operation = "updated";
        folderData.fileId = folder.fileId;
        const updated = await updateRecording(folderData);
        folderId = updated.folderId;
      }
      // refresh the exhibits list.
      await listRecordings(folder.eventId);

      handleClose();

      // show the snackbar.
      onSuccess({
        severity: "success",
        message: `The recording ${name} has been successfully ${operation}.`,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 409) {
        setErrorMessages("name", ERR_MSG_DUPLICATE_FOLDER_NAME, setErrors);
        return;
      } else if (ex.response && ex.response.status === 400) {
        setErrorMessages("name", ex.response.data.message, setErrors);
      } else if (ex.response) {
        setErrorMessages("server", ex.response.data.message, setErrors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth={false} maxWidth={"md"} open={open}>
      <CRXDialogTitle onClick={handleClose} title={dialogTitle} />
      <form
        onSubmit={handleSubmit}
        novalidate
        style={{
          width: "700px",
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="flex-start"
            m={1}
          >
            <Box m={1}>
              <TextField
                id="name"
                label="Name"
                name="name"
                variant="outlined"
                defaultValue={folder.name}
                required
                style={{
                  width: "600px",
                }}
                error={errors && !isEmpty(errors["name"])}
                helperText={errors && errors["name"]}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                  validateProperty(schema, e.currentTarget, setErrors);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") submitBtn.current.click();
                }}
              />
            </Box>
            <Box m={1}>
              <TextField
                id="description"
                label="Description"
                name="description"
                variant="outlined"
                defaultValue={folder.description}
                multiline
                rows={4}
                style={{
                  width: "600px",
                }}
                error={errors && !isEmpty(errors["description"])}
                helperText={errors && errors["description"]}
                onChange={(e) => {
                  setDescription(e.currentTarget.value);
                  validateProperty(schema, e.currentTarget, setErrors);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            ref={submitBtn}
            variant="contained"
            color="primary"
            formNoValidate
            disabled={!isEmpty(errors)}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { RecordingFormDialog };
