import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import Joi from "joi-browser";
import CRXDialogTitle from "../common/dialog/CRXDialogTitle";
import { useRecordingsService } from "../../services/RecordingsService";
import { isNil, update } from "lodash";
import SearchBar from "./recordingsList/SearchBar";
import { ContactTypeEnum } from "../common/model/ContactTypeEnum";

const LIST_PAGE_LENGTH = 15;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    height: "calc(100vh - 250px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "4px",
  },
  buttonBar: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recordingListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 85px)", // 8px from padding
  },
}));

const RecordingPermissionsDialog = ({ open, handleClose, onSuccess, eventId }) => {
  const classes = useStyles();
  const { listEventParticipants, updateEventParticipants } = useRecordingsService();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([
    {
      field: "eventParticipantType.eventParticipantType",
      sort: "asc",
    },
  ]);
  const [sortParam, setSortParam] = useState("eventParticipantType.eventParticipantType:asc");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [loading, setLoading] = useState();

  const participantsColumns = [
    {
      field: "user.userId",
      headerName: "Name",
      width: 500,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user ? params.row.user.firstName : params.row.contact.firstName}{" "}
            {params.row.user ? params.row.user.lastName : params.row.contact.lastName}
          </span>
        );
      },
    },
    {
      field: "contact.email",
      headerName: "Email",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <span>{params.row.user ? params.row.user.email : params.row.contact.email}</span>;
      },
    },
    {
      field: "eventParticipantType.eventParticipantType",
      headerName: "Type",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <span>{params.row.eventParticipantType.eventParticipantType}</span>;
      },
    },
    // {
    //   field: "grantViewEvent",
    //   headerName: "View Event",
    //   width: 200,
    //   disableColumnMenu: true,
    //   disableClickEventBubbling: true,
    // },
    {
      field: "grantViewRecordings",
      headerName: "View Recordings",
      width: 200,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              disabled={
                params.row.contact.contactTypeId === ContactTypeEnum.Admin ||
                params.row.contact.contactTypeId === ContactTypeEnum.SuperAdmin ||
                params.row.contact.contactTypeId === ContactTypeEnum.Support ||
                params.row.contact.contactTypeId === ContactTypeEnum.Videographer ||
                params.row.contact.contactTypeId === ContactTypeEnum.CourtReporter
              }
              checked={
                params.row.grantViewRecordings ||
                params.row.contact.contactTypeId === ContactTypeEnum.Admin ||
                params.row.contact.contactTypeId === ContactTypeEnum.SuperAdmin ||
                params.row.contact.contactTypeId === ContactTypeEnum.Support ||
                params.row.contact.contactTypeId === ContactTypeEnum.Videographer ||
                params.row.contact.contactTypeId === ContactTypeEnum.CourtReporter
              }
              onChange={(e) =>
                handleViewRecordingsChecked(params.row.eventParticipantId, e.currentTarget)
              }
            />
          </span>
        );
      },
    },
    // {
    //   field: "grantViewTranscripts",
    //   headerName: "View Transcripts",
    //   width: 200,
    //   disableColumnMenu: true,
    //   disableClickEventBubbling: true,
    // },
    // {
    //   field: "grantSaveTranscripts",
    //   headerName: "Save Transcripts",
    //   width: 200,
    //   disableColumnMenu: true,
    //   disableClickEventBubbling: true,
    // },
  ];

  React.useEffect(() => {
    if (isNil(open)) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        if (sortModel?.sortModel?.length > 0) {
          const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        const eventParticipants = await listEventParticipants(
          eventId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam
        );
        setFilteredParticipants(eventParticipants.content);
        setPagination(eventParticipants.totalElements);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (sortModel?.sortModel?.length > 0) {
          const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        const eventParticipants = await listEventParticipants(
          eventId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam
        );
        setFilteredParticipants(eventParticipants.content);
        setPagination(eventParticipants.totalElements);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [searchQuery]);

  const handleViewRecordingsChecked = (eventParticipantId, currentTarget) => {
    console.log("handleViewRecordingsChecked", eventParticipantId, currentTarget.checked);
    const updatedParticipants = filteredParticipants.map((p) => {
      if (p.eventParticipantId === eventParticipantId) {
        return { ...p, grantViewRecordings: currentTarget.checked };
      }
      return p;
    });

    setFilteredParticipants(updatedParticipants);
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const handleSavePermissions = async () => {
    try {
      setLoading(true);
      await updateEventParticipants(eventId, filteredParticipants);
      handleClose();
      onSuccess({
        severity: "success",
        message: `The recording permissions has been successfully updated.`,
      });
    } catch (error) {
      console.log("Unexpected error while saving event participant permissions...", error);
      // onError({
      //   severity: "error",
      //   message: `There was an unexpected error while saving recording permissions.`,
      // });
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSavePermissions(e);
          }}
        >
          <CRXDialogTitle onClick={handleClose} title="Recording Permissions" />
          <DialogContent>
            <Box className={classes.container}>
              <Box className={classes.buttonBar}>
                <Box width={1} display="flex" flexDirection="row" justifyContent="flex-end">
                  <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
                </Box>
              </Box>

              <Box className={classes.recordingListContainer}>
                <DataGridPro
                  className={classes.paper}
                  rows={filteredParticipants}
                  columns={participantsColumns}
                  rowHeight={48}
                  paginationMode="server"
                  pageSize={pageLength}
                  rowCount={pagination}
                  onPageChange={(newPage) => setPageIndex(newPage.page)}
                  onPageSizeChange={(newPageSize) => {
                    setPageLength(newPageSize.pageSize);
                  }}
                  disableSelectionOnClick
                  // isRowSelectable={params =>  (params.row.readOnly === false)
                  getRowId={(row) => row.eventParticipantId}
                  loading={loading}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { RecordingPermissionsDialog };
