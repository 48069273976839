/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import Joi from "joi-browser";
import { isEmpty, isNil } from "lodash";
import { validate, validateProperty } from "@cirrux888/huseby-client-auth";
import {
  validateExhibitNumber,
  publishExhibitAndUpdateExhibitNumber,
  fetchS3FileBlob,
  viewFile,
} from "../../../services/ExhibitsService";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { PublishExhibitConfirmationDialog } from "./PublishExhibitsDialog";

const ExhibitNumberDialog = ({
  open,
  onClose,
  eventId,
  publishExhibitData,
  onClosePreview,
  setSnackbar,
}) => {
  const [exhibitNumber, setExhibitNumber] = React.useState("");
  const [errors, setErrors] = useState(null);
  const [publishing, setPublishing] = React.useState({ status: "not-started", dialogOpen: false });

  const schema = {
    exhibitNumber: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(10)
      .regex(/^[^`'^ \[\]\{\}\\|]+$/)
      .label("Exhibit Number"),
  };

  useEffect(() => {
    if (!open) return;

    setExhibitNumber("");
  }, [open]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const errs = validate({ exhibitNumber }, schema);

    if (errs != null) {
      setErrors(errs);
      return;
    }

    try {
      await validateExhibitNumber(eventId, exhibitNumber, publishExhibitData?.fileId);
      setSnackbar((prevSnackbar) => ({
        ...prevSnackbar,
        open: true,
        message: "Publishing exhibit",
      }));

      setPublishing({
        ...publishing,
        dialogOpen: true,
        status: "in-progress",
      });
      await publishExhibitAndUpdateExhibitNumber(publishExhibitData?.fileId, exhibitNumber, (e) => {
        console.log("progress", e);
      });
      setSnackbar((prevSnackbar) => ({
        ...prevSnackbar,
        open: true,
        message: "Exhibit Published. Check Final Exhibits.",
      }));
      setPublishing({
        ...publishing,
        dialogOpen: false,
        status: "complete",
      });
    } catch (error) {
      const serverErrs = {};
      if (error.response) {
        serverErrs.exhibitNumber = error.response.data.message;
      } else {
        serverErrs.exhibitNumber = "An unknown error has occurred.";
      }
      setErrors(serverErrs);
      return;
    } finally {
      onClosePreview();
    }

    onClose();
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    onClose();
  };

  return (
    <>
      <Dialog maxWidth="md" open={open} onClose={onClose}>
        <form
          style={{
            width: "400px",
          }}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <h2>Publish Exhibit</h2>
            <p>
              With one click publishing, you can mark and publish your exhibit. It will then be
              accessible to all event attendees.
            </p>

            <p>Please enter exhibit number below</p>

            <TextField
              id="exhibitNumber"
              label="Exhibit Number"
              name="exhibitNumber"
              value={exhibitNumber}
              variant="outlined"
              required
              error={errors && !isEmpty(errors["exhibitNumber"])}
              helperText={errors && errors["exhibitNumber"]}
              onChange={(e) => {
                setExhibitNumber(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              autoFocus
              style={{ width: 300 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <PublishExhibitConfirmationDialog
        open={publishing.dialogOpen}
        publishing={publishing.status}
      />
    </>
  );
};

export default ExhibitNumberDialog;
