import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Error";
import { useDropzone } from "react-dropzone";
import { forEach } from "lodash";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    fontSize: "10pt",
  },
  link: {
    display: "flex",
    fontSize: 12,
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  container: {
    padding: "20px",
  },
  dropzone: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderColor: "#999999",
    borderWidth: "2px",
    borderStyle: "dashed",
    textAlign: "center",
    height: "200px",
    width: "750px",
  },
}));

const UploadFolderDialog = ({ open, handleClose, folderId, fileToUpload }) => {
  const classes = useStyles();
  const { data, listExhibits } = useContext(ExhibitsContext);
  const eventMeta = data.eventData;

  // We need our own file state instead of using the read-only dropzone acceptedFiles state.
  const [myFiles, setMyFiles] = useState([]);
  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    // Custom event handler for Dropzone's onDrop event.  We need to do this
    // to properly reset the acceptedFiles list to display a clean UploadFolderConfirmation.
    onDrop: (acceptedFiles) => {
      setStatus("has-files");
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    noDragEventsBubbling: true,
  });

  const [name, setName] = React.useState("");
  const [triggerUpload, setTriggerUpload] = useState(false);
  const [uploadAllFilesComplete, setUploadAllFilesComplete] = useState(false);
  const [status, setStatus] = useState("no-files"); // no-files || has-files
  useEffect(() => {
    if (fileToUpload != null) setName(fileToUpload.name);
    setMyFiles([]);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("in-progress");
    setTriggerUpload(true);
  };

  const handleUploadAllFilesComplete = async () => {
    setTriggerUpload(false);
    setStatus("complete");
    await listExhibits(folderId);
  };

  return (
    <Dialog fullWidth={false} maxWidth={"lg"} open={open} onClose={handleClose}>
      <CRXDialogTitle
        onClick={() => {
          handleClose();
        }}
        title="Upload Exhibits and Folders"
      />
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="flex-start"
            m={1}
          >
            <section className={classes.container}>
              <div {...getRootProps({ className: "dropzone" })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <CloudUploadIcon color="primary" fontSize="large" />
                <p>
                  Drag and drop your exhibits and folders here, or{" "}
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    click to select exhibits.
                  </a>
                </p>
              </div>
            </section>
            {myFiles && myFiles.length > 0 && (
              <UploadFolderConfirmation
                folderId={folderId}
                triggerUpload={triggerUpload}
                acceptedFiles={myFiles}
                fileRejections={fileRejections}
                setStatus={setStatus}
                onUploadAllFilesComplete={handleUploadAllFilesComplete}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {status === "has-files" && (
            <>
              <Button
                onClick={() => {
                  handleClose();
                }}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Upload
              </Button>
            </>
          )}
          {(status !== "has-files") === true && (
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              disabled={status === "in-progress"}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

/**
 * Upload folder confirmation dialog.
 *
 * @param {*} param0
 * @returns
 */
const UploadFolderConfirmationDialog = ({
  open,
  handleClose,
  folderId,
  acceptedFiles,
  fileRejections,
  onUploadAllFilesComplete,
}) => {
  const classes = useStyles();
  const [triggerUpload, setTriggerUpload] = useState(false);
  const [uploadAllFilesComplete, setUploadAllFilesComplete] = useState(false);
  const [status, setStatus] = useState("not-started");

  useEffect(() => {
    // if (fileToUpload != null) setName(fileToUpload.name);
    setUploadAllFilesComplete(false);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("in-progress");
    setTriggerUpload(true);
  };

  const handleUploadAllFilesComplete = () => {
    setStatus("complete");
  };

  return (
    <Dialog fullWidth={false} maxWidth={"md"} open={open} onClose={handleClose}>
      <CRXDialogTitle onClick={handleClose} title="Upload Folder Confirmation" />
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="flex-start"
            m={1}
          >
            {(acceptedFiles || fileRejections) && (
              <UploadFolderConfirmation
                folderId={folderId}
                acceptedFiles={acceptedFiles}
                fileRejections={fileRejections}
                triggerUpload={triggerUpload}
                onUploadAllFilesComplete={handleUploadAllFilesComplete}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {status === "not-started" && (
            <>
              <Button onClick={handleClose} variant="contained" color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Upload
              </Button>
            </>
          )}
          {(status !== "not-started") === true && (
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              disabled={status === "in-progress"}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

/**
 * Upload folder confirmation component.
 *
 * @param {*} param0
 * @returns
 */
const UploadFolderConfirmation = ({
  folderId,
  acceptedFiles,
  fileRejections,
  setStatus,
  triggerUpload,
  onUploadAllFilesComplete,
}) => {
  const [acceptedFileItems, setAcceptedFileItems] = useState(null);
  const [fileRejectionItems, setFileRejectionItems] = useState(null);
  const [uploadedComplete, setUploadComplete] = useState(false);

  // useEffect(() => {
  //   if (setStatus) {
  //     setStatus("not-started");
  //     console.log('acceptedFileItems', acceptedFileItems);
  //   }
  // }, [acceptedFileItems])
  useEffect(() => {
    if (!triggerUpload && !acceptedFiles.length) {
      return;
    }
    const tmpAcceptedFileItems = [];
    forEach(acceptedFiles, (file) => {
      tmpAcceptedFileItems.push(
        <AcceptedFile
          folderId={folderId}
          file={file}
          triggerUpload={triggerUpload}
          onStatusChange={handleStatusChange}
        />
      );
    });
    setAcceptedFileItems(tmpAcceptedFileItems);
  }, [acceptedFiles, triggerUpload]);

  useEffect(() => {
    const tmpFileRejectionItems = [];
    forEach(fileRejections, ({ file, errors }) => {
      tmpFileRejectionItems.push(
        <RejectedFile filePath={file.path} fileSize={file.size} errors={errors} />
      );
    });
    setFileRejectionItems(tmpFileRejectionItems);
  }, [fileRejections]);

  if (!acceptedFiles || !fileRejections) {
    return null;
  }

  let _uploadedCount = 0;
  const handleStatusChange = async (upload) => {
    _uploadedCount = _uploadedCount + 1;
    if (acceptedFileItems?.length === _uploadedCount) {
      await onUploadAllFilesComplete();
      setUploadComplete(true);
    }
  };

  return (
    <aside>
      <Box
        style={{
          padding: "20px",
          height: "300px",
          width: "calc(100% - 20px)",
          overflowY: "auto",
        }}
      >
        {acceptedFileItems && acceptedFileItems.length > 0 && <h4>Files to Upload:</h4>}
        {/* <Box>{acceptedFileItems}</Box>
         */}
        {acceptedFiles &&
          acceptedFiles.map((file) => (
            <AcceptedFile
              folderId={folderId}
              file={file}
              triggerUpload={triggerUpload}
              onStatusChange={handleStatusChange}
            />
          ))}

        {fileRejectionItems && fileRejectionItems.length > 0 && <h4>Rejected files</h4>}
        <Box>{fileRejectionItems}</Box>
      </Box>
    </aside>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 22,
    borderRadius: 5,
    width: 300,
    margin: 2,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

/**
 * UploadFile
 *
 * @param {*} param0
 * @returns
 */
const AcceptedFile = ({ folderId, file, triggerUpload = false, onStatusChange }) => {
  const { createExhibit, uploadFile } = useContext(ExhibitsContext);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("not-started");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (triggerUpload === true) handleOnSubmit();
  }, [triggerUpload]);

  const handleOnSubmit = async () => {
    try {
      setStatus("in-progress");
      // // Remove the leading '/'
      // // If there is a subdirectory in the path, then create the subdirectory.
      // let files = filePath.split("/");
      // forEach(files, (file) => {
      //   console.log("file", file);
      //   // Get folder by name and folderId
      //   // If it does not exist, then create folder
      //   // Finally create the exhibit
      // });

      // Create exhibit
      const exhibit = await createExhibit({
        folderId: folderId,
        name: file.path,
        description: "",
      });

      // Upload exhibit file
      await uploadFile(exhibit.fileId, file, (ev) => {
        const curProgress = Math.round((100 * ev.loaded) / ev.total);
        setProgress(curProgress);
      });
      setStatus("complete");
      onStatusChange &&
        (await onStatusChange({
          file,
          status: "complete",
        }));
    } catch (err) {
      if (err?.response?.data?.status == 409 || err?.response?.data?.status == 400) {
        setErrorMessage(err.response.data?.message);
      } else {
        setErrorMessage("There was an error uploading your file");
      }
      setStatus("error");
      onStatusChange &&
        (await onStatusChange({
          file,
          status: "error",
        }));
    }
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" m={2}>
      <Box width={500} style={{ marginRight: "20px" }}>
        <b>{file.path}</b> ({file.size / 1000} KB)
      </Box>
      <Box width={300} display="flex" flexDirection="row">
        {!errorMessage && <BorderLinearProgress variant="determinate" value={progress} />}
        {status === "complete" && <CheckIcon style={{ color: "green" }} />}
        {status === "in-progress" && <CircularProgress size={20} />}
        {status === "error" && (
          <Box
            width={300}
            style={{ color: "red", display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <ErrorIcon />
            <span style={{ marginLeft: 10 }}>{errorMessage}</span>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const RejectedFile = ({ filePath, fileSize, errors }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" m={2}>
      <Box width={500}>
        <b>{filePath}</b> ({fileSize / 1000} KB)
      </Box>
      <Box
        width={300}
        style={{ color: "red", display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <ErrorIcon />
        {errors.map((e) => (
          <span style={{ marginLeft: 10 }}>{e.message}</span>
        ))}
      </Box>
    </Box>
  );
};

export { UploadFolderDialog, UploadFolderConfirmationDialog };
