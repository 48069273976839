import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Icon } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { forEach } from "lodash";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import getFileIcon from "../../../hooks/useFileIcons";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const RetractExhibitDialog = ({ open, handleClose, onSuccess, folderId, selectedFiles = [] }) => {
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const { listExhibits, retractFiles } = React.useContext(ExhibitsContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tmp = [];
    forEach(selectedFiles, (file) => {
      tmp.push(
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
          key={file.fileId}
        >
          {getFileIcon(file)}
          <div>{file.name}</div>
        </div>
      );
    });

    setSelectedFilesUI(tmp);
  }, [selectedFiles]);

  const handleConfirmRetraction = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let ids = [];
      forEach(selectedFiles, (file) => {
        ids.push(file.fileId);
      });
      console.log("ids", ids);
      const response = await retractFiles(ids);
      console.trace("RetractExhibit::handleConfirmRetraction:retractfiles After retract files");
      await listExhibits(folderId);
      console.trace("RetractExhibit::handleConfirmRetraction:listExhibits After listExhibits");
      handleClose();
      onSuccess({
        severity: "success",
        message: response.message,
      });
    } catch (err) {
      handleClose();
      onSuccess({
        severity: "error",
        message: err.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"md"} open={open}>
        <CRXDialogTitle onClick={handleClose} title="Retract Confirmation" />
        <form
          onSubmit={handleConfirmRetraction}
          style={{
            width: "100%",
          }}
        >
          <DialogContent>
            <div>Are you sure you want to retract the following:</div>
            <Box
              style={{
                padding: "20px",
                height: "300px",
                width: "calc(100% - 20px)",
                overflowY: "auto",
              }}
            >
              {selectedFilesUI}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
              Confirm Retraction
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { RetractExhibitDialog };
