import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Icon } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { forEach } from "lodash";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import getFileIcon from "../../../hooks/useFileIcons";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const DeleteExhibitsDialog = ({ open, handleClose, onSuccess, folderId, selectedFiles = [] }) => {
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const { listExhibits, deleteFiles } = React.useContext(ExhibitsContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tmp = [];
    forEach(selectedFiles, (file) => {
      tmp.push(
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
          key={file.fileId}
        >
          {getFileIcon(file)}
          <div>{file.name}</div>
        </div>
      );
    });

    setSelectedFilesUI(tmp);
  }, [selectedFiles]);

  const handleConfirmDeletion = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let ids = [];
      forEach(selectedFiles, (file) => {
        ids.push(file.fileId);
      });
      console.log("ids", ids);
      await deleteFiles(ids);

      await listExhibits(folderId);

      handleClose();

      onSuccess({
        severity: "success",
        message: `The selected files have been successfully deleted.`,
      });
    } catch (err) {
      handleClose();

      onSuccess({
        severity: "error",
        message: err.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        style={{ height: "100%", width: "100%", margin: 0, padding: 0, overflowY: "scroll" }}
      >
        <CRXDialogTitle onClick={handleClose} title="Delete Confirmation" />
        <form onSubmit={handleConfirmDeletion}>
          <DialogContent>
            <div
              style={{
                // backgroundColor: "#ff0000",
                height: "calc(100vh - 400px)",
                width: "100%",
                padding: "10px",
              }}
            >
              <div>Are you sure you want to delete the following:</div>
              <Box
                style={{
                  margin: "20px",
                  width: "calc(100%)",
                  overflowY: "auto",
                }}
              >
                {selectedFilesUI}
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
              Confirm Deletion
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { DeleteExhibitsDialog };
