import React, { useContext, useState, useEffect } from "react";
import { Box, Button, CircularProgress, CssBaseline, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _, { isNil } from "lodash";
import Recordings from "./recordingsList/Recordings";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { useRecordingsService } from "../../services/RecordingsService";
import { DialogProvider } from "../common/providers/DialogProvider";

const useStyles = makeStyles({
  splashTitle: {
    fontSize: 50,
  },
  subTitle: {
    fontSize: 20,
    textAlign: "center",
  },
  progress: {
    color: "#71ee33",
  },
});

const RecordingsIndex = ({ eventId, style }) => {
  const classes = useStyles();
  const { auth, authenticate, getIdentity } = useContext(AuthContext);
  const { data, setData, listRecordings } = useRecordingsService();
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [recordingId, setRecordingId] = useState(null);
  const [isHost, setIsHost] = React.useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await authenticate();
      if (auth.token !== undefined) {
        console.log("Getting logged-in user's identity...");
        const identity = JSON.parse(getIdentity());
        const username = identity.username;
        const name = identity.name;

        setData({ eventId: eventId });

        // get list of recordings at the root folder.
        try {
          setLoading(true);
          await listRecordings(eventId);
        } catch (error) {
          console.log("Error detected while trying to list recordings!", error);
          if (error?.response?.status === 403) {
            setForbidden(true);
          }
        } finally {
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [eventId]);

  React.useEffect(() => {
    if (isNil(data.selectedRecording)) return;
    setSelectedRecording(data.selectedRecording);
  }, [data.selectedRecording]);

  return (
    <>
      {!loading && (
        <div style={style}>
          <CssBaseline />
          {forbidden && (
            <Box
              style={{
                color: "#000000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/images/error_403.jpeg" height="25%" width="25%" />
              <br />
              <br />
              <h2 style={{ width: "500px", textAlign: "center" }}>
                Unfortunately, you do not have permissions to view this recordings.
                <br />
                <br />
                Please contact Huseby Support for assistance.
              </h2>
            </Box>
          )}
          {/* {isHost && ( */}
          {!forbidden && (
            <DialogProvider style={style}>
              <Box
                style={{ height: "calc(100% - 20px)", width: "100%" }}
                display="flex"
                flexDirection="row"
              >
                <Box width={1} style={{ padding: "0px" }}>
                  <Recordings />
                </Box>
              </Box>
            </DialogProvider>
          )}
          {/* )} */}
          {/* {isHost === null && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
          >
            <CircularProgress size={50} className={classes.progress} />
            <br />
            <br />
            <br />
            Loading...
          </Box>
        )} */}
          {isHost === false && notAuthorized === true && <NotAuthorizedMessage />}
        </div>
      )}
    </>
  );
};

export const NotAuthorizedMessage = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
      >
        <Typography className={classes.splashTitle}>Not Authorized for Event</Typography>
        <Typography className={classes.subTitle}>
          You do not have permissions to access this Event. Please contact the Event Host to add you
          as a participant.
          <br />
          <br />
        </Typography>
      </Box>
    </>
  );
};

export { RecordingsIndex };
