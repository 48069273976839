import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { makeStyles } from "@mui/styles";
import { forEach, isEmpty, isNil } from "lodash";
import Joi from "joi-browser";
import { validate, validateProperty, setErrorMessages } from "../common/validateUtilities";
import CRXDialogTitle from "../common/dialog/CRXDialogTitle";
import { useRecordingsService } from "../../services/RecordingsService";

const useStyles = makeStyles((theme) => ({
  root: {
    "& form .MuiDialogContent-root": {
      width: "calc(100% - 48px)",
      overflowX: "hidden",
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const ShareRecordingsDialog = ({ open, handleClose, onSuccess, selectedRecordings }) => {
  const classes = useStyles();
  const [selectedRecordingsUI, setSelectedRecordingsUI] = useState([]);
  const [selectedRecordingsEmail, setSelectedRecordingsEmail] = useState("");
  const { shareRecordings } = useRecordingsService();

  const [recipients, setRecipients] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [errors, setErrors] = React.useState(null);

  useEffect(() => {
    if (isNil(selectedRecordings)) return;

    let tmp = [];
    forEach(selectedRecordings, (recording) => {
      tmp.push(
        <div key={recording.fileId} style={{ width: "100%" }}>
          <VideoLibraryIcon className={classes.icon} color="primary" />
          {recording.name}
        </div>
      );
    });
    setSelectedRecordingsUI(tmp);

    let tmpEmail = "";
    forEach(selectedRecordings, (file) => {
      tmpEmail += `\n- ${file.name}`;
    });
    setSelectedRecordingsEmail(tmpEmail);
  }, [selectedRecordings]);

  if (!open) {
    return null;
  }

  const schema = {
    subject: Joi.string().required().trim(true).min(0).max(512).label("Subject"),
    message: Joi.string().required().trim(true).min(0).max(512).label("Message"),
  };

  const handleConfirmShare = async (e, files) => {
    console.log("handleConfirmShare");
    // e.preventDefault();
    try {
      let recipients = e.target.recipients.value;
      let subject = e.target.subject.value;
      let message = e.target.message.value;

      // Split recipients
      let recipientList = recipients.split(",");
      console.log("recipientList", recipientList);
      // Validate emails
      // QW-TODO:
      let arrayLength = recipientList.length;
      setErrorMessages("recipients", null, setErrors);
      for (let i = 0; i < arrayLength; i++) {
        console.log("email is", recipientList[i]);
        let isValid = validateEmail(recipientList[i]);
        if (isValid === false) {
          console.log("email is", recipientList[i]);
          setErrorMessages("recipients", "Invalid email", setErrors);
          console.log("email is invalid");
          break;
        }
      }

      console.log(recipientList, subject, message);
      console.log("files is", files);
      let ids = [];
      files.forEach((file) => {
        ids.push(file.recordingId);
      });
      console.log("ids", ids);

      await shareRecordings(ids, recipientList, subject, message);
      handleClose();

      onSuccess({
        severity: "success",
        message: `An email with links to view the selected recordings has been sent.`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const validateEmail = (email) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regEmail.test(email)) {
      console.log("Email is not valid");
      return false;
    } else {
      console.log("Email is valid");
      return true;
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <CRXDialogTitle onClick={handleClose} title="Share Recordings" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmShare(e, selectedRecordings);
          }}
        >
          <DialogContent>
            <Box style={{ marginBottom: "10px" }}>
              <div>The following Recordings will be shared to your selected recipients.</div>
              <Box
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  width: "100%",
                }}
              >
                {selectedRecordingsUI}
              </Box>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Please use the form below to select your recipients and enter in a message to send
                to them.
              </div>
            </Box>
            <TextField
              id="recipients"
              label="Recipients"
              name="recipients"
              placeholder="Enter email addresses for recipients"
              variant="outlined"
              required
              fullWidth
              autoFocus
              error={errors && !isEmpty(errors["recipients"])}
              helperText={errors && errors["recipients"]}
              // value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                disableunderline: true,
              }}
            />
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              The following email will be sent to your recipients. This email is not editable.
            </div>
            <TextField
              id="subject"
              label="Subject"
              name="subject"
              variant="outlined"
              required
              disabled
              fullWidth
              error={errors && !isEmpty(errors["subject"])}
              helperText={errors && errors["subject"]}
              onChange={(e) => {
                setSubject(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              defaultValue={"A Recording Viewing link has been shared with you."}
            />
            <br />
            <br />
            <TextField
              id="message"
              label="Message"
              name="message"
              multiline
              rows={10}
              defaultValue=""
              variant="outlined"
              required
              disabled
              fullWidth
              error={errors && !isEmpty(errors["message"])}
              helperText={errors && errors["message"]}
              onChange={(e) => {
                setMessage(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              defaultValue={
                `A link has been shared with you to view the following Recordings:` +
                `\n` +
                `${selectedRecordingsEmail}` +
                `\n\n` +
                `Please click on the link to view the Recordings.` +
                `\n\n\${DOWNLOAD_LINK}` +
                `\n\nRegards,` +
                `\n\nThe Huseby Team`
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Send Email
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { ShareRecordingsDialog };
