import React, { useEffect, useState, useContext } from "react";
import { Dialog, DialogContent, Box, Drawer, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { ExhibitEditor, DataProvider } from "@cirrux888/huseby-client-exhibit-editor";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={333} />;
});

const useStyles = makeStyles((style) => ({
  paper: {
    height: (style) => style.height,
    width: (style) => style.width,
  },
}));

// const useStyles = makeStyles((style) => ({
//   paper: {
//     height: "100%",
//     width: "100%",
//   },
// }));

const ExhibitEditorDialog = ({ eventId, fileId, style, open, handleClose }) => {
  const classes = useStyles(style);
  const { data } = React.useContext(ExhibitsContext);

  return (
    <>
      <DataProvider>
        {/* <Dialog fullScreen open={open} TransitionComponent={Transition}>
          <DialogContent style={{ padding: 0 }}>
            <ExhibitEditor eventId={data.eventId} fileId={fileId} onCloseExhibit={handleClose} />
          </DialogContent>
        </Dialog> */}

        {/* <Drawer variant="persistent" anchor="bottom" open={open} classes={{ paper: classes.paper }}> */}
        <Drawer variant="persistent" anchor="bottom" open={open} classes={{ paper: classes.paper }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              backgroundColor: "#333333",
              overflowY: "none",
            }}
          >
            <Box
              style={{
                height: "20px",
                width: "calc(100% - 4px)",
                backgroundColor: "#333333",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handleClose} edge="end" color="primary" size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            <ExhibitEditor
              eventId={eventId}
              fileId={fileId}
              onCloseExhibit={handleClose}
              style={{ height: "calc(100vh - 24px)", width: "100%" }}
            />
          </Box>
        </Drawer>
      </DataProvider>
    </>
  );
};

export { ExhibitEditorDialog };
