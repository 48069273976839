/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    width: "100%",
    margin: 2,
    marginRight: 10,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export const PublishExhibitConfirmationDialog = ({
  open,
  publishing = "not-started",
  onYesClick,
  onNoClick,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogContent>
        <DialogContentText>
          {publishing === "not-started" && (
            <span>
              Are you sure you are finished annotating the exhibit? This document will become
              non-editable upon save.
            </span>
          )}

          {publishing !== "not-started" && (
            <span>
              {publishing === "in-progress" && <span>Your exhibit is being published...</span>}

              {publishing === "complete" && <span>Your exhibit has been published.</span>}

              <Box
                width={1}
                my={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <BorderLinearProgress variant={publishing ? "determinate" : "indeterminate"} />
                {publishing === "complete" && <CheckIcon style={{ color: "green" }} />}
                {publishing === "in-progress" && <CircularProgress size={20} />}
                {publishing === "error" && <ErrorIcon style={{ color: "red" }} />}
              </Box>
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {publishing !== "not-started" && (
          <>
            <Button
              color="primary"
              variant="contained"
              disabled={publishing !== "complete" && publishing !== "error"}
              onClick={onNoClick}
            >
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
