import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
} from "@mui/material";
import Joi from "joi-browser";
import { isEmpty, takeRight } from "lodash";
import { makeStyles } from "@mui/styles";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { ExhibitPreview } from "@cirrux888/huseby-client-exhibit-editor";
import { validate, validateProperty } from "@cirrux888/huseby-client-auth";
import {
  validateExhibitNumber,
  publishExhibitAndUpdateExhibitNumber,
  viewFile,
  ExhibitsContext,
} from "../../../services/ExhibitsService";
import { PublishExhibitConfirmationDialog } from "../../exhibits/dialogs/PublishExhibitsDialog";
import { Default, Mobile } from "../../common/Responsive";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    "& form": {
      overflowY: "scroll",
    },
    "& .MuiDialogContent-root": {
      marginBottom: 20,
    },
    "& .MuiPaper-root": {
      overflow: "hidden",
    },
    "& form div:nth-child(2)": {
      position: "sticky",
      bottom: 0,
      backgroundColor: "#ffffff",
      paddingRight: 14,
    },
  },
  main: {
    // paddingLeft: "20px",
    // paddingRight: "20px",
    margin: "0px",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    width: "100%",
  },
  container: {
    backgroundColor: "#ffffff",
    color: "#000000",
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    width: "100%",
  },
  closeButton: {
    color: "#333333",
    position: "absolute",
    right: 2,
    top: 0,
  },
  publishButton: {
    color: "#333333",
    position: "absolute",
    right: 40,
    top: 0,
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  notSupported: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000000",
  },
}));

const supportedImgFormats = [".png", ".jpg", ".jpeg", ".gif", ".bmp"];
const supportedVideoFormats = [".mp4", ".mp3", ".mov"];
const PublishExhibitDialog = ({
  fileId,
  onClosePreview,
  open,
  eventId,
  publishExhibitData,
  setSnackbar,
  existingExhibitNumber,
}) => {
  const classes = useStyles();
  const { data, editorObject, listFinalExhibits, getNextExhibitNumber } =
    React.useContext(ExhibitsContext);
  const [fileMeta, setFileMeta] = useState(null);
  const [exhibitNumber, setExhibitNumber] = React.useState("");
  const [isRetractExhibit, setIsRetractExhibit] = useState(false);
  const [errors, setErrors] = useState(null);
  const [publishing, setPublishing] = React.useState({ status: "not-started", dialogOpen: false });
  const [finalExhibits, setFinalExhibits] = useState(null);

  const videoRef = useRef(null);

  useEffect(() => {
    if (!open) return;

    // Get the file metadata.
    viewFile(fileId, false)
      .then((fileInfo) => {
        setFileMeta(fileInfo);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get the list of final exhibits to display exhibit numbers
    console.log("Get the list of final exhibits to display exhibit numbers...");
    listFinalExhibits(eventId)
      .then((finalExhibits) => {
        setFinalExhibits(finalExhibits);
      })
      .catch((error) => {
        console.log(error);
      });

    if (existingExhibitNumber) {
      setExhibitNumber(existingExhibitNumber);
      setIsRetractExhibit(true);
    }
    // Get the next exhibit number if autogeneration is enabled
    else if (data?.caseData.isExhibitsNumbersAutoIncremented) {
      getNextExhibitNumber(eventId).then((nextResp) => {
        const nextExhibitNumber = nextResp.data.nextExhibitNumber;
        setExhibitNumber(nextExhibitNumber);
      });
    }
  }, [open]);

  const imageVideoPlayer =
    fileMeta &&
    (supportedImgFormats.includes(`.${fileMeta.name.toLowerCase().split(".").pop()}`) ? (
      <img src={fileMeta.s3Url} crossOrigin="true" width="100%" />
    ) : supportedVideoFormats.includes(`.${fileMeta.name.toLowerCase().split(".").pop()}`) ? (
      <video ref={videoRef} src={fileMeta.s3Url} controls crossOrigin="true" width="100%" />
    ) : fileMeta?.name.toLowerCase().includes(".pdf") ? (
      <div style={{ width: "100%" }}>
        <ExhibitPreview
          open={open}
          eventId={data.eventId}
          fileId={fileId}
          presenter={data.identity.username}
          isMeetingRoom={editorObject.isMeetingRoom}
          exhibitMode={data?.eventData.exhibits}
          useDialog={false}
        />
      </div>
    ) : (
      <span className={classes.notSupported}>
        (Previews are only supported for the following file types: bmp, jpeg, gif, mp3, mp4, pdf,
        png.)
      </span>
    ));

  const schema = {
    exhibitNumber: Joi.string()
      .required()
      .trim(true)
      .min(0)
      .max(10)
      .regex(/^[^`'^ \[\]\{\}\\|]+$/)
      .label("Exhibit Number"),
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const errs = validate({ exhibitNumber }, schema);

    if (errs != null) {
      setErrors(errs);
      return;
    }

    try {
      await validateExhibitNumber(eventId, exhibitNumber, publishExhibitData?.fileId);
      setSnackbar((prevSnackbar) => ({
        ...prevSnackbar,
        open: true,
        message: "Publishing exhibit...",
      }));

      setPublishing({
        ...publishing,
        dialogOpen: true,
        status: "in-progress",
      });
      await publishExhibitAndUpdateExhibitNumber(publishExhibitData?.fileId, exhibitNumber, (e) => {
        console.log("progress", e);
      });
      setIsRetractExhibit(false);
      setSnackbar((prevSnackbar) => ({
        ...prevSnackbar,
        open: true,
        message: "Exhibit Published. Check the 'Final Exhibits' for your published exhibit.",
      }));
      setPublishing({
        ...publishing,
        dialogOpen: false,
        status: "complete",
      });
    } catch (error) {
      const serverErrs = {};
      if (error.response) {
        serverErrs.exhibitNumber = error.response.data.message;
      } else {
        serverErrs.exhibitNumber = "An unknown error has occurred.";
      }
      setErrors(serverErrs);
      return;
    } finally {
      // onClosePreview();
    }

    onClosePreview();
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    onClosePreview();
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.root}
      >
        <CRXDialogTitle
          onClick={onClosePreview}
          title={isRetractExhibit ? "Publishing Retracted Exhibit" : "Publish Exhibit"}
        />
        <Divider />
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box className={classes.main}>
              <p>
                Enter your exhibit number in the dialogue box and select Publish Exhibit. The
                published exhibit will be accessible to all event attendees in the Final Exhibits
                folder.&#160;&#160;
                {/* Display the last 5 exhibit numbers */}
                {finalExhibits?.files?.length > 0 && (
                  <span>
                    {finalExhibits?.files?.length === 1 && (
                      <span>The last exhibit number used is displayed for your reference:</span>
                    )}

                    {finalExhibits?.files?.length > 1 && (
                      <span>
                        The last {takeRight(finalExhibits?.files, 5).length} exhibit numbers used
                        are displayed for your reference.
                      </span>
                    )}
                  </span>
                )}
              </p>
              <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Box>
                  {data?.caseData.isExhibitsNumbersAutoIncremented && !existingExhibitNumber ? (
                    <Box
                      style={{
                        display: "flex",
                        direction: "row",
                        alignItems: "center",
                        height: "40px",
                        marginRight: "40px",
                      }}
                    >
                      <span>
                        Exhibit Number: <b>{exhibitNumber}</b>
                      </span>
                      <input type="hidden" value={exhibitNumber} />
                    </Box>
                  ) : (
                    <TextField
                      id="exhibitNumber"
                      label="Exhibit Number"
                      name="exhibitNumber"
                      value={exhibitNumber}
                      variant="outlined"
                      required
                      error={errors && !isEmpty(errors["exhibitNumber"])}
                      helperText={errors && errors["exhibitNumber"]}
                      onChange={(e) => {
                        setExhibitNumber(e.currentTarget.value);
                        validateProperty(schema, e.currentTarget, setErrors);
                      }}
                      autoFocus
                      style={{ minWidth: 200, width: 300 }}
                    />
                  )}
                </Box>
                <Default>
                  <Box
                    style={{
                      padding: 10,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ height: 40, width: 300 }}
                    >
                      Publish Exhibit
                    </Button>
                  </Box>
                </Default>
              </Box>

              <Mobile>
                <Box
                  style={{
                    width: "100%",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ height: 40, width: 300 }}
                  >
                    Publish Exhibit
                  </Button>
                </Box>
              </Mobile>

              {/* Display the last 5 exhibit numbers */}
              {finalExhibits?.files?.length > 0 && (
                <p>
                  {finalExhibits?.files?.length === 1 && <span>The last exhibit number was:</span>}

                  {finalExhibits?.files?.length > 1 && (
                    <span>
                      The last {takeRight(finalExhibits?.files, 5).length} exhibit numbers used are:
                    </span>
                  )}

                  <span style={{ paddingLeft: 5, fontWeight: "bold" }}>
                    {finalExhibits?.files &&
                      takeRight(finalExhibits?.files, 5)
                        .map((file) => file.exhibitNumber)
                        .join(", ")}
                  </span>
                </p>
              )}

              <Box className={classes.container}>{imageVideoPlayer}</Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <PublishExhibitConfirmationDialog
        open={publishing.dialogOpen}
        publishing={publishing.status}
      />
    </>
  );
};

export default PublishExhibitDialog;
