import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TreeView, TreeItem } from "@mui/lab";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WarningIcon from "@mui/icons-material/Warning";
import { makeStyles } from "@mui/styles";
import { forEach, isEmpty, isNil } from "lodash";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { useExhibitsService } from "../../../services/ExhibitsService";

const useStyles = makeStyles((theme) => ({
  root: {
    "& form .MuiDialogContent-root": {
      width: "calc(100%)",
      overflowX: "hidden",
    },
  },
  // navContainer: {
  //   display: "flex",
  //   flexDirection: "row",
  //   height:500,
  //   width: "100vw",
  //   backgroundColor: "#ffffff",
  //   padding: "20px"
  // },
  treeContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 400px)",
    width: "100vw",
    backgroundColor: "#e4e4e4",
    padding: "20px",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  progress: {
    color: "#71ee33",
  },
}));

const folderKeys = [];

const MoveExhibitsDialog = ({ open, handleClose, onSuccess, selectedFiles }) => {
  const classes = useStyles();
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const { data, moveFiles, listBookmarkedFiles, listMoveDestinations, checkFinalExhibitsSelected } =
    useExhibitsService();
  // const [bookmarks, setBookmarks] = useState([]);
  const [destFolders, setDestFolders] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [destFolderId, setDestFolderId] = React.useState([]);
  const [expanded, setExpanded] = useState([]);
  const [isFinalExhibitsSelected, setIsFinalExhibitsSelected] = React.useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);

        const selectedFileIds = selectedFiles.map((file) => file.fileId);

        const _destinations = await listMoveDestinations(data.eventId, selectedFileIds);
        const folders = _destinations;
        folders.folderId = -1;
        folders.name = "Destinations";
        setDestFolders(folders);

        // Get folder keys to default to expanded TreeView
        getKeys(folders);
        setExpanded(folderKeys);
      } finally {
        setLoading(false);
      }
    };
    init();
  }, []);

  const getKeys = (nodes) => {
    folderKeys.push("" + nodes.folderId);
    return Array.isArray(nodes.folders) ? nodes.folders.map((node) => getKeys(node)) : "";
  };

  useEffect(() => {
    if (isNil(selectedFiles)) return;

    let tmp = [];
    forEach(selectedFiles, (file) => {
      tmp.push(
        <div key={file.fileId} style={{ width: "100%" }}>
          {file.fileType === 0 && <FolderIcon className={classes.icon} color="primary" />}
          {file.fileType === 1 && <PictureAsPdfIcon className={classes.icon} color="primary" />}
          {file.name}
        </div>
      );
    });
    setSelectedFilesUI(tmp);
  }, [selectedFiles]);

  // const isValidDestFolderId = (destFolderId) => {
  // QW-TODO: Need to fix this and have a Map of all exhibits.  However, this is not necessary at the moment.
  //   // const _exhibit = find(data.exhibits, file => file.fileId === Number(destFolderId));
  //   console.log("XXX isValidDestFolderId", _exhibit);
  //   if (isNil(_exhibit) || _exhibit.exhibitName === "Final Exhibits") return null;

  //   return destFolderId;
  // }

  const handleMove = async (e) => {
    e.preventDefault();

    // You cannot selected the Root folder as the destination folder.
    if (destFolderId === "-1") {
      console.log("You cannot selected the Root folder as the destination folder.");
      return;
    }

    // Validate
    // if (isNil(isValidDestFolderId(destFolderId))) {
    //   console.log("You have selected an invalid destination folder.");
    //   return;
    // };

    try {
      setSaving(true);
      let selectedFileIds = [];
      selectedFiles.forEach((file) => {
        selectedFileIds.push(file.fileId);
      });

      await moveFiles(destFolderId, selectedFileIds);
      onSuccess({
        severity: "success",
        message: `The files have been moved to the selected destination folder.`,
      });

      handleClose();
    } catch (err) {
      onSuccess({
        severity: "error",
        message: err.response.data.message,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setDestFolderId(nodeIds);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const renderTree = (nodes) => {
    return (
      <TreeItem
        key={nodes.folderId}
        nodeId={"" + nodes.folderId}
        label={
          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box>{nodes.name}</Box>
            <Box style={{ fontSize: "12px", maxWidth: "400px" }}>
              {nodes.disabled && (
                <Box
                  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                >
                  <Tooltip title={nodes.disabledMsg} placement="right-start">
                    <WarningIcon fontSize="small" />
                  </Tooltip>
                  {/* <Box>{nodes.disabledMsg}</Box> */}
                </Box>
              )}
            </Box>
          </Box>
        }
        disabled={nodes?.disabled}
      >
        {Array.isArray(nodes.folders) ? nodes.folders.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <CRXDialogTitle onClick={handleClose} title="Move Files" />
        <form onSubmit={handleMove}>
          <DialogContent>
            <Box style={{ marginBottom: "10px" }}>
              <div>You have selected {selectedFiles.length} files to move.</div>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Please select the destination folder below.
              </div>
            </Box>
            <Box className={classes.navContainer}>
              <Box className={classes.treeContainer} style={{ width: "100%" }}>
                {!loading && (
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<FolderOpenIcon fontSize="large" color="primary" />}
                    defaultExpandIcon={<FolderIcon fontSize="large" color="primary" />}
                    defaultEndIcon={<FolderOpenIcon fontSize="large" color="primary" />}
                    icon={<FolderIcon fontSize="large" color="primary" />}
                    sx={{ height: "100%", flexGrow: 1, maxWidth: "100vw", overflowY: "auto" }}
                    expanded={expanded}
                    selected={destFolderId}
                    onNodeSelect={handleSelect}
                    onNodeToggle={handleToggle}
                  >
                    {renderTree(destFolders)}
                  </TreeView>
                )}
                {loading && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: "100vh", paddingLeft: "50px", paddingRight: "50px" }}
                  >
                    <CircularProgress size={40} className={classes.progress} />
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={saving}
              variant="contained"
              color="primary"
              disabled={destFolderId === "-1"}
            >
              Move
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { MoveExhibitsDialog };
