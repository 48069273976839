import React, { useRef, useEffect, useState } from "react";
import { viewFile } from "../../../services/ExhibitsService";
import { Box, Button, Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import ExhibitNumberDialog from "./../../exhibits/dialogs/ExhibitNumberDialog";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "10px",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  closeButton: {
    color: "#333333",
    position: "absolute",
    right: 2,
    top: 0,
  },
  publishButton: {
    color: "#333333",
    position: "absolute",
    right: 40,
    top: 0,
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  notSupported: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontSize: "20px",
    fontWeight: "medium",
    color: "#000",
  },
  textSize: {
    fontSize: "20px",
  },
}));

const supportedImgFormats = [".png", ".jpg", ".jpeg", ".gif", ".bmp"];
const supportedVideoFormats = [".mp4", ".mp3", ".mov"];
const ImageVideoViewer = ({
  fileId,
  onClosePreview,
  open,
  eventId,
  publishExhibitData,
  setSnackbar,
  exhibitName,
  exhibitNumber,
}) => {
  const classes = useStyles();
  const [fileMeta, setFileMeta] = useState(null);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!open) return;

    viewFile(fileId, false)
      .then((fileInfo) => {
        setFileMeta(fileInfo);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [open]);

  const imageVideoPlayer =
    fileMeta &&
    (supportedImgFormats.includes(`.${fileMeta.name.toLowerCase().split(".").pop()}`) ? (
      <img src={fileMeta.s3Url} crossOrigin="true" width="100%" />
    ) : supportedVideoFormats.includes(`.${fileMeta.name.toLowerCase().split(".").pop()}`) ? (
      <video ref={videoRef} src={fileMeta.s3Url} controls crossOrigin="true" width="100%" />
    ) : (
      <span className={classes.notSupported}>
        Previews are only supported for the following file types: bmp, jpeg, gif, mp3, mp4, pdf,
        png.
      </span>
    ));

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          <div style={{ fontSize: "22px" }}>{exhibitName}</div>
          {exhibitNumber && (
            <span style={{ fontSize: "18px" }} className={classes.textSize}>
              Exhibit Number: {exhibitNumber}
            </span>
          )}
          <IconButton onClick={onClosePreview} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box className={classes.main}>
          <Box className={classes.container}>{imageVideoPlayer}</Box>
        </Box>
        <DialogActions>
          <Button onClick={onClosePreview} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {showPublishDialog && (
        <ExhibitNumberDialog
          open={showPublishDialog}
          onClose={() => setShowPublishDialog(false)}
          eventId={eventId}
          publishExhibitData={publishExhibitData}
          s3Url={fileMeta.s3Url}
          onClosePreview={onClosePreview}
          setSnackbar={setSnackbar}
        />
      )}
    </>
  );
};

export default ImageVideoViewer;
