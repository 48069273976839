import React, { useContext, useEffect, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikTextField } from "../../common/FormComponents";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import useSnackbar from "../../common/snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "54ch",
    },
  },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
    [theme.breakpoints.down("lg")]: {
      flex: "1",
    },
  },
  formikInput: {
    width: "100%", // Ensure full width on mobile
    padding: theme.spacing(1), // Add padding to avoid merging with window border
    [theme.breakpoints.up("md")]: {
      minWidth: "430px",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "300px",
      flex: "4",
    },
  },
  formContainer: {
    [theme.breakpoints.up("md")]: {
      width: "430px",
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 22,
    borderRadius: 5,
    width: "100%",
    margin: 2,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

/**
 * Exhibit schema for Yup.
 */
const ExhibitSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is a required field")
    .trim(true)
    .min(0)
    .max(255)
    .matches(
      /^[^/\\"<>:*?|'{}$]+$/,
      "Name cannot contain any of the following characters: \"/\\<>:*?|'{}$"
    ),
  description: Yup.string().trim(true).min(0).max(255),
});

const ExhibitFormDialog = ({
  open,
  handleClose,
  onSuccess,
  folderId,
  fileToUpload,
  exhibit = { folderId: folderId },
}) => {
  const classes = useStyles();
  const { data, listExhibits, createExhibit, updateExhibit, uploadFile } =
    useContext(ExhibitsContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = React.useState(exhibit.name);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("not-started");
  const [progress, setProgress] = useState(0);
  const actualFileDetails = exhibit?.name?.split(".");
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    if (!open) return;

    // setErrors(null);
  }, [open]);

  useEffect(() => {
    if (fileToUpload) {
      if (fileToUpload != null) setName(fileToUpload.name);
    }
  }, [fileToUpload]);

  const handleSubmit = async (values, { setFieldError }) => {
    const name = values.name;
    const description = values.description;

    try {
      setLoading(true);
      const exhibitData = {
        name,
        description,
      };

      let operation = null;
      if (exhibit.fileId != null) {
        operation = "updated";
        exhibitData.fileId = exhibit.fileId;
        var fileExtension = name.split(".").pop();
        if (
          actualFileDetails &&
          actualFileDetails.length > 0 &&
          actualFileDetails[1] === "pdf" &&
          fileExtension !== "pdf"
        ) {
          // setErrorMessages("name", ERR_MSG_INVALID_EXHIBIT_NAME, setErrors);
          return;
        }
        const updated = await updateExhibit(exhibitData);
        // await listExhibits(updated.folderId);
        folderId = updated.folderId;
      } else {
        operation = "created";
        exhibitData.folderId = exhibit.folderId;
        const created = await createExhibit(exhibitData);
        // await listExhibits(created.folderId);
        folderId = created.folderId;

        // Only upload files for newly created exhibits for now.
        if (fileToUpload != null) {
          try {
            setStatus("in-progress");
            await uploadFile(created.fileId, fileToUpload, (ev) => {
              const curProgress = Math.round((100 * ev.loaded) / ev.total);
              setProgress(curProgress);
            });
            setStatus("complete");
          } catch (error) {
            setStatus("error");
          }
        }
      }

      // refresh the exhibits list.
      await listExhibits(folderId);

      handleClose();

      // show the snackbar.
      onSuccess({
        severity: "success",
        message: `The exhibit ${name} has been successfully ${operation}.`,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 409) {
        await setFieldError("name", ex.response.data.message);
        return;
      } else if (ex.response) {
        showSnackbar(ex.response.data.message, "error");
      } else {
        showSnackbar("Something went wrong. Please try again.", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: exhibit?.name || fileToUpload?.name || "",
        description: exhibit?.description || "",
      }}
      validationSchema={ExhibitSchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Dialog fullWidth={false} maxWidth={"md"} open={open}>
          <CRXDialogTitle onClick={handleClose} title="Exhibit Details" />
          <Form>
            <DialogContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                justifyContent="flex-start"
                m={0}
              >
                {status === "not-started" && (
                  <>
                    <Box m={1}>
                      <FormikTextField
                        id="name"
                        name="name"
                        label="Name"
                        required
                        className={classes.formikInput}
                        autoFocus
                      />
                    </Box>
                    <Box m={1}>
                      <FormikTextField
                        id="description"
                        name="description"
                        label="Description"
                        style={{
                          width: "100%",
                        }}
                        multiline
                        rows="5"
                      />
                    </Box>
                  </>
                )}

                {status !== "not-started" && (
                  <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Box width="100%" display="flex" flexDirection="row" my={2}>
                      {status === "in-progress" && <div>Your exhibit is being uploaded.</div>}
                      {status === "complete" && <div>Your exhibit has been uploaded.</div>}
                    </Box>
                    <Box width="100%" display="flex" flexDirection="row">
                      {!errorMessage && (
                        <BorderLinearProgress variant="determinate" value={progress} />
                      )}

                      {status === "complete" && <CheckIcon style={{ color: "green" }} />}
                      {status === "in-progress" && <CircularProgress size={20} />}
                      {status === "error" && (
                        <Box
                          width="100%"
                          style={{
                            color: "red",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <ErrorIcon />
                          <span style={{ marginLeft: 10 }}>{errorMessage}</span>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              {status === "not-started" && (
                <>
                  <Button onClick={handleClose} variant="contained" color="primary">
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    variant="contained"
                    color="primary"
                    formNoValidate
                    disabled={!isEmpty(errors)}
                  >
                    Save
                  </LoadingButton>
                </>
              )}

              {status != "not-started" && (
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  disabled={status === "in-progress"}
                >
                  OK
                </Button>
              )}
            </DialogActions>
          </Form>
          {SnackbarComponent}
        </Dialog>
      )}
    </Formik>
  );
};

export { ExhibitFormDialog };
