import React, { useCallback, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { BookmarkExhibitsDialog } from "../../exhibits/dialogs/BookmarkExhibits";
import { DeleteExhibitsDialog } from "../../exhibits/dialogs/DeleteExhibits";
import { RetractExhibitDialog } from "../../exhibits/dialogs/RetractExhibit";
import { DownloadExhibitsDialog } from "../../exhibits/dialogs/DownloadExhibits";
import { DownloadExhibitDialog } from "../../exhibits/dialogs/DownloadExhibit";
import { ExhibitFormDialog } from "../../exhibits/dialogs/ExhibitForm";
import { ExhibitEditorDialog } from "../../exhibits/dialogs/ExhibitEditor";
import { FolderFormDialog } from "../../exhibits/dialogs/FolderForm";
import { MoveExhibitsDialog } from "../../exhibits/dialogs/MoveExhibits";
import { ShareExhibitsDialog } from "../../exhibits/dialogs/ShareExhibits";
import { ExhibitPermissionsDialog } from "../../exhibits/dialogs/ExhibitPermissions";
import { AddUserToPermissionsDialog } from "../../exhibits/dialogs/AddUserToPermissionsList";
import { UploadFolderDialog } from "../../exhibits/dialogs/UploadFolder";
import { UploadFolderConfirmationDialog } from "../../exhibits/dialogs/UploadFolder";
import { DeleteRecordingsDialog } from "../../recordings/DeleteRecordings";
import { DownloadRecordingsDialog } from "../../recordings/DownloadRecordings";
import { DownloadRecordingDialog } from "../../recordings/DownloadRecording";
import { ShareRecordingsDialog } from "../../recordings/ShareRecordings";
import { RecordingPermissionsDialog } from "../../recordings/RecordingPermissions";
import { RecordingFormDialog } from "../../exhibits/dialogs/RecordingForm";

const DialogContext = React.createContext();

// SuperSnackbar is a solution to the problem of the snackbar not being
// displayed when the dialog is open. we render the snackbar using a portal
const SuperSnackbar = ({ message, isOpen, onClose, severity }) => {
  if (!isOpen) return null;
  return createPortal(
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>,
    document.body
  );
};

const DialogProvider = (props) => {
  const { data, setData } = React.useContext(ExhibitsContext);
  const { style } = props;
  const [snackbar, setSnackbar] = useState(false);
  const [exhibit, setExhibit] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [exhibitForm, setExhibitForm] = useState();
  const [exhibitEditor, setExhibitEditor] = useState();
  const [editFolder, setEditFolder] = useState();
  const [downloadExhibit, setDownloadExhibit] = useState();
  const [downloadExhibits, setDownloadExhibits] = useState(); // downloadExhibits.folderId, downloadExhibits.selectedFiles
  const [deleteExhibits, setDeleteExhibits] = useState(); // deleteExhibits.folderId, deleteExhibits.selectedFiles
  const [retractExhibit, setRetractExhibit] = useState(); // retractExhibit.folderId, retractExhibit.selectedFiles
  const [moveExhibits, setMoveExhibits] = useState();
  const [shareExhibits, setShareExhibits] = useState();
  const [exhibitPermissions, setExhibitPermissions] = useState();
  const [addUserToPermissions, setAddUserToPermissions] = useState();
  const [uploadFolder, setUploadFolder] = useState();
  const [uploadFolderConfirmation, setUploadFolderConfirmation] = useState();
  const [downloadRecording, setDownloadRecording] = useState();
  const [downloadRecordings, setDownloadRecordings] = useState(); // downloadRecordings.folderId, downloadRecordings.selectedFiles
  const [deleteRecordings, setDeleteRecordings] = useState(); // deleteRecordings.folderId, deleteRecordings.selectedFiles
  const [shareRecordings, setShareRecordings] = useState();
  const [recordingPermissions, setRecordingPermissions] = useState();
  const [editRecording, setEditRecording] = useState();

  return (
    <DialogContext.Provider
      value={{
        setExhibit,
        setSelectedFiles,
        // setBookmarkExhibits,
        setExhibitEditor,
        setExhibitForm,
        setEditFolder,
        setDownloadExhibit,
        setDownloadExhibits,
        setDeleteExhibits,
        setRetractExhibit,
        setShareExhibits,
        setMoveExhibits,
        setExhibitPermissions,
        setAddUserToPermissions,
        setUploadFolder,
        setUploadFolderConfirmation,
        setDownloadRecordings,
        setDownloadRecording,
        setDeleteRecordings,
        setShareRecordings,
        setRecordingPermissions,
        setEditRecording,
      }}
      {...props}
    >
      {props.children}

      {/* Bookmarks Dialog */}
      {/* {bookmarkExhibits && (
        <BookmarkExhibitsDialog
          open={openBookmarkExhibits}
          handleClose={(e) => {
            setBookmarkExhibits(null);
          }}
          onSuccess={(e) => {
            setBookmarkExhibits(null);
            // setOpenSnackbar(true);
            // setSnackbarSeverity(e.severity);
            // setSnackbarMessage(e.message);
          }}
          selectedFiles={bookmarkExhibits.selectedFiles}
        />
      )} */}
      {uploadFolder && (
        <UploadFolderDialog
          open={uploadFolder && uploadFolder.open}
          handleClose={(e) => {
            setUploadFolder({ ...uploadFolder, open: false });
          }}
          folderId={uploadFolder.folderId}
        />
      )}

      {exhibitForm && (
        <ExhibitFormDialog
          open={exhibitForm && exhibitForm.open}
          handleClose={(e) => {
            setExhibitForm(null);
          }}
          exhibit={exhibitForm.exhibit}
          folderId={exhibitForm.folderId}
          fileToUpload={exhibitForm.fileToUpload}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
        />
      )}

      {/* {exhibitEditor && (
        <ExhibitEditorDialog
          fileId={exhibitEditor.fileId}
          open={exhibitEditor && exhibitEditor.open}
          handleClose={(e) => {
            setExhibitEditor(null);
            setData({ clear: "launchedExhibit" });
            setData({ launchedExhibit: null });
          }}
          style={style}
        />
      )} */}

      {/* Edit Folder */}
      {editFolder && (
        <FolderFormDialog
          open={editFolder && editFolder.open}
          handleClose={(e) => {
            editFolder && editFolder.onClose && editFolder.onClose();
            setEditFolder(null);
          }}
          folder={editFolder.folder}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
        />
      )}
      {/* Download A Single Exhibit */}
      {downloadExhibit && (
        <DownloadExhibitDialog
          open={downloadExhibit && downloadExhibit.open}
          handleClose={(e) => {
            downloadExhibit && downloadExhibit.onClose && downloadExhibit.onClose();
            setDownloadExhibit(null);
          }}
          file={downloadExhibit.file}
          // selectedFiles={downloadExhibits.selectedFiles}
        />
      )}

      {/* Download Exhibits */}
      {downloadExhibits && (
        <DownloadExhibitsDialog
          open={downloadExhibits && downloadExhibits.open}
          handleClose={(e) => {
            downloadExhibits && downloadExhibits.onClose && downloadExhibits.onClose();
            setDownloadExhibits(null);
          }}
          selectedFiles={downloadExhibits.selectedFiles}
        />
      )}
      {/* Delete Exhibits */}
      {deleteExhibits && (
        <DeleteExhibitsDialog
          open={deleteExhibits && deleteExhibits.open}
          handleClose={(e) => {
            deleteExhibits && deleteExhibits.onClose && deleteExhibits.onClose();
            setDeleteExhibits(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          folderId={deleteExhibits && deleteExhibits.folderId}
          selectedFiles={deleteExhibits.selectedFiles}
        />
      )}
      {/* Retract Exhibits */}
      {retractExhibit && (
        <RetractExhibitDialog
          open={retractExhibit && retractExhibit.open}
          handleClose={(e) => {
            retractExhibit && retractExhibit.onClose && retractExhibit.onClose();
            setRetractExhibit(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          folderId={retractExhibit && retractExhibit.folderId}
          selectedFiles={retractExhibit.selectedFiles}
        />
      )}

      {/* Share Exhibits */}
      {shareExhibits && (
        <ShareExhibitsDialog
          open={shareExhibits && shareExhibits.open}
          handleClose={(e) => {
            shareExhibits && shareExhibits.onClose && shareExhibits.onClose();
            setShareExhibits(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          selectedFiles={shareExhibits.selectedFiles}
        />
      )}

      {/* Move Exhibits */}
      {moveExhibits && (
        <MoveExhibitsDialog
          open={moveExhibits && moveExhibits.open}
          handleClose={(e) => {
            moveExhibits && moveExhibits.onClose && moveExhibits.onClose();
            setMoveExhibits(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          selectedFiles={moveExhibits.selectedFiles}
          folderId={moveExhibits.folderId}
        />
      )}

      {exhibitPermissions && (
        <ExhibitPermissionsDialog
          open={exhibitPermissions && exhibitPermissions.open}
          handleClose={(e) => {
            exhibitPermissions && exhibitPermissions.onClose && exhibitPermissions.onClose();
            setExhibitPermissions(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          eventId={exhibitPermissions.eventId}
          fileId={exhibitPermissions.fileId}
          firmId={exhibitPermissions.firmId}
          currentFolder={exhibitPermissions.currentFolder}
        />
      )}

      {addUserToPermissions && (
        <AddUserToPermissionsDialog
          open={addUserToPermissions && addUserToPermissions.open}
          handleClose={(e) => {
            addUserToPermissions && addUserToPermissions.onClose && addUserToPermissions.onClose();
            setAddUserToPermissions(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          fileId={addUserToPermissions.fileId}
          firmId={addUserToPermissions.firmId}
          currentFolder={addUserToPermissions.currentFolder}
          eventId={addUserToPermissions.eventId}
        />
      )}

      {/* UploadFolderConfirmation */}
      {uploadFolderConfirmation && (
        <UploadFolderConfirmationDialog
          open={uploadFolderConfirmation && uploadFolderConfirmation.open}
          handleClose={(e) => {
            setUploadFolderConfirmation(null);
            uploadFolderConfirmation.onClose && uploadFolderConfirmation.onClose();
          }}
          folderId={uploadFolderConfirmation.folderId}
          acceptedFiles={uploadFolderConfirmation.acceptedFiles}
          fileRejections={uploadFolderConfirmation.fileRejections}
        />
      )}

      {/* Download A Single Recording */}
      {downloadRecording && (
        <DownloadRecordingDialog
          open={downloadRecording && downloadRecording.open}
          handleClose={(e) => {
            downloadRecording && downloadRecording.onClose && downloadRecording.onClose();
            setDownloadRecording(null);
          }}
          recording={downloadRecording.selectedRecording}
          // selectedFiles={downloadRecordings.selectedFiles}
        />
      )}

      {/* Download Recordings */}
      {downloadRecordings && (
        <DownloadRecordingsDialog
          open={downloadRecordings && downloadRecordings.open}
          handleClose={(e) => {
            downloadRecordings && downloadRecordings.onClose && downloadRecordings.onClose();
            setDownloadRecordings(null);
          }}
          selectedRecordings={downloadRecordings.selectedRecordings}
        />
      )}
      {/* Delete Recordings */}
      {deleteRecordings && (
        <DeleteRecordingsDialog
          open={deleteRecordings && deleteRecordings.open}
          handleClose={(e) => {
            deleteRecordings && deleteRecordings.onClose && deleteRecordings.onClose();
            setDeleteRecordings(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          eventId={deleteRecordings && deleteRecordings.eventId}
          selectedRecordings={deleteRecordings.selectedRecordings}
        />
      )}
      {/* Share Recordings */}

      {shareRecordings && (
        <ShareRecordingsDialog
          open={shareRecordings && shareRecordings.open}
          handleClose={(e) => {
            shareRecordings && shareRecordings.onClose && shareRecordings.onClose();
            setShareRecordings(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          selectedRecordings={shareRecordings.selectedRecordings}
        />
      )}

      {recordingPermissions && (
        <RecordingPermissionsDialog
          open={recordingPermissions && recordingPermissions.open}
          handleClose={(e) => {
            recordingPermissions && recordingPermissions.onClose && recordingPermissions.onClose();
            setRecordingPermissions(null);
          }}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
          eventId={recordingPermissions.eventId}
        />
      )}

      {editRecording && (
        <RecordingFormDialog
          open={editRecording && editRecording.open}
          handleClose={(e) => {
            editRecording && editRecording.onClose && editRecording.onClose();
            setEditRecording(null);
          }}
          folder={editRecording.selectedRecording}
          onSuccess={(e) => {
            setSnackbar({ open: true, severity: e.severity, message: e.message });
          }}
        />
      )}

      {/* Snackbar Confirmation */}
      {snackbar && (
        <SuperSnackbar
          isOpen={snackbar.open}
          onClose={() => setSnackbar(null)}
          message={snackbar.message}
          severity={snackbar.severity}
        />
      )}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  return React.useContext(DialogContext);
};

export { DialogProvider, useDialog };
