import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import SearchBar from "../exhibitsList/SearchBar";
import { useFilePermissionsService } from "../../../services/FilePermissionsService";
import { isNil, update } from "lodash";
import { useDataGridStyles } from "../../../components/datagrid.styles";

const LIST_PAGE_LENGTH = 25;

const AddUserToPermissionsDialog = ({
  open,
  handleClose,
  onSuccess,
  fileId,
  firmId,
  currentFolder,
  eventId,
}) => {
  const classes = useDataGridStyles();
  const { addFilePermissions, listFirmMembers, listEventParticipantContacts } =
    useFilePermissionsService();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: "firstName", sort: "asc" }]);
  const [sortParam, setSortParam] = useState("firstName:asc");
  const [firmMembers, setFirmMembers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState();
  const [saving, setSaving] = useState();

  let contactTypeMap = new Map([
    [1, "Super Admin"],
    [2, "Admin"],
    [3, "Support"],
    [4, "Videographer"],
    [5, "Court Reporter"],
    [6, "Firm Member"],
    [7, "Participant"],
    [8, "ACAdmin"],
    [100, "Captioner"],
    [101, "Digital Reporter"],
    [102, "Interpreter"],
    [103, "Reporter"],
    [104, "Reporting Agency"],
    [105, "Transcriber"],
    [106, "Videographer"],
    [110, "Other"],
  ]);

  const columns = [
    {
      field: "userId",
      headerName: "Name",
      width: 500,
      minWidth: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            {" "}
            {params.row.contact
              ? params.row.contact.firstName + " " + params.row.contact.lastName
              : params.row.firstName + " " + params.row.lastName}{" "}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <span>{params.row.contact ? params.row.contact.email2 : params.row.email2}</span>;
      },
    },
    {
      field: "firmContactType",
      headerName: "Type",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            {params.row.contact
              ? contactTypeMap.get(params.row.contact.contactTypeId)
              : params.row.firmContactType}
          </span>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (isNil(open)) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        let _sortParam = sortParam;
        if (sortModel?.length > 0) {
          _sortParam = `${sortModel[0].field}:${sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        let firmMembers;
        if (currentFolder?.name && currentFolder?.isUnderFinalExhibit) {
          firmMembers = await listEventParticipantContacts({
            query: searchQuery,
            pageIndex,
            pageLength,
            sortParam: _sortParam,
            eventId,
          });
        } else {
          firmMembers = await listFirmMembers({
            firmId,
            query: searchQuery,
            pageIndex,
            pageLength,
            sortParam: _sortParam,
            status: 1,
          });
        }
        if (null != firmMembers) {
          setFirmMembers(firmMembers.content);
          setPagination(firmMembers.totalElements);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [open, searchQuery, pageIndex, pageLength, sortModel]);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const handleSavePermissions = async () => {
    try {
      setLoading(true);
      setSaving(true);
      let filePermissions = [];
      selectedIds.forEach((id) => {
        filePermissions.push({
          fileId: fileId,
          user: {
            contactId: id,
          },
        });
      });
      await addFilePermissions(fileId, filePermissions);
      handleClose();
      onSuccess({
        severity: "success",
        message: `The selected users have been added to the permissions list.`,
      });
    } catch (error) {
      console.log("Unexpected error while saving file permissions...", error);
      // onError({
      //   severity: "error",
      //   message: `There was an unexpected error while saving recording permissions.`,
      // });
      handleClose();
    } finally {
      setLoading(false);
      setSaving(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <CRXDialogTitle onClick={handleClose} title="Add User to Exhibit Permissions List" />
        <Box className={classes.buttonBar}>
          <Box width={1} display="flex" flexDirection="row" justifyContent="flex-end">
            <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
          </Box>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSavePermissions(e);
            return false;
          }}
        >
          <DialogContent>
            <div style={{ height: "calc(100vh - 400px)", width: "100%" }}>
              {firmMembers && (
                <DataGridPro
                  className={classes.paper}
                  rows={firmMembers}
                  columns={columns}
                  rowHeight={48}
                  pagination
                  paginationMode="server"
                  pageSize={pageLength}
                  rowCount={pagination}
                  checkboxSelection
                  getRowId={(row) => (row.contact ? row.contact.contactId : row.contactId)}
                  loading={loading}
                  sortingMode="server"
                  onPageChange={(newPage) => setPageIndex(newPage)}
                  onPageSizeChange={(newPageSize) => {
                    setPageLength(newPageSize);
                  }}
                  onSortModelChange={handleSortModelChange}
                  onSelectionModelChange={(value) => {
                    setSelectedIds(value);
                  }}
                  sx={{
                    boxShadow: 0,
                    border: 1,
                    borderColor: "#e4e4e4",
                    "& .MuiDataGrid-cell": {
                      borderColor: "#e4e4e4",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      borderColor: "#e4e4e4",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderColor: "#e4e4e4",
                    },
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={saving}
              variant="contained"
              color="primary"
              disabled={selectedIds.length < 1}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { AddUserToPermissionsDialog };
