// Font Awesome Icons
import React from "react";
import { Icon } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFilePowerpoint,
  FaFileAudio,
  FaFileVideo,
  FaFile,
} from "react-icons/fa";

const icon = {
  width: 22,
  height: 22,
  margin: 4,
};

function getIcon(file) {
  if (file.fileType === 0) {
    return <FolderIcon style={icon} />;
  } else if (file.fileType === 1) {
    const exhibitName = file.exhibitName || file.name; // Handle both single and mult-select cases
    return getExhibitIcon(exhibitName);
  }
}

export const getExhibitIcon = (exhibitName) => {
  if (exhibitName?.includes("pdf")) {
    return <PictureAsPdfIcon style={icon} />;
  } else if (exhibitName?.includes(".xls") || exhibitName?.includes(".xlsx")) {
    return <FaFileExcel style={icon} />;
  } else if (exhibitName?.includes(".doc") || exhibitName?.includes(".docx")) {
    return <FaFileWord style={icon} />;
  } else if (exhibitName?.includes(".mp3")) {
    return <FaFileAudio style={icon} />;
  } else if (exhibitName?.includes(".mp4")) {
    return <FaFileVideo style={icon} />;
  } else if (exhibitName?.includes(".ppt") || exhibitName?.includes(".pptx")) {
    return <FaFilePowerpoint style={icon} />;
  } else if (
    exhibitName?.includes(".jpeg") ||
    exhibitName?.includes(".jpg") ||
    exhibitName?.includes(".png") ||
    exhibitName?.includes(".gif") ||
    exhibitName?.includes(".tif") ||
    exhibitName?.includes(".tiff") ||
    exhibitName?.includes(".bmp")
  ) {
    return <FaFileImage style={icon} />;
  } else if (exhibitName?.includes(".doc") || exhibitName?.includes(".docx")) {
    return <FaFileWord style={icon} />;
  } else {
    return <FaFile style={icon} />;
  }
};

export const getFileIcon = (file) => {
  return <Icon color="primary">{getIcon(file)}</Icon>;
};

export default getFileIcon;
