/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { find, forEach, merge } from "lodash";
import moment from "moment";
import { recordingsData } from "./recordingData";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  eventId: null,
  recordings: null,
  recordingsListParams: null,
};

let breadcrumbs = []; // QW-TODO: Refactor into data

const RecordingsContext = React.createContext();

const RecordingsProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  /**
   * List Recordings.
   *
   * @param {*} folderId
   * @param {*} clearCache
   * @returns
   */
  const LIST_PAGE_LENGTH = 15;
  const listRecordings = async (
    eventId,
    searchQuery = "",
    pageNumber = 0,
    pageLength = LIST_PAGE_LENGTH,
    sortParam = "name:asc",
    clearCache = true
  ) => {
    // Now get the list of recordings for the current folder
    const config = {
      method: "get",
      url: `/sf/recordings/?eventId=${eventId}&pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,
    };

    clearCache && setData({ clear: "recordings" });
    clearCache && setData({ clear: "recordingsListParams" });
    //clearCache && setData({ clear: "breadcrumbs" });
    console.log("Inside listRecording eventId is", eventId);
    if (searchQuery) {
      config.url += `&name=${searchQuery}`;
    }

    try {
      setData({ loading: true });
      const { data: resp } = await http(config);
      setData({
        recordings: resp.content,
        recordingsListParams: {
          searchQuery,
          pagination: {
            totalElements: resp.totalElements,
            totalPages: resp.totalPages,
            pageNumber: resp.number,
          },
        },
      });

      return recordings;
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        console.log("Access forbidden error in listRecordings");
        throw error;
      }
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getRecording = async (recordingId) => {
    const config = {
      method: "get",
      url: `/sf/recordings/${recordingId}`,
    };

    try {
      setData({ loading: true });
      const { data: recording } = await http(config);
      return recording;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const playRecording = async (recordingId) => {
    // console.log("XXX playRecording", recordingId);
    const config = {
      method: "get",
      url: `/sf/recordings/${recordingId}/play`,
    };

    try {
      setData({ loading: true });
      const { data: recording } = await http(config);
      return recording;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Download recordings.
   *
   */
  const downloadRecordings = async (recordingIds = []) => {
    console.log("downloadRecordings", recordingIds);
    let recordingIdParam = "";
    forEach(recordingIds, (recordingId) => {
      recordingIdParam += `recordingId=${recordingId}&`;
    });

    const config = {
      method: "get",
      url: `/sf/recordings/download?${recordingIdParam}`,
      responseType: "blob",
    };

    try {
      setData({ loading: true });
      const response = await http(config);

      // Generate download link from response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const today = moment(new Date()).toISOString();
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `recordings_${today}.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Download a single recording.
   *
   */
  const downloadRecording = async (recording = null, onDownloadProgress) => {
    const config = {
      method: "get",
      url: `/sf/recordings/${recording.fileId}/download`,
      responseType: "blob",
      onDownloadProgress,
    };

    try {
      setData({ loading: true });
      const response = await http(config);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${recording.name}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Share recordings.
   *
   */
  const shareRecordings = async (recordingIds = [], recipients, subject, message) => {
    // console.log("sharerecordings", recordingIds, recipients, subject, message);
    let recordingIdParam = "";
    recordingIds.forEach((recordingId, index) => {
      if (index === 0) {
        recordingIdParam += `recordingId=${recordingId}`;
      } else {
        recordingIdParam += `&recordingId=${recordingId}`;
      }
    });

    const shareEmailData = {
      recipients: recipients,
      subject: subject,
      body: message,
    };

    const config = {
      method: "post",
      url: `/sf/recordings/shareemail?${recordingIdParam}`,
      data: shareEmailData,
    };

    try {
      setData({ loading: true });
      await await http(config);
      setData({ loading: false });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete recordings.
   */
  const deleteRecordings = async (recordingIds = []) => {
    let recordingIdParam = "";
    forEach(recordingIds, (recordingId) => {
      recordingIdParam += `recordingId=${recordingId}&`;
    });

    const config = {
      method: "delete",
      url: `/sf/recordings?${recordingIdParam}`,
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete a single recording.
   */
  const deleteRecording = async (recordingId) => {
    const config = {
      method: "delete",
      url: `/sf/recordings/${recordingId}`,
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * List Event Participants.   This is used for displaying recording permissions.
   */
  const listEventParticipants = async (
    eventId,
    searchQuery = "",
    pageNumber = 0,
    pageLength = LIST_PAGE_LENGTH,
    sortParam = "user.firstName:asc"
  ) => {
    console.log("EventService.listEventParticipants()");
    try {
      const config = {
        method: "get",
        url: `/hc/events/${eventId}/participants?pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,`,
      };
      if (searchQuery) {
        config.url += `&firstName=${searchQuery}&lastName=${searchQuery}&email=${searchQuery}`;
      }

      const { data: participantData } = await http(config);
      setData({ loading: false, participants: participantData });
      return participantData;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  /**
   * Update Event Participants.  This is used for updating recording permissions.
   */
  const updateEventParticipants = async (eventId, eventParticipants) => {
    console.log("EventService.updateEventParticipants()");
    const config = {
      method: "put",
      url: `/hc/events/${eventId}/participants`,
      data: eventParticipants,
    };
    try {
      const { data: participants } = await http(config);
      setData({ loading: false, participants });
      return participants;
    } catch (error) {
      setData({ loading: false, error: true });
      throw error;
    }
  };

  /**
   * Update Recording.
   *
   * @param {*} fileId
   * @param {*} name
   * @param {*} description
   * @returns
   */
  const updateRecording = async ({ fileId, name, description }) => {
    const config = {
      method: "put",
      url: `/sf/files/${fileId}`,
      data: {
        name: name,
        description: description,
      },
    };

    try {
      setData({ loading: true });
      const { data: folder } = await http(config);
      setData({
        folder: folder,
      });

      return folder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  return (
    <RecordingsContext.Provider
      value={{
        data,
        setData,
        listRecordings,
        getRecording,
        playRecording,
        downloadRecording,
        downloadRecordings,
        shareRecordings,
        deleteRecordings,
        deleteRecording,
        listEventParticipants,
        updateEventParticipants,
        updateRecording,
      }}
    >
      {props.children}
    </RecordingsContext.Provider>
  );
};

const useRecordingsService = () => React.useContext(RecordingsContext);

export { RecordingsContext, RecordingsProvider, useRecordingsService };
