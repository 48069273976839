import React, { useEffect, useState, useContext, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { isEmpty, isNil } from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikTextField } from "../../common/FormComponents";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import useSnackbar from "../../common/snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "54ch",
    },
  },
  button: {
    width: 200,
    margin: 10,
    fontSize: "10pt",
    [theme.breakpoints.down("lg")]: {
      flex: "1",
    },
  },
  formikInput: {
    [theme.breakpoints.up("md")]: {
      minWidth: "430px",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "300px",
      flex: "4",
    },
  },
  formContainer: {
    [theme.breakpoints.up("md")]: {
      minWidth: "430px",
    },
  },
}));

/**
 * Folder schema for Yup.
 */
const FolderSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is a required field")
    .trim(true)
    .min(0)
    .max(255)
    .matches(
      /^[^/\\"<>:*?|'{}$]+$/,
      "Name cannot contain any of the following characters: \"/\\<>:*?|'{}$"
    ),
  description: Yup.string().trim(true).min(0).max(255),
});

const FolderFormDialog = ({ open, handleClose, onSuccess, folder = null }) => {
  const [dialogTitle, setDialogTitle] = useState("New Folder");
  const classes = useStyles();
  const { createFolder, updateFolder, listExhibits } = useContext(ExhibitsContext);
  const [loading, setLoading] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  // submit button reference
  const submitBtn = useRef(null);

  useEffect(() => {
    if (folder?.fileId) setDialogTitle("Edit Folder");
  }, [open]);

  const handleSubmit = async (values, { setFieldError }) => {
    const name = values.name;
    const description = values.description;
    const permissions = ""; // e.target.permissions.value;

    try {
      setLoading(true);
      const folderData = {
        name,
        description,
        permissions,
      };

      let operation = null;
      let folderId;
      if (folder.fileId != null) {
        operation = "updated";
        folderData.fileId = folder.fileId;
        const updated = await updateFolder(folderData);
        folderId = updated.folderId;
      } else {
        operation = "created";
        folderData.folderId = folder.folderId;
        const created = await createFolder(folderData);
        folderId = created.folderId;
      }
      // refresh the exhibits list.
      await listExhibits(folderId);

      handleClose();

      // show the snackbar.
      onSuccess({
        severity: "success",
        message: `The folder ${name} has been successfully ${operation}.`,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 409) {
        await setFieldError("name", ex.response.data.message);
        return;
      } else if (ex.response) {
        showSnackbar(ex.response.data.message, "error");
      } else {
        showSnackbar("Something went wrong. Please try again.", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{ name: folder?.name || "", description: folder?.description || "" }}
      validationSchema={FolderSchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Dialog fullWidth={false} maxWidth={"md"} open={open}>
          <CRXDialogTitle onClick={handleClose} title={dialogTitle} />
          <Form>
            <DialogContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                justifyContent="flex-start"
                m={0}
              >
                <Box m={1}>
                  <FormikTextField
                    id="name"
                    name="name"
                    label="Name"
                    required
                    className={classes.formikInput}
                    autoFocus
                  />
                </Box>
                <Box m={1}>
                  <FormikTextField
                    id="description"
                    label="Description"
                    style={{
                      width: "100%",
                    }}
                    multiline
                    rows="5"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="primary">
                Cancel
              </Button>
              {/* <button type="submit">Submit</button> */}
              <LoadingButton
                type="submit"
                loading={loading}
                ref={submitBtn}
                variant="contained"
                color="primary"
                // formNoValidate
                disabled={!isEmpty(errors)}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
          {SnackbarComponent}
        </Dialog>
      )}
    </Formik>
  );
};

export { FolderFormDialog };
