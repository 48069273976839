import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import getFileIcon from "../../../hooks/useFileIcons";
import { forEach } from "lodash";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    width: "100%",
    margin: 2,
    marginRight: 10,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const DownloadExhibitsDialog = ({ open, handleClose, onSuccess, folderId, selectedFiles = [] }) => {
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const { data, listExhibits, downloadFiles } = React.useContext(ExhibitsContext);
  const [status, setStatus] = useState("not-started");

  useEffect(() => {
    let tmp = [];
    let ids = [];
    setStatus("in-progress");

    if (!open) return;

    forEach(selectedFiles, (file) => {
      tmp.push(
        <div
          key={file.fileId}
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {getFileIcon(file)}
          <div>{file.name}</div>
        </div>
      );

      ids.push(file.fileId);
    });
    setSelectedFilesUI(tmp);

    // Download files
    const downloadNow = async () => {
      try {
        await downloadFiles(ids);
        setStatus("complete");
      } catch (err) {
        console.log(err);
        setStatus("error");
      }
    };
    downloadNow();
  }, [open]);

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose}>
        <CRXDialogTitle onClick={handleClose} title="Download Confirmation" />
        <form
          style={{
            width: "100%",
          }}
        >
          <DialogContent>
            <div>You have selected the following files to download:</div>
            <Box style={{ padding: "20px", width: "600px" }}>{selectedFilesUI}</Box>
            {status === "in-progress" && (
              <div>
                We are generating and downloading a zip file containing all the selected files.
                Thank you for your patience.
              </div>
            )}
            {status === "complete" && (
              <div>
                Your zip file containing your Exhibits has been downloaded. Please check your
                Downloads folder.
              </div>
            )}
            <Box width={1} my={2} display="flex" flexDirection="row" justifyContent="space-between">
              <BorderLinearProgress
                variant={status === "complete" ? "determinate" : "indeterminate"}
              />
              {status === "complete" && <CheckIcon style={{ color: "green" }} />}
              {status === "in-progress" && <CircularProgress size={20} />}
              {status === "error" && <ErrorIcon style={{ color: "red" }} />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { DownloadExhibitsDialog };
