import React from "react";
import { Box, RadioGroup, TextField } from "@mui/material";
import { useField } from "formik";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export const FormikTextField = ({
  id,
  label,
  variant = "outlined",
  style = {},
  formatValue = null,
  ...props
}) => {
  const [field, meta] = useField({ ...props, name: id });

  // React.useEffect(() => {
  //   console.log("XXX FormikTextField", id, label, props);
  // }, [props]);

  return (
    <TextField
      variant="standard"
      {...{
        ...props,
        ...field,
        value: formatValue ? formatValue(field.value) : field.value,
        id,
        label,
        variant,
        style,
      }}
      name={id}
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
    />
  );
};

export const FormikRadioGroup = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props });
  const classes = useStyles();

  return (
    <RadioGroup
      {...{
        ...props,
        ...field,
        value: field?.value?.toString(),
        defaultValue: field?.value?.toString(),
      }}
      row
      className={classes.radioContainer}
    >
      <Box width="25%">{props.label}</Box>
      <Box width="75%">{children}</Box>
    </RadioGroup>
  );
};
