/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import { useExhibitsService } from ".../../../services/ExhibitsService";
import { useRecordingsService } from "../../../services/RecordingsService";
import { useDialog } from "../../common/providers/DialogProvider";

const useStyles = makeStyles(() => ({
  recordingCell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  nameColumn: {
    width: "100%",
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const RecordingMenuButton = ({
  recordingId,
  recordingName,
  description,
  fileType,
  folderId,
  fileSize,
  onRecordingSelected,
  isDeleteEnabled,
  eventId,
}) => {
  const classes = useStyles();
  const { setDownloadRecording, setDeleteRecordings, setShareRecordings, setEditRecording } =
    useDialog();
  const { isAdminMode } = useExhibitsService();
  const { data, setData, downloadRecording } = useRecordingsService();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRecordingClick = () => {
    console.log("handleRecordingClick");
    setData({ clear: "selectedRecording" });
    setData({
      selectedRecording: {
        recordingId: recordingId,
        name: recordingName,
        description: description,
        fileSize: fileSize,
        evetId: eventId,
      },
    });
  };

  const handlePlayRecordingClick = (e) => {
    e.preventDefault();
    console.log("handlePlayRecordingClick", recordingId);
    window.open(`/recordings/${recordingId}`, "_blank");
  };

  const handleRecordingMenuClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadRecording = async () => {
    setAnchorEl(null);
    setDownloadRecording({
      open: true,
      selectedRecording: {
        fileId: recordingId,
        name: recordingName,
        description: description,
        fileSize: fileSize,
        eventId: eventId,
      },
    });
  };

  const handleShareRecording = async () => {
    setAnchorEl(null);
    setShareRecordings({
      open: true,
      selectedRecordings: [
        {
          recordingId: recordingId,
          name: recordingName,
          description: description,
          eventId: eventId,
        },
      ],
    });
  };

  const handleEditRecording = (e) => {
    setAnchorEl(null);
    setEditRecording({
      open: true,
      selectedRecording: {
        fileId: recordingId,
        name: recordingName,
        description: description,
        fileSize: fileSize,
        eventId: eventId,
      },
    });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteRecordings({
      open: true,
      selectedRecordings: [
        {
          recordingId: recordingId,
          name: recordingName,
          description: description,
          folderId: folderId,
          eventId: eventId,
        },
      ],
      folderId: folderId,
    });
  };

  return (
    <>
      <Box className={classes.nameColumn}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: "500px",
          }}
        >
          <Tooltip title="Click to play Recording" placement="right-start">
            <IconButton
              color="primary"
              aria-label="preview-recording"
              component="span"
              size="small"
              onClick={handlePlayRecordingClick}
            >
              <VideoLibraryIcon />
            </IconButton>
          </Tooltip>
          <Link className={classes.link}>{recordingName}</Link>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80px",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Click to play Recording" placement="right-start">
            <IconButton
              color="primary"
              aria-label="recording-menu"
              component="span"
              size="small"
              onClick={handlePlayRecordingClick}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="More options" placement="right-start">
            <IconButton
              color="primary"
              aria-label="recording-menu"
              component="span"
              size="small"
              onClick={handleRecordingMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Menu
        id="recording-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditRecording}>
          <ListItemIcon>
            <EditIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={handleDownloadRecording}>
          <ListItemIcon>
            <CloudDownloadIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </MenuItem>
        <MenuItem onClick={handleShareRecording} divider={isAdminMode()}>
          <ListItemIcon>
            <ShareIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Share" />
        </MenuItem>
        {isAdminMode() && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

RecordingMenuButton.defaultProps = {
  isDeleteEnabled: true,
};

export default RecordingMenuButton;
