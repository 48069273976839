/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { instance as http, AuthContext } from "@cirrux888/huseby-client-auth";
import { useSocketIOService } from "@cirrux888/huseby-client-exhibit-editor";
import { find, forEach, isNil, merge } from "lodash";
import socket, { TYPE_MESSAGE } from "../components/exhibits/useSocket";
import moment from "moment";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  identity: null, // The logged-in user's identity fetched from getIdentity().
  isAuthorized: false,
  eventId: null, // The eventId
  rootResource: null, // The Event's resource record.
  folderId: null, // The current folderId that we are in.
  eventParticipant: null,
  isHost: null, // editorState
  rootFolder: null,
  currentFolder: null,
  exhibits: null,
  exhibitListParams: null,
  eventData: null,
  caseData: null,
};

const editorState = {
  isMeetingRoom: false,
};

let breadcrumbs = []; // QW-TODO: Refactor into data

const ExhibitsContext = React.createContext();

const ExhibitsProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const { auth, authenticate, getIdentity } = React.useContext(AuthContext);
  const { data: socketData, setData: setSocketData } = useSocketIOService();
  const [editorObject, setEditorObject] = React.useReducer(reducer, editorState);

  /**
   * Initialize model.
   *
   * @param {*} eventId
   * @returns
   */
  const initializeApp = async ({ eventId, folderId, isMeetingRoom }) => {
    console.log("Initializing ExhibitManager application...");
    console.log("- eventId", eventId);

    // Check if user is authenticated.
    await authenticate();

    // If user is authenticated, then initialize application.
    if (auth.token !== undefined) {
      // Set data.eventId
      setData({ eventId: eventId });

      // Set data.identity
      console.log("Getting logged-in user's identity...");
      const identity = JSON.parse(getIdentity());
      const username = identity.username;
      const name = identity.name;
      const userId = identity.userId;
      setData({ identity: identity });

      // Set editor state.
      setEditorObject({ isMeetingRoom: isMeetingRoom });

      // Get Event's root Resource.
      const rootResource = await getRootResource(eventId);
      const eventDetails = await getExhibitsDetails(eventId);
      setData({ eventData: eventDetails.event });
      setData({ caseData: eventDetails.myCase });
      setData({ rootResource: rootResource });

      // Get Event's root folder.
      setData({ clear: "currentFolder" });
      let rootFolder = await getUserFolder(rootResource.hcResourceId, username, userId);
      console.log("XXX rootFolder", rootFolder);

      // If event.exhibits === SIMPLE_MODE, then redirect to the Final Exhibits folder.
      const exhibitsMode = eventDetails.event.exhibits;
      const SIMPLE_MODE = 2;
      if (isNil(rootFolder.fileId) && exhibitsMode === SIMPLE_MODE) {
        console.log(
          "This user does not have a root folder.  For SIMPLE_MODE, we will redirect to Final Exhibits folder..."
        );
        const bookmarks = await listBookmarkedFiles(eventId);
        rootFolder = bookmarks[0]; // Get the Final Exhibits bookmark
      }

      setData({ rootFolder: rootFolder });
      setData({ currentFolder: rootFolder });

      if (isNil(folderId) || folderId === null) {
        folderId = rootFolder.fileId;
        setData({ folderId: folderId });
      }

      // Get list of exhibits at the root folder.
      console.log("Getting list of exhibits at the specified folder...", folderId);
      await listExhibits(folderId);

      // Setup SocketIO Connection
      try {
        console.log("Getting event participant info...");
        const eventParticipant = await getEventParticipant(eventId, identity.contactId);
        setData({ eventParticipant: eventParticipant });
        setData({ isAuthorized: true });
        setData({ exhibitMode: "simple" });

        let _isHost = false;
        if (
          eventParticipant.eventParticipantType.eventParticipantTypeId === 1 || // Internal
          eventParticipant.eventParticipantType.eventParticipantTypeId === 2 // Firm Contact
        ) {
          _isHost = true;
        } else if (
          eventParticipant.eventParticipantType.eventParticipantTypeId === 3 &&
          exhibitsMode === SIMPLE_MODE
        ) {
          _isHost = true;
        }
        setData({ isHost: _isHost });
        // setIsHost(_isHost);
        setSocketData({ _isHost }); // Move to useSocket?

        // Socket.IO stuff
        console.log("Checking if SocketIO session exists...");
        const sessionID = localStorage.getItem("sessionID");

        const role = _isHost ? "host" : "participant";
        const roomId = eventId;

        // Set room data
        console.log("Setting room data", roomId, username, name, role);
        setSocketData({
          user: {
            userId,
            username,
            name,
            roomId,
            role,
          },
        });

        // QW-TODO: Refactor into joinRoom in huseby-client-meeting.
        console.log("Joining room...", userId, username, name, roomId, role);
        socket.auth = { userId, username, name, roomId, role };
        socket.connect();
        console.log("Connected user to room.", socket);

        // QW-TODO: Refactor into handleNewSessionId
        socket.on("session", ({ userId, username, name, roomId, role }) => {
          // attach the session ID to the next reconnection attempts
          console.log("handleNewSessionId", userId, username, name, roomId, role);
          socket.auth = { userId, username, name, roomId, role };
          socket.userId = userId;
        });
      } catch (error) {
        // setIsHost(false);
        // setNotAuthorized(true);
        setData({ isHost: false });
        setData({ isAuthorized: false });
        return;
      }
    }
  };

  //	1	Super Admin
  //	2	Admin
  //	3	Support
  //	4	Videographer
  //	5	Court Reporter
  //	6	Firm Member
  //	7	Participant
  //	8	ACAdmin
  const isAdminMode = () => {
    const myContact = JSON.parse(localStorage.getItem("huseby-identity"));

    const contactTypeId = myContact.contactTypeId;
    return contactTypeId === 1 || contactTypeId === 2 || contactTypeId === 3;
  };

  const getEventParticipant = async (eventId, contactId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants/${contactId}`,
    };

    try {
      setData({ loading: true });
      const { data: eventParticipant } = await http(config);
      // console.log("XXX getEventParticipant", eventParticipant);
      return eventParticipant;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getFirmContact = async (contactId) => {
    const config = {
      method: "get",
      url: `/hc/firms/contacts/${contactId}`,
    };

    try {
      setData({ loading: true });
      const { data: firmContact } = await http(config);
      // console.log("XXX getFirmContact", firmContact);
      return firmContact;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Get Root Resource by the specified resourceId.   The Resource points
   * to the File's parent object in HusebyConnect.  Although the
   * API can handle resourceIds for Firms, Cases and Events, for the
   * ExhibitManager, we are expecting the resourceId to be for
   * an Event.
   *
   * @param {*} resourceId
   * @returns
   */
  const getRootResource = async (resourceId) => {
    const config = {
      method: "get",
      // url: `/sf/files/root?resourceId=${resourceId}&resourceType=event`,
      url: `/sf/resource?hcResourceId=${resourceId}&hcResourceType=event`,
    };
    try {
      setData({ loading: true });
      const { data: resources } = await http(config);

      // Set the rootResource and breadcrumbs
      setData({ rootResource: resources.content[0] });

      // addBreadcrumb({
      //   fileId: resource.fileId,
      //   exhibitName: resource.name,
      //   fileType: resource.fileType,
      // });
      return resources.content[0];
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API is used to fetch a event details by eventId.
   * <br/>
   * @param {*} eventId
   */
  const getExhibitsDetails = async (eventId) => {
    const config = {
      method: "get",
      url: `hc/events/${eventId}`,
    };
    try {
      setData({ loading: true });
      const { data: exhibitData } = await http(config);
      return exhibitData;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API is used to fetch a resource's root folder by its resourceId.
   * A resource in this case refers to a HusebyConnect resource, such as
   * a Firm, Case or Event.
   * <br/>
   * A typical use of this API is to fetch the root folder for
   * an Event by passing in the Event's unique id.   From there,
   * additional Folders or Exhibits can be created or uploaded
   * by passing in the Exhibit's folderId.
   *
   * @return
   */
  const getRootFolder = async (eventId) => {
    const config = {
      method: "get",
      url: `/sf/files/root?resourceId=${eventId}&resourceType=event`,
    };
    try {
      setData({ loading: true });
      const { data: resource } = await http(config);
      return resource;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API returns the user folder associated with a User and a Resource.
   * The root folder depends on who the logged-in user is.  If the logged-in user
   * is an Administrator, then the root folder return by `getUserFolder` will be the
   * root folder of the Event.  If the logged-in user is not an Administrator, then the
   * folder returned is the user's private folder.
   *
   * @param {*} eventId
   * @param {*} username
   * @returns
   */
  const getUserFolder = async (eventId, username, userId) => {
    const config = {
      method: "get",
      url: `/sf/files/users?resourceId=${eventId}&username=${username}&userId=${userId}`,
    };
    try {
      setData({ loading: true });
      const { data: resource } = await http(config);
      return resource;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API returns the user folder associated with a User and a Resource by folderId.
   *
   * @param {*} folderId
   * @returns
   */
  const getUserFolderByFileId = async (folderId) => {
    const config = {
      method: "get",
      url: `/sf/files/users/by-folder-id?folderId=${folderId}`,
    };
    try {
      setData({ loading: true });
      const { data: userFolder } = await http(config);
      return userFolder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API is used to list File objects for the specified folderId.
   *
   * @param {*} folderId
   * @param {*} searchQuery
   * @param {*} pageNumber
   * @param {*} pageLength
   * @param {*} sortParam
   * @param {*} clearCache
   * @returns
   */
  const LIST_PAGE_LENGTH = 15;
  const listExhibits = async (
    folderId,
    searchQuery = "",
    pageNumber = 0,
    pageLength = LIST_PAGE_LENGTH,
    sortParam = "name:asc",
    clearCache = true
  ) => {
    // Now get the list of files for the current folder
    const config = {
      method: "get",
      url: `/sf/files/?folderId=${folderId}&pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,
    };

    clearCache && setData({ clear: "exhibits" });
    clearCache && setData({ clear: "exhibitListParams" });
    clearCache && setData({ clear: "breadcrumbs" });

    if (searchQuery) {
      config.url += `&name=${searchQuery}`;
    }

    try {
      setData({ loading: true });
      const { data: exhibits } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        exhibits: exhibits.files,
        exhibitListParams: {
          searchQuery,
          pagination: {
            totalElements: exhibits.totalElements,
            totalPages: exhibits.totalPages,
            pageNumber: exhibits.number,
          },
        },
      });

      setData({ clear: "breadcrumbs" });
      setData({ breadcrumbs: exhibits.breadcrumbs });

      return exhibits;
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        console.log("Access forbidden error in listExhibits");
        throw error;
      }
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API fetches a single File object.   If the specified fileId
   * points to an Exhibit, then additional details for the Exhibit will be
   * returned as well.
   *
   * @param {*} fileId
   * @returns
   */
  const getFile = async (fileId) => {
    const config = {
      method: "get",
      url: `/sf/files/${fileId}`,
    };

    try {
      setData({ loading: true });
      const { data: file } = await http(config);
      return file;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Create Folder.
   *
   * Since the Exhibit Manager is only for creating folders and exhibits that are used in Events, we
   * set the hcResourceId to -1 and set the hcResourceType to 'folder'.
   *
   * @param {*} folderId
   * @param {*} hcResourceId
   * @param {*} folderName
   * @param {*} folderDescription
   * @returns
   */
  const createFolder = async ({ folderId, name, description, permissions }) => {
    const folderData = {
      folderId: folderId,
      hcResourceId: data.eventId, // For folders under an event, the hcResourceId = eventId
      hcResourceType: "folder",
      name: name,
      description: description,
      permissions: permissions,
      fileType: 0,
      sharefileId: "",
    };

    const config = {
      method: "post",
      url: `/sf/files`,
      data: folderData,
    };

    try {
      setData({ loading: true });
      const { data: folder } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        folder: folder,
      });
      console.log("Folder created successfully!", folder);
      return folder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Update Folder.
   *
   * @param {*} fileId
   * @param {*} name
   * @param {*} description
   * @returns
   */
  const updateFolder = async ({ fileId, name, description, permissions }) => {
    const config = {
      method: "put",
      url: `/sf/files/${fileId}`,
      data: {
        name: name,
        description: description,
        permissions: permissions,
      },
    };

    try {
      setData({ loading: true });
      const { data: folder } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        folder: folder,
      });

      return folder;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Create Exhibit.
   *
   * Since the Exhibit Manager is only for creating folders and exhibits that are used in Events, we
   * set the hcResourceId to -1 and set the hcResourceType to 'exhibit'.
   *
   * @param {*} folderId
   * @param {*} name
   * @param {*} description
   * @returns
   */
  const createExhibit = async ({ folderId, name, description }) => {
    // Add extension
    if (data?.editorMode === "advanced" && !name.toLowerCase().endsWith(".pdf")) {
      name = name + ".pdf";
    }
    const exhibitData = {
      folderId: folderId,
      hcResourceId: data.eventId, // For events under an event, the hcResourceId = eventId
      hcResourceType: "exhibit",
      name: name,
      description: description,
      fileType: 1, // 'exhibit' - unfortunately an issue in JPA / Hibernate prevents us from using strings for discriminator values.
      sharefileId: "",
    };

    const config = {
      method: "post",
      url: `/sf/files`,
      data: exhibitData,
    };

    try {
      setData({ loading: true });
      const { data: exhibit } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        exhibit: exhibit,
      });
      console.log("Exhibit created successfully!", exhibit);
      return exhibit;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Update an Exhibit.
   *
   * @param {*} fileId
   * @param {*} name
   * @param {*} description
   * @returns
   */
  const updateExhibit = async ({ fileId, name, description }) => {
    // Add extension
    if (data?.editorMode === "advanced" && !name.toLowerCase().endsWith(".pdf")) {
      name = name + ".pdf";
    }
    const config = {
      method: "put",
      url: `/sf/files/${fileId}`,
      data: {
        name: name,
        description: description,
      },
    };

    try {
      setData({ loading: true });
      const { data: exhibit } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        exhibit: exhibit,
      });
      console.log("Exhibit updated successfully!", exhibit);
      return exhibit;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Update an Exhibit Number.
   *
   * @param {*} fileId
   * @param {*} name
   * @param {*} description
   * @returns
   */
  const updateExhibitNumber = async ({ fileId, exhibitNumber, eventId }) => {
    const config = {
      method: "put",
      url: `/sf/files/${fileId}/updateExhibitNumber?eventId=${eventId}&exhibitNumber=${encodeURIComponent(
        exhibitNumber
      )}`,
    };

    try {
      setData({ loading: true });
      const { data: exhibit } = await http(config);
      // assignDataToCase([caseId], { exhibits });?
      setData({
        exhibit: exhibit,
      });
      console.log("Exhibit updated successfully!", exhibit);
      return exhibit;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Upload an Exhibit.
   *
   * @param {*} fileId
   * @param {*} fileToUpload
   * @param {*} onUploadProgress
   */
  const uploadFile = async (fileId, fileToUpload, onUploadProgress) => {
    // console.log("uploadFile", fileId, fileToUpload);
    const formData = new FormData();
    formData.append("file", fileToUpload);

    const config = {
      method: "put",
      url: `/sf/files/${fileId}/upload`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress,
    };
    try {
      setData({ loading: true });
      const results = await http(config);
      console.log("Exhibit uploaded successfully!", results);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API downloads multiple Exhibits in a single Zip file.
   *
   * @param {*} fileIds
   */
  const downloadFiles = async (fileIds = []) => {
    console.log("downloadFiles", fileIds);
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `fileId=${fileId}&`;
    });

    const config = {
      method: "get",
      url: `/sf/files/download?${fileIdParam}`,
      responseType: "blob",
    };

    try {
      setData({ loading: true });
      const response = await http(config);

      // Generate download link from response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const today = moment(new Date()).toISOString();
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exhibits_${today}.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * This REST API downloads a single physical File associated with an Exhibit.
   *
   * @param {*} file
   * @param {*} onDownloadProgress
   */
  const downloadFile = async (file = null, onDownloadProgress) => {
    const config = {
      method: "get",
      url: `/sf/files/${file.fileId}/download`,
      responseType: "blob",
      onDownloadProgress,
    };

    try {
      setData({ loading: true });
      const response = await http(config);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Move files.
   *
   */
  const moveFiles = async (destFolderId, fileIds = []) => {
    console.log("moveFiles", destFolderId, fileIds);
    const config = {
      method: "put",
      url: `/sf/files/${destFolderId}/move`,
      data: fileIds,
    };

    try {
      setData({ loading: true });
      const response = await http(config);
      setData({ loading: false });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const listMoveDestinations = async (eventId, moveFileIds) => {
    // console.log("listMoveDestinations", folderId, moveFileIds);
    const config = {
      method: "put",
      url: `/sf/files/${eventId}/destinations`,
      data: moveFileIds,
    };
    try {
      setData({ loading: true });
      const { data: destinations } = await http(config);
      // console.log("XXX destinations", destinations);
      return destinations;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const checkFinalExhibitsSelected = (selectedFiles) => {
    for (let i in selectedFiles) {
      // console.log("XXX _selectedFiles[i]", selectedFiles[i]);
      if (selectedFiles[i].exhibitName === "Final Exhibits") {
        // console.log("XXX isFinalExhibitsSelected");
        return true;
      }
      return false;
    }
  };

  /**
   * Share files.
   *
   */
  const shareFiles = async (fileIds = [], recipients, subject, message) => {
    // console.log("shareFiles", fileIds, recipients, subject, message);
    let fileIdParam = "";
    fileIds.forEach((fileId, index) => {
      if (index === 0) {
        fileIdParam += `fileId=${fileId}`;
      } else {
        fileIdParam += `&fileId=${fileId}`;
      }
    });

    const shareEmailData = {
      recipients: recipients,
      subject: subject,
      body: message,
    };

    const config = {
      method: "post",
      url: `/sf/files/shareemail?${fileIdParam}`,
      data: shareEmailData,
    };

    try {
      setData({ loading: true });
      await await http(config);
      setData({ loading: false });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Bookmark Services.
   *
   */
  const listBookmarks = async (eventId) => {
    const config = {
      method: "get",
      url: `/sf/bookmarks`,
    };

    try {
      setData({ loading: true });
      const response = await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const listBookmarkedFiles = async (eventId) => {
    const config = {
      method: "get",
      url: `/sf/bookmarks/files?hcResourceId=${eventId}`,
    };

    try {
      setData({ loading: true });
      const { data: bookmarks } = await http(config);
      return bookmarks;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const addBookmark = async (fileIds = []) => {
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `fileId=${fileId}&`;
    });

    const config = {
      method: "post",
      url: `/sf/bookmark?${fileIdParam}`,
    };

    try {
      setData({ loading: true });
      const response = await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const removeBookmark = async (fileIds = []) => {
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `fileId=${fileId}&`;
    });

    const config = {
      method: "delete",
      url: `/sf/bookmark?${fileIdParam}`,
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete files.
   */
  const deleteFiles = async (fileIds = []) => {
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `fileId=${fileId}&`;
    });

    const config = {
      method: "delete",
      url: `/sf/files?${fileIdParam}`,
    };

    try {
      setData({ loading: true });
      await http(config);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Retract files.
   */
  const retractFiles = async (fileIds = []) => {
    let fileIdParam = "";
    forEach(fileIds, (fileId) => {
      fileIdParam += `${fileId}`;
    });

    const config = {
      method: "put",
      url: `/sf/files/${fileIdParam}/retract`,
    };

    try {
      setData({ loading: true });
      const response = await http(config);
      return response.data;
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * Delete a single file.
   */
  const deleteFile = async (fileId) => {
    const config = {
      method: "delete",
      url: `/sf/files/${fileId}`,
    };

    try {
      setData({ loading: true });
      await http(config);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  /**
   * List Final Exhibits.  This can be used to list the exhibitNumbers for the 'Publish Exhibits' folder.
   */
  const listFinalExhibits = async (eventId) => {
    const bookmarks = await listBookmarkedFiles(eventId);
    const finalExhibitFolderId = bookmarks.find((bookmark) => bookmark.icon === "final_exhibits");

    // Now get the list of files for the current folder
    const folderId = finalExhibitFolderId.fileId;
    const pageLength = 100;
    const pageNumber = 0;
    const sortParam = "dateModified:asc";
    const config = {
      method: "get",
      url: `/sf/files/?folderId=${folderId}&pageLength=${pageLength}&pageIndex=${pageNumber}&sortParam=${sortParam}`, //&${urlParams}`,
    };

    const { data: finalExhibits } = await http(config);

    return finalExhibits;
  };

  /**
   * Get the next exhibit number.  This is used for automated exhibit numbering.
   * @param {*} eventId
   * @returns
   */
  const getNextExhibitNumber = async (eventId) => {
    console.log("getNextExhibitNumber", eventId);
    const config = {
      method: "get",
      url: `/sf/files/event/${eventId}/next-exhibit-number`,
    };
    try {
      const nextResp = await http(config);
      return nextResp;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <ExhibitsContext.Provider
      value={{
        editorObject,
        setEditorObject,
        data,
        setData,
        initializeApp,
        isAdminMode,
        getEventParticipant,
        getFirmContact,
        getRootResource,
        getRootFolder,
        getUserFolder,
        getUserFolderByFileId,
        listExhibits,
        listFinalExhibits,
        getFile,
        createFolder,
        updateFolder,
        createExhibit,
        updateExhibit,
        updateExhibitNumber,
        uploadFile,
        downloadFile,
        downloadFiles,
        moveFiles,
        listMoveDestinations,
        checkFinalExhibitsSelected,
        shareFiles,
        listBookmarks,
        listBookmarkedFiles,
        addBookmark,
        removeBookmark,
        deleteFiles,
        retractFiles,
        deleteFile,
        getExhibitsDetails,
        fetchS3FileBlob,
        getNextExhibitNumber,
        // listFirmMembers,
        // addFirmMember
      }}
    >
      {props.children}
    </ExhibitsContext.Provider>
  );
};

/**
 * Get file information for viewing.
 *
 * @param {*} fileId
 * @returns
 */
const viewFile = async (fileId, isPresenting) => {
  // console.log("Get file information for viewing...", fileId);

  try {
    const viewConfig = {
      method: "get",
      url: `/sf/files/${fileId}/view?isPresenting=${encodeURIComponent(isPresenting)}`,
    };

    const { data } = await http(viewConfig);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const validateExhibitNumber = async (eventId, exhibitNumber, fileId) => {
  console.log("validateExhibitNumber", eventId);
  const config = {
    method: "get",
    url: `/sf/files/event/${eventId}/validate-exhibit-number?exhibitNumber=${encodeURIComponent(
      exhibitNumber
    )}&fileId=${encodeURIComponent(fileId)}`,
  };

  try {
    const nextResp = await http(config);
    return nextResp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchS3FileBlob = async (s3Url) => {
  console.log("fetchS3FileBlob", s3Url);

  // const corsImageModified = new Image();
  // corsImageModified.crossOrigin = "anonymous";
  // corsImageModified.src = s3Url + "?cacheblock=true";

  return new Promise((resolve, reject) => {
    fetch(`${s3Url}?whatever`, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const publishExhibitAndUpdateExhibitNumber = async (fileId, exhibitNumber, onPublishProgress) => {
  console.log("publishExhibitAndUpdateExhibitNumber", fileId, exhibitNumber);

  try {
    // Download the file in memory
    const downloadConfig = {
      method: "get",
      url: `/sf/files/${fileId}/download`,
      responseType: "blob",
    };
    const response = await http(downloadConfig);
    const fileBlob = new Blob([response.data]);

    // Upload the file for publishing.
    const formData = new FormData();
    formData.append("file", fileBlob);

    const config = {
      method: "put",
      url: `/sf/files/${fileId}/publish?exhibitNumber=${encodeURIComponent(exhibitNumber)}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
      onPublishProgress,
    };

    await http(config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const useExhibitsService = () => React.useContext(ExhibitsContext);

export {
  ExhibitsContext,
  ExhibitsProvider,
  useExhibitsService,
  viewFile,
  validateExhibitNumber,
  publishExhibitAndUpdateExhibitNumber,
};
