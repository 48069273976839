import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { initial, isEmpty, isNil, update } from "lodash";
import SearchBar from "../exhibitsList/SearchBar";
import { useDialog } from "../../common/providers/DialogProvider";
import { useFilePermissionsService } from "../../../services/FilePermissionsService";
import { useDataGridStyles } from "../../../components/datagrid.styles";
import { Default, Mobile } from "../../common/Responsive";

const LIST_PAGE_LENGTH = 15;

const ExhibitPermissionsDialog = ({
  open,
  handleClose,
  onSuccess,
  eventId,
  fileId,
  firmId,
  currentFolder,
}) => {
  const classes = useDataGridStyles();
  const { listFilePermissions, updateFilePermissions, removeFilePermissions } =
    useFilePermissionsService();
  const { setAddUserToPermissions } = useDialog();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: "user.firstName", sort: "asc" }]);
  const [sortParam, setSortParam] = useState("user.firstName:asc");
  const [filePermissions, setFilePermissions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [initialPermissions, setInitialPermissions] = useState(null);

  const columns = [
    {
      field: "user.firstName",
      headerName: "Name",
      flex: 1,
      minWidth: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            {params.row.user.firstName} {params.row.user.lastName}
          </span>
        );
      },
    },
    {
      field: "user.email",
      headerName: "Email",
      flex: 1,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <span>{params.row.user.email}</span>;
      },
    },
    {
      field: "user.contactType",
      headerName: "Contact Type",
      flex: 1,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <span>{`${params.row.user.contactType ? params.row.user.contactType : ""}`}</span>;
      },
    },
    {
      field: "grantCreateUpdate",
      headerName: "Create/Edit",
      width: 125,
      resizable: false,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantCreateUpdate}
              disabled={
                params.row.user.contactTypeId == 1 ||
                params.row.user.contactTypeId == 2 ||
                params.row.user.contactTypeId == 3
              }
              onChange={(e) =>
                handlePermissionsChecked(params.row.user.userId, e.currentTarget, "upload")
              }
            />
          </span>
        );
      },
    },
    {
      field: "grantShare",
      headerName: "Share",
      width: 125,
      resizable: false,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantShare}
              disabled={
                params.row.user.contactTypeId == 1 ||
                params.row.user.contactTypeId == 2 ||
                params.row.user.contactTypeId == 3
              }
              onChange={(e) =>
                handlePermissionsChecked(params.row.user.userId, e.currentTarget, "share")
              }
            />
          </span>
        );
      },
    },
    {
      field: "grantDelete",
      headerName: "Delete",
      width: 125,
      resizable: false,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantDelete}
              disabled={
                params.row.user.contactTypeId == 1 ||
                params.row.user.contactTypeId == 2 ||
                params.row.user.contactTypeId == 3
              }
              onChange={(e) =>
                handlePermissionsChecked(params.row.user.userId, e.currentTarget, "delete")
              }
            />
          </span>
        );
      },
    },
    {
      field: "grantManagePermissions",
      headerName: "Permissions",
      width: 125,
      resizable: false,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantManagePermissions}
              disabled={
                params.row.user.contactTypeId == 1 ||
                params.row.user.contactTypeId == 2 ||
                params.row.user.contactTypeId == 3
              }
              onChange={(e) =>
                handlePermissionsChecked(
                  params.row.user.userId,
                  e.currentTarget,
                  "managePermissions"
                )
              }
            />
          </span>
        );
      },
    },
    {
      field: "grantDownload",
      headerName: "Download",
      width: 125,
      resizable: false,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <span>
            <Checkbox
              color="primary"
              checked={params.row.grantDownload}
              disabled={
                params.row.user.contactTypeId == 1 ||
                params.row.user.contactTypeId == 2 ||
                params.row.user.contactTypeId == 3
              }
              onChange={(e) =>
                handlePermissionsChecked(params.row.user.userId, e.currentTarget, "download")
              }
            />
          </span>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (isNil(open)) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        let _sortParam = sortParam;
        if (sortModel?.length > 0) {
          _sortParam = `${sortModel[0].field}:${sortModel[0].sort}`;
          setSortParam(_sortParam);
        }
        console.trace(
          "ExhibitPermissions::ExhibitPermissionsDialog::useEffect - Before Getting list of permission FileId:",
          fileId
        );
        console.trace(
          "ExhibitPermissions::ExhibitPermissionsDialog::useEffect - Before Getting list of permission Seach Query: ",
          searchQuery
        );
        const filePermissions = await listFilePermissions({
          fileId,
          searchQuery,
          pageIndex,
          pageLength,
          sortParam: _sortParam,
        });
        console.trace(
          "ExhibitPermissions::ExhibitPermissionsDialog::useEffect - After Getting list of permission FilePermissions: ",
          filePermissions
        );
        setFilePermissions(filePermissions.content);

        setPagination(filePermissions.totalElements);

        if (!initialPermissions) {
          //deep clone object to prevent reference of filePermission
          var tempPermission = JSON.parse(JSON.stringify(filePermissions.content));
          setInitialPermissions(tempPermission);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [open, searchQuery, pageIndex, pageLength, sortModel]);

  useEffect(() => {
    const hasPermissionsChanged = () => {
      if (
        filePermissions &&
        filePermissions.length > 0 &&
        initialPermissions &&
        initialPermissions.length > 0
      ) {
        for (let i = 0; i < filePermissions.length; i++) {
          const currentPermission = filePermissions[i];
          if (
            initialPermissions[i]?.grantCreateUpdate !== currentPermission?.grantCreateUpdate ||
            initialPermissions[i]?.grantShare !== currentPermission?.grantShare ||
            initialPermissions[i]?.grantDelete !== currentPermission?.grantDelete ||
            initialPermissions[i]?.grantManagePermissions !==
              currentPermission?.grantManagePermissions ||
            initialPermissions[i]?.grantDownload !== currentPermission?.grantDownload
          ) {
            setHasChanged(true);
            return;
          }
        }
        setHasChanged(false);
      }
    };

    hasPermissionsChanged();
  }, [filePermissions, selectedIds, initialPermissions]);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const handleAddUserToPermissionsClick = (e) => {
    setAddUserToPermissions({
      open: true,
      fileId: fileId,
      firmId: firmId,
      currentFolder: currentFolder,
      eventId: eventId,
      onClose: refreshList,
    });
  };

  const refreshList = async (e) => {
    try {
      setLoading(true);
      const filePermissions = await listFilePermissions({
        fileId,
        query: searchQuery,
        pageIndex,
        pageLength,
        sortParam,
      });
      setFilePermissions(filePermissions.content);
      setPagination(filePermissions.totalElements);
      var tempPermission = JSON.parse(JSON.stringify(filePermissions.content));
      setInitialPermissions(tempPermission);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUserFromPermissionsClick = async (e) => {
    try {
      setLoading(true);
      await removeFilePermissions(fileId, selectedIds);
      refreshList();
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionsChecked = (userId, currentTarget, permissionsType) => {
    const tempPermission = filePermissions.map((p) => {
      if (permissionsType === "upload" && p.user.userId == userId) {
        p.grantCreateUpdate = currentTarget.checked;
      }
      if (permissionsType === "share" && p.user.userId == userId) {
        p.grantShare = currentTarget.checked;
      }
      if (permissionsType === "delete" && p.user.userId == userId) {
        p.grantDelete = currentTarget.checked;
      }
      if (permissionsType === "managePermissions" && p.user.userId == userId) {
        p.grantManagePermissions = currentTarget.checked;
      }
      if (permissionsType === "download" && p.user.userId == userId) {
        p.grantDownload = currentTarget.checked;
      }
      return p;
    });
    setFilePermissions(tempPermission);

    // Ensure the row is selected if any checkbox is checked, or deselected if all are unchecked
    setSelectedIds((prevIds) => {
      const updatedPermission = tempPermission.find((p) => p.user.userId === userId);
      const allUnchecked =
        !updatedPermission.grantCreateUpdate &&
        !updatedPermission.grantShare &&
        !updatedPermission.grantDelete &&
        !updatedPermission.grantManagePermissions &&
        !updatedPermission.grantDownload;

      let newSelectedIds = prevIds;

      if (allUnchecked) {
        newSelectedIds = prevIds.filter((id) => id !== userId);
      } else if (!prevIds.includes(userId)) {
        newSelectedIds = [...prevIds, userId];
      }
      // Force the DataGridPro to update its selection state
      setFilePermissions((currentPermissions) =>
        currentPermissions.map((perm) => ({
          ...perm,
          selected: newSelectedIds.includes(perm.user.userId),
        }))
      );
      return newSelectedIds;
    });
    // setSelectedIds((prevIds) => {
    //   const selectedIndex = prevIds.indexOf(userId);
    //   if (selectedIndex > -1) {
    //     return [
    //       ...prevIds,
    //       ...prevIds.slice(0, selectedIndex),
    //       ...prevIds.slice(selectedIndex + 1),
    //     ];
    //   } else {
    //     return [...prevIds, userId];
    //   }
    // });
  };

  const handleSavePermissions = async () => {
    if (isEmpty(selectedIds)) {
      console.log("No changes to save.");
      handleClose();
      return;
    }

    try {
      setLoading(true);
      setSaving(true);
      await updateFilePermissions(fileId, filePermissions);
      handleClose();
      onSuccess({
        severity: "success",
        message: `The folder permissions have been successfully updated.`,
      });
    } catch (error) {
      console.log("Unexpected error while saving folder permissions...", error);
      onError({
        severity: "error",
        message: `There was an unexpected error while saving folder permissions.`,
      });
      handleClose();
    } finally {
      setLoading(false);
      setSaving(false);
    }
  };

  const buttonState = () => {
    if (hasChanged) {
      return false;
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <CRXDialogTitle onClick={handleClose} title="Exhibits Permissions" />
        <Box className={classes.buttonBar}>
          <Box width={1} display="flex" flexDirection="row" justifyContent="flex-start">
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddUserToPermissionsClick}
              label="Add Attendee"
            >
              {currentFolder?.name && currentFolder?.isUnderFinalExhibit
                ? "Add"
                : "Add Firm Member"}
            </Button>
            <Box component="span" display="inline" p={0.5}></Box>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              startIcon={<RemoveIcon />}
              disabled={isEmpty(selectedIds)}
              onClick={handleRemoveUserFromPermissionsClick}
            >
              {currentFolder?.name && currentFolder?.isUnderFinalExhibit
                ? "Remove"
                : "Remove Firm Member"}
            </Button>
          </Box>
          <Default>
            <Box width={1} display="flex" flexDirection="row" justifyContent="flex-end">
              <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
            </Box>
          </Default>
        </Box>
        <Mobile>
          <Box
            width={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            style={{ padding: 10 }}
          >
            <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
          </Box>
        </Mobile>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSavePermissions(e);
          }}
        >
          <DialogContent>
            <div style={{ height: "calc(100vh - 400px)", width: "100%" }}>
              <Box className={classes.container} style={{ height: "100%" }}>
                <Box className={classes.datagridContainer}>
                  {filePermissions && (
                    <DataGridPro
                      className={classes.paper}
                      rows={filePermissions}
                      columns={columns}
                      rowHeight={48}
                      pagination
                      paginationMode="server"
                      pageSize={pageLength}
                      rowCount={pagination}
                      onPageChange={(newPage) => setPageIndex(newPage)}
                      onPageSizeChange={(newPageSize) => {
                        setPageLength(newPageSize.pageSize);
                      }}
                      checkboxSelection
                      disableSelectionOnClick
                      getRowId={(row) => row.user.userId}
                      loading={loading}
                      selectionModel={selectedIds}
                      sortingMode="server"
                      onSortModelChange={handleSortModelChange}
                      onSelectionModelChange={(value) => {
                        setSelectedIds(value);
                      }}
                      sx={{
                        height: "55vh",
                        boxShadow: 0,
                        border: 1,
                        borderColor: "#e4e4e4",
                        "& .MuiDataGrid-cell": {
                          borderColor: "#e4e4e4",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderColor: "#e4e4e4",
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderColor: "#e4e4e4",
                        },
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={saving}
              variant="contained"
              color="primary"
              disabled={buttonState()}
            >
              {isEmpty(selectedIds) && !hasChanged ? "Ok" : "Save"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { ExhibitPermissionsDialog };
