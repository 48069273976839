/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import _, { isNil, merge } from "lodash";
import socket from "../components/useSocket";
import { getRoom } from "./RoomService";
import Cookies from "js-cookie";

export const ROLE_HOST = "host";
export const ROLE_COHOST = "co-host";
export const ROLE_PARTICIPANT = "participant";

const emitRoleChanged = (e) => {
  console.log("emitRoleChanged", e);
  const message = {
    userId: e.userId,
    username: e.username,
    name: e.name,
    role: e.role,
    roomId: e.roomId
  };
  socket.emit("roleChanged", { data: message, roomId: e.roomId });
};

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  user: {
    userID: null,
    username: null,
    name: null,
    role: ROLE_PARTICIPANT,
    roomId: null
  },
  roomId: -1,
  attendees: []
};

const SocketIOContext = React.createContext();
const SocketIOProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);
  const [isConnected, setIsConnected] = React.useState(null);

  /**
   * Join a HusebyConnect Room.
   *
   * @param {*} userId
   * @param {*} username
   * @param {*} name
   * @param {*} roomId
   * @param {*} role
   */
  const joinRoom = (userId, username, name, roomId, role) => {
    console.log("Joining room...", userId, username, name, roomId, role);

    socket.auth = { userId, username, name, roomId, role };
    socket.connect();

    console.log("Connected user to room.", socket);
  };

  /**
   * Leave the room.   This disconnects the socket.
   *
   */
  const leaveRoom = () => {
    socket.disconnect();
  };

  /**
   *
   * @param {*} param0
   */
  const handleNewSessionId = ({ userId, username, name, roomId, role }) => {
    // attach the session ID to the next reconnection attempts
    console.log("handleNewSessionId", userId, username, name, roomId, role);
    socket.auth = { userId, username, name, roomId, role };
    socket.userId = userId;
  };

  /**
   *
   * @param {*} users
   */
  const handleUserListing = (users) => {
    let _attendees = JSON.parse(users);
    setData({ clear: "attendees" });
    setData({ attendees: _attendees });
  };

  /**
   *
   * @param {*} connected
   */
  const handleOnUserConnected = async (connected) => {
    setData({ clear: "attendees" });
    updateRoomAttendees();
  };

  /**
   *
   * @param {*} disconnected
   */
  const handleOnUserDisconnected = async (disconnected) => {
    setData({ clear: "attendees" });
    updateRoomAttendees();
  };

  const updateRoomAttendees = async () => {
    // Look up user.  If this user already exists, then update their record.
    // QW-TODO
    const roomInfo = await getRoom(data.roomId);

    let userList = [];
    const entries = Object.entries(roomInfo.users);
    const map = new Map(entries);
    map.forEach((v, k) => {
      userList.push(v);
    });
    setData({ attendees: userList });
  };

  /**
   *
   * @param {*} changed
   */
  const handleRoleChanged = (changed) => {
    console.log("role changed", data, changed);
    console.log("changed", changed);
    if (data.user && data.user.username === changed.username) {
      console.log("changing the logged in user's role...");
      let _user = _.cloneDeep(data.user);
      console.log("cloned _user", _user);
      _user.role = changed.role;
      console.log("updated user", _user);
      setData({ clear: "user" });
      setData({ user: _user });
      if (_user.role === ROLE_HOST) setData({ isHost: true });
    }

    let _attendees = _.cloneDeep(data.attendees);
    setData({ clear: "attendees" });
    _attendees.forEach((a) => {
      if (a.username === changed.username) a.role = changed.role;
    });
    setData({ attendees: _attendees });
  };

  /**
   * Launch the exhibit.  This emits an 'exhibitLaunched' event.
   *
   * @param {*} roomId
   * @param {*} exhibit
   * @param {*} presenter
   */
  const launchExhibit = (roomId, exhibit, presenter) => {
    console.log("SocketIOService.launchExhibit()...", roomId, presenter);

    const message = {
      type: "exhibitLaunched",
      data: exhibit,
      src: presenter
    };

    socket.emit("HusebyMeeting.message", { data: message, roomId });
  };

  /**
   *
   * @param {*} msg
   */
  const handleSyncExhibitState = (msg) => {
    const exhibitSettings = JSON.parse(msg);

    setData({ clear: "exhibitSettings" });
    setData({ exhibitSettings });
  };

  /**
   *
   */
  const handleSocketConnect = () => {
    console.log("Handling socket connection...");
    console.log("-- attempting to join room...", data.user);

    joinRoom(
      data.user.userId,
      data.user.username,
      data.user.name,
      data.user.roomId,
      data.user.role
    );
  };

  /**
   *
   */
  const handleSocketConnectError = () => {
    console.log("Handling socket connection error...");
  };

  /**
   *
   * @param {*} reason
   */
  const handleSocketDisconnect = (reason) => {
    console.log("Handling socket disconnect", reason);
  };

  /**
   * Initialize socket event handlers.
   */
  React.useEffect(() => {
    socket.on("session", handleNewSessionId);
    socket.on("users", handleUserListing);
    socket.on("userJoined", handleOnUserConnected);
    socket.on("userLeft", handleOnUserDisconnected);
    socket.on("roleChanged", handleRoleChanged);
    socket.on("syncExhibitState", handleSyncExhibitState);
    socket.on("connect", handleSocketConnect);
    socket.on("connect_error", handleSocketConnectError);
    socket.on("disconnect", handleSocketDisconnect);
  }, []);

  return (
    <SocketIOContext.Provider
      value={{
        data,
        setData,
        joinRoom,
        launchExhibit,
        leaveRoom
      }}
    >
      {props.children}
    </SocketIOContext.Provider>
  );
};

const useSocketIOService = () => React.useContext(SocketIOContext);

export {
  SocketIOContext,
  SocketIOProvider,
  useSocketIOService,
  emitRoleChanged
};
