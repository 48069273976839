import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { forEach, isEmpty, isNil } from "lodash";
import Joi from "joi-browser";
import { validate, validateProperty, setErrorMessages } from "../../common/validateUtilities";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import getFileIcon from "../../../hooks/useFileIcons";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    "& form .MuiDialogContent-root": {
      width: "100%",
      margin: 0,
      padding: 0,
      overflowY: "scroll",
    },
    "& form": {
      overflowY: "scroll",
    },
    "& .MuiDialogContent-root": {
      marginBottom: 20,
    },
    "& .MuiPaper-root": {
      overflow: "hidden",
    },
    "& form div:nth-child(2)": {
      position: "sticky",
      bottom: 0,
      backgroundColor: "#ffffff",
      paddingRight: 14,
    },
  },
}));

const ShareExhibitsDialog = ({ open, handleClose, onSuccess, selectedFiles }) => {
  const classes = useStyles();
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const [selectedFilesEmail, setSelectedFilesEmail] = useState("");
  const { shareFiles } = useContext(ExhibitsContext);
  const [recipients, setRecipients] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (isNil(selectedFiles)) return;

    let tmp = [];
    forEach(selectedFiles, (file) => {
      tmp.push(
        <div
          key={file.fileId}
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {getFileIcon(file)}
          <div>{file.name}</div>
        </div>
      );
    });
    setSelectedFilesUI(tmp);

    let tmpEmail = "";
    forEach(selectedFiles, (file) => {
      tmpEmail += `\n- ${file.name}`;
    });
    setSelectedFilesEmail(tmpEmail);
  }, [selectedFiles]);

  if (!open) {
    return null;
  }

  const schema = {
    subject: Joi.string().required().trim(true).min(0).max(512).label("Subject"),
    message: Joi.string().required().trim(true).min(0).max(512).label("Message"),
  };

  const handleConfirmShare = async (e, files) => {
    console.log("handleConfirmShare");
    // e.preventDefault();
    try {
      setLoading(true);
      let recipients = e.target.recipients.value;
      let subject = e.target.subject.value;
      let message = e.target.message.value;

      // Split recipients
      let recipientList = recipients.split(",");
      console.log("recipientList", recipientList);
      // Validate emails
      // QW-TODO:
      let arrayLength = recipientList.length;
      setErrorMessages("recipients", null, setErrors);
      for (let i = 0; i < arrayLength; i++) {
        console.log("email is", recipientList[i]);
        let isValid = validateEmail(recipientList[i]);
        if (isValid === false) {
          console.log("email is", recipientList[i]);
          setErrorMessages("recipients", "Invalid email", setErrors);
          console.log("email is invalid");
          break;
        }
      }

      console.log(recipientList, subject, message);
      let ids = [];
      files.forEach((file) => {
        ids.push(file.fileId);
      });
      console.log("ids", ids);

      await shareFiles(ids, recipientList, subject, message);
      handleClose();

      onSuccess({
        severity: "success",
        message: `An email with links to download the selected files has been sent.`,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regEmail.test(email)) {
      console.log("Email is not valid");
      return false;
    } else {
      console.log("Email is valid");
      return true;
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        className={classes.root}
      >
        <CRXDialogTitle onClick={handleClose} title="Share Exhibits" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmShare(e, selectedFiles);
          }}
        >
          <DialogContent>
            <div
              style={{
                height: "75vh",
                width: "100%",
                padding: "20px",
              }}
            >
              <Box style={{ marginBottom: "10px" }}>
                <div>The following Exhibits will be shared to your selected recipients.</div>
                {selectedFilesUI}
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Please use the form below to select your recipients and enter in a message to send
                  to them.
                </div>
                <TextField
                  id="recipients"
                  label="Recipients"
                  name="recipients"
                  placeholder="Enter email addresses for recipients"
                  variant="outlined"
                  required
                  fullWidth
                  autoFocus
                  error={errors && !isEmpty(errors["recipients"])}
                  helperText={errors && errors["recipients"]}
                  // value={searchQuery}
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    disableunderline: true,
                  }}
                />
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  The following email will be sent to your recipients. This email is not editable.
                </div>
                <TextField
                  id="subject"
                  label="Subject"
                  name="subject"
                  variant="outlined"
                  disabled
                  fullWidth
                  error={errors && !isEmpty(errors["subject"])}
                  helperText={errors && errors["subject"]}
                  onChange={(e) => {
                    setSubject(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                  defaultValue={"An Exhibit Download link has been shared with you."}
                />
                <br />
                <br />
                <TextField
                  id="message"
                  label="Message"
                  name="message"
                  multiline
                  rows={10}
                  variant="outlined"
                  disabled
                  fullWidth
                  error={errors && !isEmpty(errors["message"])}
                  helperText={errors && errors["message"]}
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                    validateProperty(schema, e.currentTarget, setErrors);
                  }}
                  defaultValue={
                    `A link has been shared with you to download the following Exhibits:` +
                    `\n` +
                    `${selectedFilesEmail}` +
                    `\n\n` +
                    `Please click on the link to download the Exhibits.` +
                    `\n\n\${DOWNLOAD_LINK}` +
                    `\n\nRegards,` +
                    `\n\nThe Huseby Team`
                  }
                />
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
              Send Email
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { ShareExhibitsDialog };
