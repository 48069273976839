/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { find, forEach, isEmpty, isNil, merge } from "lodash";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { useFilePermissionsService } from "../../../services/FilePermissionsService";
import socket, { TYPE_MESSAGE, TYPE_EXHIBIT_NUMBER_UPDATED } from "../useSocket";
import BreadcrumbTrail from "./BreadcrumbTrail";
import ExhibitMenuButton from "./ExhibitMenuButton";
import FolderMenuButton from "./FolderMenuButton";
import SearchBar from "../exhibitsList/SearchBar";
import { Default, Mobile } from "../../common/Responsive";
import { ContactTypeEnum } from "../../common/model/ContactTypeEnum";
import { useDialog } from "../../common/providers/DialogProvider";
import { useDataGridStyles } from "../../../components/datagrid.styles";

// Icons
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
import ShareIcon from "@mui/icons-material/Share";
import PublishExhibitDialog from "../../../components/common/ViewerModal/PublishExhibitDialog";
import EditableTextField from "./EditableTextField";

const LIST_PAGE_LENGTH = 30;

// Exhibit Modes
const SIMPLE_MODE = 2;
const supportedContactTypes = [
  ContactTypeEnum.SuperAdmin,
  ContactTypeEnum.Admin,
  ContactTypeEnum.Support,
  ContactTypeEnum.FirmMember,
];

const useStyles = makeStyles((theme) => ({
  exhibitListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 85px)", // 8px from padding
  },
  dropHere: {
    backgroundColor: "#ffffff",
    backgroundImage: "url(/images/drop_here.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 50%",
  },
  dropHere90: {
    backgroundColor: "#ffffff",
    backgroundImage: "url(/images/drop_here.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 90%",
  },
  dropzoneContainer: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  dropzone: {
    backgroundColor: "#ffffff",
    padding: "0px",
    borderColor: "#999999",
    borderWidth: "0px",
    borderStyle: "none",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  button: {
    fontSize: "10pt",
    "&.Mui-disabled": {
      color: "#ddadb2",
    },
  },
  link: {
    display: "flex",
    fontSize: 12,
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
}));

const getFileSize = (fileSize) => {
  const BYTES_TO_MB = 1000000; //1048576;
  const BYTES_TO_KB = 1000;

  if (fileSize < 1000000) return Number.parseFloat(fileSize / BYTES_TO_KB).toFixed(2) + "KB";
  else return Number.parseFloat(fileSize / BYTES_TO_MB).toFixed(2) + "MB";
};

const Exhibits = ({
  onExhibitSelected,
  onExhibitEditorLaunched,
  onExhibitManagerClosed = null,
  folderId,
}) => {
  const classes = useStyles();
  const dgClasses = useDataGridStyles();
  const {
    data,
    setData,
    isAdminMode,
    listExhibits,
    getFile,
    getUserFolderByFileId,
    moveFiles,
    listBookmarkedFiles,
    checkFinalExhibitsSelected,
    updateExhibitNumber,
  } = React.useContext(ExhibitsContext);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({});
  const { getRootFilePermissions, listFilePermissions } = useFilePermissionsService();
  const {
    setDownloadExhibits,
    setDeleteExhibits,
    setRetractExhibit,
    setMoveExhibits,
    setShareExhibits,
    setUploadFolderConfirmation,
    setExhibitPermissions,
  } = useDialog();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const [sortParam, setSortParam] = useState("name:asc");
  const [readOnly, setReadOnly] = useState(false);
  const [permissions, setPermissions] = useState();
  const [exhibitsMap, setExhibitsMap] = useState(new Map());
  const [filteredExhibits, setFilteredExhibits] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [loading, setLoading] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openUploadFolderConfirmation, setOpenUploadFolderConfirmation] = useState(false);
  const [exhibitColumns, setExhibitColumns] = useState([]);
  const [isRootFolder, setIsRootFolder] = useState(true);
  const [firmId, setFirmId] = useState();
  const [isFinalExhibitsSelected, setIsFinalExhibitsSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [forbidden, setForbidden] = useState(false);
  const [publishExhibitData, setPublishExhibitData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [exhibitNumberUpdated, setExhibitNumberUpdated] = useState(null);

  const exhibitColumnsNoExhibitNumber = [];
  const eventMeta = data.eventData;

  useEffect(() => {
    console.log("selectedIds", selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    // setExhibitColumns(exhibitColumnsNoExhibitNumber);
    const init = async () => {
      const bookmarks = await listBookmarkedFiles(data.eventId);
      setSharedFolders(bookmarks);
    };
    init();

    // Listen for exhibit number updating...
    if (eventMeta?.exhibits === SIMPLE_MODE) {
      console.log("Web sockets are enabled.");
      socket.on(TYPE_MESSAGE, (msg) => {
        if (msg.type === TYPE_EXHIBIT_NUMBER_UPDATED) {
          // Look up the file and update the exhibit number real-time
          setExhibitNumberUpdated(msg.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    const getUserFolder = async () => {
      if (isNil(folderId)) return;

      try {
        const _userFolder = await getUserFolderByFileId(folderId);
        setIsRootFolder(true);
        setFirmId(_userFolder.firmId);
      } catch (error) {
        setIsRootFolder(false);
      }
    };
    getUserFolder();
  }, [folderId]);

  useEffect(() => {
    if (sortModel.length > 0) {
      const _sortParam = `${sortModel[0].field}:${sortModel[0].sort}`;
      setSortParam(_sortParam);
    }
  }, [sortModel]);

  useEffect(() => {
    if (acceptedFiles.length > 0 || fileRejections.length > 0)
      setUploadFolderConfirmation({
        open: true,
        folderId: currentFolder != null ? currentFolder.fileId : null,
        acceptedFiles: acceptedFiles,
        fileRejections: fileRejections,
        onClose: () => {
          refreshExhibits();
          setUploadFolderConfirmation({
            acceptedFiles: [],
          });
        },
        onUploadAllFilesComplete: () => {
          // console.log("onUploadAllFilesComplete");
        },
      });
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    console.log("Updating the Exhibits list...", data.exhibits);
    let exhibitsList = data.exhibits;

    const exhibits = !isNil(exhibitsList)
      ? exhibitsList.map((ex) =>
          merge(ex, {
            fileId: `${ex.fileId}`,
            exhibitName: ex.name,
            // witnessName: ex.witnessVO.fullName,
          })
        )
      : [];

    // Map the selectedIds to selectedFiles.  This wasn't necessary
    // before with DataGrid, but for some reason we have to use
    // onSelectionModelChange, which only returns a list of ids.
    // useEffect(() => {
    let _exhibitsMap = new Map();
    forEach(data.exhibits, (exhibit) => {
      _exhibitsMap.set(exhibit.fileId.toString(), exhibit);
    });
    // console.log({ exhibitsMap, selectedIds });
    setExhibitsMap(_exhibitsMap);

    setFilteredExhibits(exhibits);
  }, [data.exhibits]);

  useEffect(() => {
    if (data && data.exhibitListParams) {
      setPagination(data.exhibitListParams.pagination.totalElements);
    }
  }, [data.exhibitListParams]);

  // Handle the exhibit number being updated from Websocket message
  React.useEffect(() => {
    if (isNil(exhibitNumberUpdated)) return;

    try {
      const idx = data.exhibits.findIndex((x) => x.fileId === exhibitNumberUpdated.fileId);
      const updatedExhibit = data.exhibits[idx];
      if (updatedExhibit !== -1) {
        const _exhibits = data.exhibits;
        setData({ clear: "exhibits" });
        // Update the exhibit
        updatedExhibit.exhibitNumber = exhibitNumberUpdated.exhibitNumber;

        // Update the exhibits in the model
        _exhibits[idx] = updatedExhibit;
        setData({ exhibits: _exhibits });

        setSnackbar({
          open: true,
          severity: "success",
          message: "Exhibit number updated",
        });
      }
    } catch (e) {
      console.log("Some unknown error occurred....", e);
    }
  }, [exhibitNumberUpdated]);

  useEffect(() => {
    // console.log("XXXX useEffect.selectedIds");
    const _selectedFiles = selectedIds.map((id) => exhibitsMap.get(id));
    setSelectedFiles(_selectedFiles);
    const _isSelected = checkFinalExhibitsSelected(_selectedFiles);
    // console.log("XXXX _isSelected", _isSelected);
    setIsFinalExhibitsSelected(_isSelected);
  }, [selectedIds]);

  async function refreshExhibits(action = null) {
    if (currentFolder === null) return;

    let _exhibits;
    const fetchData = async () => {
      setLoading(true);
      if (sortModel.length > 0) {
        const _sortParam = `${sortModel[0].field}:${sortModel[0].sort}`;
        setSortParam(_sortParam);
      }
      _exhibits = await getAllExhibits(
        currentFolder.fileId,
        setForbidden,
        listExhibits,
        _exhibits,
        searchQuery,
        pageIndex,
        pageLength,
        sortParam
      );

      setLoading(false);
    };
    await fetchData();
    setSnackbar({
      open: true,
      message: "Exhibits refreshed",
      variant: "success",
    });
  }

  React.useEffect(() => {
    const fetchData = async () => {
      if (isNil(folderId)) return;

      try {
        setLoading(true);

        // get current folder
        setData({ clear: "currentFolder" });
        const currentFolder = await getFile(folderId);
        setCurrentFolder(currentFolder);
        setData({
          currentFolder: currentFolder,
        });

        // Reset selectedExhibit so that the previewPanel does not display an Exhibit
        setData({
          selectedExhibit: null,
        });

        const rootFilePermissions = await getRootFilePermissions(folderId);
        setPermissions(rootFilePermissions);
        setData({ permissions: rootFilePermissions });

        if (currentFolder?.isUnderFinalExhibit) {
          // setReadOnly(true);
          exhibitColumnsNoExhibitNumber.push({
            field: "exhibitNumber",
            headerAlign: "center",
            headerName: "Exhibit #",
            align: "center",
            minWidth: 175,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            // editable: true,
            renderCell: (params) => {
              if (params.row.fileType === 1) {
                return (
                  <EditableTextField
                    value={params.row.exhibitNumber}
                    handleUpdateExhibitNumber={handleUpdateExhibitNumber}
                    fileId={params.row.fileId}
                  />
                );
              } else {
                return <span>{`${params.row.exhibitNumber ? params.row.exhibitNumber : ""}`}</span>;
              }
            },
          });
        } else {
          setReadOnly(false);
        }

        // Init columns with permissions
        exhibitColumnsNoExhibitNumber.push({
          field: "name",
          headerName: "File Name",
          minWidth: 480,
          flex: 1,
          disableColumnMenu: true,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            if (params.row.fileType === 0)
              return (
                <FolderMenuButton
                  fileId={params.row.fileId}
                  exhibitName={params.row.exhibitName}
                  description={params.row.description}
                  fileType={params.row.fileType}
                  folderId={params.row.folderId}
                  onListExhibits={handleListExhibits}
                  permissions={rootFilePermissions}
                  isUnderFinalExhibit={params.row.isUnderFinalExhibit}
                />
              );
            else {
              return (
                <ExhibitMenuButton
                  fileId={params.row.fileId}
                  exhibitName={params.row.exhibitName}
                  description={params.row.description}
                  fileName={params.row.fileName}
                  fileType={params.row.fileType}
                  folderId={params.row.folderId}
                  fileSize={params.row.fileSize}
                  allowRetract={params.row.allowRetract ? params.row.allowRetract : false}
                  bookmarked={params.row.isBookmarked}
                  permissions={rootFilePermissions}
                  currentFolder={currentFolder}
                  setPublishExhibitData={setPublishExhibitData}
                  setShowPreview={setShowPreview}
                  exhibitNumber={params.row.exhibitNumber}
                  //  onExhibitSelected={handleExhibitSelected}
                />
              );
            }
          },
        });

        //column for Owner Name
        if (currentFolder?.isUnderFinalExhibit) {
          exhibitColumnsNoExhibitNumber.push({
            field: "ownerName",
            headerName: "Created By",
            minWidth: 175,
            disableColumnMenu: true,
            disableClickEventBubbling: true,
            sortable: false,
            renderCell: (params) => {
              return <span>{`${params.row.ownerName ? params.row.ownerName : ""}`}</span>;
            },
            valueGetter: (params) => params.value,
          });
        }
        // HUSEBYA105-7535: Huseby wanted this removed for now.   We may want to revisit this and
        // make the columns configurable.
        // exhibitColumnsNoExhibitNumber.push({
        //   field: "dateCreated",
        //   headerName: "Date Created",
        //   minWidth: 150,
        //   flex: 1,
        //   disableColumnMenu: true,
        //   disableClickEventBubbling: true,
        //   valueGetter: (params) => {
        //     var utcDate = moment.utc(params.value).toDate();
        //     return moment(utcDate).local().format("MM/DD/YYYY h:mmA");
        //   },
        // });

        exhibitColumnsNoExhibitNumber.push({
          field: "fileSize",
          headerName: "File Size",
          width: 200,
          type: "number",
          disableColumnMenu: true,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            if (params.row.fileType === 1) {
              return <span>{`${getFileSize(params.row.fileSize)}`}</span>;
            }
          },
          valueGetter: (params) => params.value,
        });

        exhibitColumnsNoExhibitNumber.push({
          field: "lastActivity",
          headerName: "Last Activity",
          headerAlign: "left",
          minWidth: 150,
          align: "left",
          flex: 1,
          disableColumnMenu: true,
          disableClickEventBubbling: true,
          sortable: false,
          renderCell: (params) => {
            if (params.row.fileType === 1) {
              return <span>{`${params.row.lastActivity ? params.row.lastActivity : ""}`}</span>;
            }
          },
          valueGetter: (params) => params.value,
        });

        setExhibitColumns(exhibitColumnsNoExhibitNumber);

        //  refreshExhibits();
        await getAllExhibits(folderId, setForbidden, listExhibits);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [folderId]);

  const handleListExhibits = async (fileId) => {
    try {
      setLoading(true);

      // //  // get current folder
      setData({ clear: "currentFolder" });
      const currentFolder = await getFile(fileId);
      setData({
        currentFolder: currentFolder,
      });

      //  refreshExhibits();
      await getAllExhibits(fileId, setForbidden, listExhibits);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshExhibits();
  }, [searchQuery, pageIndex, pageLength]);

  const handleExhibitSelected = (ev) => {
    // console.log("handleExhibitSelected", ev);
    onExhibitSelected(ev);
  };

  const handleDownloadExhibitsClick = () => {
    setAnchorEl(null);
    setDownloadExhibits({
      open: true,
      selectedFiles: selectedFiles,
      folderId: currentFolder != null ? currentFolder.fileId : null,
    });
  };

  const handleShareExhibitsClick = (e) => {
    setAnchorEl(null);
    setShareExhibits({
      open: true,
      selectedFiles: selectedFiles,
      folderId: currentFolder != null ? currentFolder.fileId : null,
    });
  };

  const handleMoveExhibitsClick = (e) => {
    setAnchorEl(null);
    setMoveExhibits({
      open: true,
      selectedFiles: selectedFiles,
      folderId: currentFolder != null ? currentFolder.fileId : null,
      onClose: () => {
        refreshExhibits();
      },
    });
  };

  const handleExhibitPermissionsClick = (e) => {
    setAnchorEl(null);
    setExhibitPermissions({
      open: true,
      eventId: data.eventId,
      fileId: folderId,
      firmId: firmId,
      currentFolder: currentFolder,
    });
  };

  const handleDeleteExhibitsClick = (e) => {
    setAnchorEl(null);
    setDeleteExhibits({
      open: true,
      selectedFiles: selectedFiles,
      folderId: currentFolder != null ? currentFolder.fileId : null,
    });
  };

  const handleRetractExhibitClick = (e) => {
    setAnchorEl(null);
    setRetractExhibit({
      open: true,
      selectedFiles: selectedFiles,
      folderId: currentFolder != null ? currentFolder.fileId : null,
    });
  };

  const handlePublishExhibitsClick = (e) => {
    setAnchorEl(null);

    if (selectedIds.length === 1) {
      const selectedFile = filteredExhibits.find((file) => file.fileId === selectedIds[0]);
      setPublishExhibitData(selectedFile);
      setShowPreview(true);
    }
  };

  const handleSortModelChange = (newModel) => {
    const sortField = newModel[0]?.field; // The field being sorted
    const sortDirection = newModel[0]?.sort; // "asc" or "desc"

    // If the user has sorted, sort the rows client-side
    if (sortField && sortDirection) {
      const sortedRows = [...filteredExhibits];
      sortedRows.sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        // Perform sorting based on the field type
        if (sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      });

      // Update the filteredExhibits with the sorted rows
      setFilteredExhibits(sortedRows);
    }
    setSortModel(newModel);
  };

  const getBackgroundImageStyle = (size) => {
    if (
      permissions?.grantCreateUpdate &&
      (data.eventParticipant.eventParticipantType.eventParticipantType === "Firm Contact" ||
        data?.breadcrumbs?.length > 1) &&
      size < 5
    ) {
      return classes.dropHere;
    } else {
      return classes.paper;
    }
  };

  //
  //  Move Functions
  //

  const isValidDestFolderId = (destTarget) => {
    const dest = destTarget.id;
    const destType = dest.split(":")[0];
    if (destType !== "folder") {
      // console.log("Invalid destination type", destType);
      return null;
    }

    const destFolderId = dest.split(":")[1];

    if (isNil(destFolderId)) return null;
    for (let i in selectedIds) {
      if (selectedIds[i] === destFolderId) return null;
    }

    // If one of the selected folders is the 'Final Exhibits' folder, then do not allow
    // it to be moved.
    if (isFinalExhibitsSelected) {
      return null;
    }

    const _exhibit = find(data.exhibits, (file) => file.fileId === destFolderId);
    if (!isNil(_exhibit)) return null;

    return destFolderId;
  };

  /**
   * Handle drag start.
   * @param {*} e
   */
  const handleDragStart = (e) => {
    var dt = e.dataTransfer;

    if (isSupportedContactType(data.identity.contactTypeId)) {
      dt.dragEffect = "move";
      dt.effectAllowed = "move";
      return;
    } else if (data?.breadcrumbs?.length < 2 || isFinalExhibitsSelected) {
      dt.dragEffect = "none";
      dt.effectAllowed = "none";
      return;
    }
  };

  /**
   * Handle drag enter.   We need to check that files that are getting dragged
   * into another destination file validation against the following:
   *
   * - Make sure that the destination is a folder.
   * - Make sure that the destination is not one of the selectedIds.
   *
   * @param {*} e
   */
  const handleDragEnter = (e) => {
    const destFolderId = isValidDestFolderId(e.target);
    var dt = e.dataTransfer;
    if (isSupportedContactType(data.identity.contactTypeId)) {
      dt.dragEffect = "move";
      dt.effectAllowed = "move";
      return;
    } else if (data?.breadcrumbs?.length < 2 || isNil(destFolderId)) {
      dt.dragEffect = "none";
      dt.effectAllowed = "none";
      return;
    }
  };

  const handleDragEnd = (e) => {
    // console.log("handleDragEnd", e);
  };
  const isSupportedContactType = (contactTypeId) => {
    if (supportedContactTypes.includes(contactTypeId)) return true;
    return false;
  };

  const handleCheckUploadPermissions = (e) => {
    var dt = e.dataTransfer;

    if (permissions.grantCreateUpdate && isSupportedContactType(data.identity.contactTypeId)) {
      dt.dragEffect = "move";
      dt.effectAllowed = "move";
      return;
    } else if (
      data?.breadcrumbs?.length < 2 ||
      isNil(permissions?.grantCreateUpdate) ||
      permissions?.grantCreateUpdate === false
    ) {
      dt.dragEffect = "none";
      dt.dropEffect = "none";
      dt.effectAllowed = "none";
      return;
    }
  };
  const handleDragOver = (e) => {
    const destFolderId = isValidDestFolderId(e.target);

    if (data?.breadcrumbs?.length < 2 || isNil(destFolderId)) {
      var dt = e.dataTransfer;
      dt.dragEffect = "none";
      dt.dropEffect = "none";
      dt.effectAllowed = "none";
      return;
    }
  };

  /**
   * Handle drop.   We need to check that files that are getting dragged
   * into another destination file validation against the following:
   *
   * - Make sure that the destination is a folder.
   * - Make sure that the destination is not one of the selectedIds.
   *
   * @param {*} e
   */
  const handleDrop = async (e) => {
    const destFolderId = isValidDestFolderId(e.target);
    if (data?.breadcrumbs?.length < 2 || isNil(destFolderId)) {
      var dt = e.dataTransfer;
      dt.dragEffect = "none";
      dt.effectAllowed = "none";
      return;
    }

    if (selectedIds.length === 0) {
      setSnackbar({
        open: true,
        message: `Please check at least one file to move.`,
        severity: "error",
      });
      return;
    }

    try {
      var dt = e.dataTransfer;
      dt.dropEffect = "move";

      setLoading(true);
      await moveFiles(destFolderId, selectedIds);
      // Display a message that the files were moved.
      setSnackbar({
        open: true,
        message: `The files have been moved to the selected destination folder.`,
        severity: "success",
      });
      refreshExhibits();
    } catch (err) {
      // TODO The API returns a message that is not descriptive of the error.
      // Code 500 with message: "could not execute statement; SQL [n/a]; constraint [null]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement"
      // Display a message that moving the files failed.
      setSnackbar({
        open: true,
        message:
          "Server Error, files with the same name may already exist in the destination folder.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateExhibitNumber = async (oldExhibitNumber, newExhibitNumber, fileId) => {
    return new Promise(async (resolve, reject) => {
      if (oldExhibitNumber !== newExhibitNumber) {
        try {
          const response = await updateExhibitNumber({
            fileId: fileId,
            exhibitNumber: newExhibitNumber,
            eventId: eventMeta.eventId,
          });
          if (response) {
            setSnackbar({
              open: true,
              severity: "success",
              message: "Exhibit number has been updated.",
            });
          }
          resolve("updated");
        } catch (e) {
          if (e?.response?.data?.message) {
            setSnackbar({
              open: true,
              severity: "error",
              message: e.response.data.message,
            });
          } else {
            setSnackbar({
              open: true,
              severity: "error",
              message: "Something went wrong. Please try again.",
            });
          }
          reject("failure");
        }
      }
    });
  };

  return (
    <>
      <Box className={dgClasses.container}>
        {forbidden && (
          <Box
            style={{
              color: "#000000",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/images/error_403.jpeg" height="25%" width="25%" />
            <br />
            <br />
            <h2 style={{ width: "500px", textAlign: "center" }}>
              Unfortunately, you do not have permissions to view this exhibits.
              <br />
              <br />
              Please contact Huseby Support for assistance.
            </h2>
          </Box>
        )}
        {/* ButtonBar */}
        <Box
          className={dgClasses.buttonBar}
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          onDragOver={handleDragOver}
        >
          {!forbidden && (
            <Box width={1} component="span" display="inline">
              <NewFileButton
                currentFolder={currentFolder}
                sharedFolders={sharedFolders}
                permissions={permissions}
                onExhibitListUpdated={refreshExhibits}
                eventMeta={eventMeta}
              />
              <Box component="span" display="inline" p={0.5}></Box>
              {permissions?.grantDownload && (
                <>
                  <Tooltip title="Download Selected Files">
                    <span>
                      <IconButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={isEmpty(selectedIds)}
                        onClick={handleDownloadExhibitsClick}
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Box component="span" display="inline" p={0.5}></Box>
                </>
              )}
              {permissions?.grantShare && (
                <>
                  <Tooltip title="Share Selected Files">
                    <span>
                      <IconButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={isEmpty(selectedIds)}
                        onClick={handleShareExhibitsClick}
                      >
                        <ShareIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Box component="span" display="inline" p={0.5}></Box>
                </>
              )}
              {permissions?.grantCreateUpdate && (
                <>
                  <Tooltip title="Move Selected Files">
                    <span>
                      <IconButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={isEmpty(selectedIds) || isFinalExhibitsSelected}
                        onClick={handleMoveExhibitsClick}
                      >
                        <FolderIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Box component="span" display="inline" p={0.5}></Box>
                </>
              )}
              {permissions?.grantManagePermissions && (
                <>
                  <Tooltip title="Folder Permissions">
                    <span>
                      <IconButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={!isRootFolder}
                        onClick={handleExhibitPermissionsClick}
                      >
                        <PeopleAltIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Box component="span" display="inline" p={0.5}></Box>
                </>
              )}
              {permissions?.grantDelete && (
                <>
                  <Tooltip title="Delete Selected Files">
                    <span>
                      <IconButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        disabled={isEmpty(selectedIds)}
                        onClick={handleDeleteExhibitsClick}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Box component="span" display="inline" p={0.5}></Box>
                </>
              )}{" "}
            </Box>
          )}

          {!forbidden && (
            <>
              <Default>
                <Box width={450} component="span" display="inline">
                  <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
                </Box>
              </Default>
            </>
          )}

          {!forbidden && onExhibitManagerClosed && (
            <>
              <Box component="span" display="inline" p={0.5}></Box>
              <Tooltip title="Close">
                <IconButton
                  variant="contained"
                  size="small"
                  className={classes.button}
                  onClick={onExhibitManagerClosed}
                >
                  <ClearIcon color="primary" variant="contained" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
        {/* ButtonBar */}

        {/* Mobile Search Bar */}
        {!forbidden && (
          <Mobile>
            <Box component="span" display="inline" style={{ margin: 5, width: "calc(100vw-10)" }}>
              <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
            </Box>
          </Mobile>
        )}

        {/* Breadcrumb Trail, List & Grid View, Refresh */}
        {!forbidden && (
          <Box className={dgClasses.buttonBar} onDragOver={handleDragOver}>
            <BreadcrumbTrail onBreadcrumbClick={handleListExhibits} />
            <Box>
              <Box component="span" display="inline" p={1}></Box>
              <Tooltip title="Refresh Exhibits List" placement="right-end">
                <IconButton aria-label="" size="small" onClick={() => refreshExhibits()}>
                  <img src="/images/icon_redo.png" alt="" height="20" width="20" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
        {/* //Breadcrumb Trail, List & Grid View, Refresh */}

        {/* ExhibitListContainer */}
        {!forbidden && (
          <Box className={classes.exhibitListContainer}>
            <section className={classes.dropzoneContainer}>
              {data?.breadcrumbs?.length > 1 ||
              data.eventParticipant?.eventParticipantType?.eventParticipantType ===
                "Firm Contact" ? (
                <div
                  {...getRootProps({
                    className: "dropzone",
                    onClick: (ev) => ev.stopPropagation(),
                  })}
                  className={classes.dropzone}
                  onDragOver={handleCheckUploadPermissions}
                >
                  {(data?.breadcrumbs?.length > 1 ||
                    data.eventParticipant.eventParticipantType.eventParticipantType ===
                      "Firm Contact") && <input {...getInputProps()} />}
                  <DataGridPro
                    className={getBackgroundImageStyle(filteredExhibits.length)}
                    rows={filteredExhibits}
                    columns={exhibitColumns}
                    // components={loading===false && {NoRowsOverlay: CustomNoRowsOverlay}}
                    componentsProps={{
                      row: {
                        onDragStart: handleDragStart,
                        onDragEnter: handleDragEnter,
                        onDragEnd: handleDragEnd,
                        onDragOver: handleDragOver,
                        onDrop: handleDrop,
                      },
                    }}
                    isRowSelectable={(params) => params.row.name != "Final Exhibits"}
                    pagination={true}
                    onCellEditStop={async (data, event) => {
                      const value = event.target.value;
                      if (value !== data.formattedValue) {
                        try {
                          const response = await updateExhibitNumber({
                            fileId: data.row.fileId,
                            exhibitNumber: value,
                            eventId: eventMeta.eventId,
                          });
                          if (response) {
                            setSnackbar({
                              open: true,
                              severity: "success",
                              message: "Exhibit number updated",
                            });
                          }
                        } catch {
                          setSnackbar({
                            open: true,
                            severity: "error",
                            message:
                              "An exhibit with this number already exists. Please choose a different number.",
                          });
                        }
                      }
                    }}
                    rowHeight={48}
                    paginationMode="server"
                    pageSize={pageLength}
                    rowsPerPageOptions={[LIST_PAGE_LENGTH]}
                    rowCount={pagination}
                    onPageChange={(newPage) => setPageIndex(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setPageLength(newPageSize.pageSize);
                    }}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={(value) => {
                      setSelectedIds(value);
                    }}
                    getRowId={(row) => row.fileId}
                    loading={loading}
                    sortingMode="client"
                    onSortModelChange={handleSortModelChange}
                    sx={{
                      boxShadow: 0,
                      border: 1,
                      borderColor: "#e4e4e4",
                      "& .MuiDataGrid-cell": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </div>
              ) : (
                <div className={classes.dropzone} onDragOver={handleCheckUploadPermissions}>
                  <input {...getInputProps()} />
                  <DataGridPro
                    className={getBackgroundImageStyle(filteredExhibits.length)}
                    rows={filteredExhibits}
                    columns={exhibitColumns}
                    pagination={true}
                    onCellEditStop={async (data, event) => {
                      const value = event.target.value;
                      if (value !== data.formattedValue) {
                        console.log("api call");
                        try {
                          const response = await updateExhibitNumber({
                            fileId: data.row.fileId,
                            exhibitNumber: value,
                            eventId: eventMeta.eventId,
                          });
                          if (response) {
                            setSnackbar({
                              open: true,
                              severity: "success",
                              message: "Exhibit number updated",
                            });
                          }
                        } catch {
                          setSnackbar({
                            open: true,
                            severity: "error",
                            message:
                              "An exhibit with this number already exists. Please choose a different number.",
                          });
                        }
                      }
                    }}
                    componentsProps={{
                      row: {
                        onDragStart: handleDragStart,
                        onDragEnter: handleDragEnter,
                        onDragEnd: handleDragEnd,
                        onDragOver: handleDragOver,
                        onDrop: handleDrop,
                      },
                    }}
                    isRowSelectable={(params) => params.row.name != "Final Exhibits"}
                    rowHeight={48}
                    paginationMode="server"
                    pageSize={pageLength}
                    rowsPerPageOptions={[LIST_PAGE_LENGTH]}
                    rowCount={pagination}
                    onPageChange={(newPage) => setPageIndex(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setPageLength(newPageSize.pageSize);
                    }}
                    checkboxSelection
                    disableSelectionOnClick
                    // isRowSelectable={params =>  (params.row.readOnly === false)}
                    onSelectionModelChange={(value) => {
                      setSelectedIds(value);
                    }}
                    getRowId={(row) => row.fileId}
                    loading={loading}
                    sortingMode="client"
                    onSortModelChange={handleSortModelChange}
                    sx={{
                      boxShadow: 0,
                      border: 1,
                      borderColor: "#e4e4e4",
                      "& .MuiDataGrid-cell": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderColor: "#e4e4e4",
                      },
                      "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </div>
              )}
            </section>
          </Box>
        )}

        {showPreview && (
          <PublishExhibitDialog
            fileId={publishExhibitData.fileId}
            open={showPreview}
            onClosePreview={() => setShowPreview(false)}
            eventId={data.eventId}
            publishExhibitData={publishExhibitData}
            setSnackbar={setSnackbar}
            existingExhibitNumber={publishExhibitData.existingExhibitNumber}
          />
        )}
        {/* // ExhibitListContainer */}

        {/* Snackbar Confirmation */}
        {snackbar && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar(null)}
          >
            <Alert onClose={() => setSnackbar(null)} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
};

const CustomNoRowsOverlay = (loading) => {
  return (
    <GridOverlay>
      <div></div>
    </GridOverlay>
  );
};

const NewFileButton = ({
  currentFolder,
  sharedFolders = [],
  permissions,
  onExhibitListUpdated,
  eventMeta,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const inputFileRef = useRef(null);
  const { setExhibit, setEditFolder, setExhibitForm, setUploadFolder } = useDialog();
  const [anchorEl, setAnchorEl] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  const handleNewExhibitClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNewFolderClick = (e) => {
    setAnchorEl(null);
    // setOpenNewFolder(true);
    setEditFolder({
      open: true,
      folder: {
        fileId: null,
        folderId: currentFolder?.fileId || null,
        exhibitName: "",
        description: "",
      },
    });
  };

  const handleNewBlankExhibitClick = (e) => {
    setAnchorEl(null);
  };

  const handleUploadFolderClick = (e) => {
    setAnchorEl(null);
    setUploadFolder({
      open: true,
      folderId: currentFolder?.fileId || null,
      onClose: () => {
        onExhibitListUpdated && onExhibitListUpdated();
      },
    });
  };

  /**
   * Event handler for the 'Upload Exhibit' button.
   *
   * @param {*} e
   */
  const handleUploadExhibitButtonClick = (e) => {
    setAnchorEl(null);
    inputFileRef.current.click();
  };

  /**
   * Event handler for the file <input> element.  This html element is
   * responsible for uploading the file.
   *
   * @param {*} e
   */
  const handleUploadExhibitClick = (e) => {
    const file = e.target.files[0];

    setFileToUpload(file);

    // Display exhibit settings dialog window
    setExhibitForm({
      open: true,
      folderId: currentFolder?.fileId || null,
      fileToUpload: file,
    });
    inputFileRef.current.value = "";
  };

  const handleSharedFolderClick = (folderId) => {
    setAnchorEl(null);
    history.push(`?folderId=${folderId}`);
  };

  const numberToEventExhibitName = new Map([
    [0, "No Exhibits"],
    [1, "Advanced"],
    [2, "Simple"],
  ]);

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        startIcon={<HomeIcon />}
        onClick={handleNewExhibitClick}
      >
        File
      </Button>
      <Menu
        id="new-exhibit-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {permissions?.grantCreateUpdate && (
          <MenuItem onClick={handleNewFolderClick} divider={true}>
            <CreateNewFolderIcon color="primary" fontSize="medium" />
            <span style={{ marginLeft: 10 }}>New Folder</span>
          </MenuItem>
        )}

        {permissions?.grantCreateUpdate && (
          <MenuItem
            onClick={handleUploadFolderClick}
            disabled={currentFolder?.hcResourceType === "event"}
          >
            <CloudUploadIcon color="primary" fontSize="medium" />
            <span style={{ marginLeft: 10 }}>Upload Folder</span>
          </MenuItem>
        )}

        {permissions?.grantCreateUpdate && (
          <MenuItem
            onClick={handleUploadExhibitButtonClick}
            divider={true}
            disabled={currentFolder?.hcResourceType === "event"}
          >
            <CloudUploadIcon color="primary" fontSize="medium" />
            <span style={{ marginLeft: 10 }}>Upload Exhibit</span>
            <input
              accept="*"
              type="file"
              id="uploadExhibit"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleUploadExhibitClick}
            />
          </MenuItem>
        )}

        {sharedFolders && <MenuItem key="my-folders">My Folders</MenuItem>}
        {sharedFolders &&
          sharedFolders.map((folder) => (
            <MenuItem key={folder.fileId} onClick={() => handleSharedFolderClick(folder.fileId)}>
              {folder.icon === "home" ? (
                <HomeIcon color="primary" fontSize="medium" />
              ) : (
                <FolderSharedIcon color="primary" fontSize="medium" />
              )}
              <span style={{ marginLeft: 10 }}>{folder.name}</span>
            </MenuItem>
          ))}
      </Menu>
      {snackbar && (
        <PortalSnackbar
          isOpen={snackbar.open}
          onClose={() => setSnackbar(null)}
          message={snackbar.message}
          severity={snackbar.severity}
        />
      )}
    </>
  );
};

const PortalSnackbar = ({ message, isOpen, onClose, severity }) => {
  if (!isOpen) return null;
  return createPortal(
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>,
    document.body
  );
};

export default Exhibits;
async function getAllExhibits(
  fileId,
  setForbidden,
  listExhibits,
  _exhibits = null,
  searchQuery = "",
  pageIndex = 0,
  pageLength = LIST_PAGE_LENGTH,
  sortParam = "name:asc"
) {
  try {
    _exhibits = await listExhibits(fileId, searchQuery, pageIndex, pageLength, sortParam);
  } catch (error) {
    console.log("Error detected while trying to list exhibits!", error);
    if (error?.response?.status === 403) {
      setForbidden(true);
    }
  }
  console.trace("Exhibits::getAllExhibits _exhibits", _exhibits);
  return _exhibits;
}
