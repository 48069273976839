import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { forEach } from "lodash";
import CRXDialogTitle from "../common/dialog/CRXDialogTitle";
import { useRecordingsService } from "../../services/RecordingsService";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const DeleteRecordingsDialog = ({
  open,
  handleClose,
  onSuccess,
  eventId,
  selectedRecordings = [],
}) => {
  const classes = useStyles();
  const [selectedRecordingsUI, setSelectedRecordingsUI] = useState([]);
  const { listRecordings, deleteRecordings } = useRecordingsService();

  useEffect(() => {
    let tmp = [];

    forEach(selectedRecordings, (recording) => {
      tmp.push(
        <div style={{ width: "100%" }}>
          <VideoLibraryIcon className={classes.icon} color="primary" />
          {recording.name}
        </div>
      );
    });

    setSelectedRecordingsUI(tmp);
  }, [selectedRecordings]);

  const handleConfirmDeletion = async (e) => {
    e.preventDefault();
    try {
      let ids = [];
      forEach(selectedRecordings, (recording) => {
        ids.push(recording.recordingId);
      });
      console.log("ids", ids);
      await deleteRecordings(ids);

      await listRecordings(eventId);

      handleClose();

      onSuccess({
        severity: "success",
        message: `The selected recordings have been successfully deleted.`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} handleClose={handleClose}>
        <CRXDialogTitle onClick={handleClose} title="Delete Confirmation" />
        <form
          onSubmit={handleConfirmDeletion}
          style={{
            width: "100%",
          }}
        >
          <DialogContent>
            <div>Are you sure you want to delete the following:</div>
            <Box
              style={{
                padding: "20px",
                height: "300px",
                width: "calc(100% - 20px)",
                overflowY: "auto",
              }}
            >
              {selectedRecordingsUI}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Confirm Deletion
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { DeleteRecordingsDialog };
