import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";

export const useDataGridStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "#ffffff",
  },
  container: {
    // backgroundColor: "#ffffff",
    // height: "calc(100vh - 250px)",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "4px",
  },
  datagridContainer: {
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 450px)", // 8px from padding
  },
  buttonBar: {
    // backgroundColor: "#ff0000",
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    "&.Mui-disabled": {
      backgroundColor: "#ddadb2",
      color: "#ffffff",
    },
  },
  MuiDataGrid: {
    root: {
      boxShadow: 0,
      border: 2,
      borderColor: "#ff0000",
      "& .MuiDataGrid-cell": {
        borderColor: "#e4e4e4",
      },
      "& .MuiDataGrid-cell:hover": {
        color: "primary.main",
      },
      "& .MuiDataGrid-columnHeaders": {
        borderColor: "#e4e4e4",
      },
      "& .MuiDataGrid-footerContainer": {
        borderColor: "#e4e4e4",
      },
    },
  },
}));
