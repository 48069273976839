import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    width: "100%",
    margin: 2,
    marginRight: 10,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const DownloadExhibitDialog = ({ open, handleClose, file }) => {
  const classes = useStyles();
  const { downloadFile } = useContext(ExhibitsContext);
  const [status, setStatus] = useState("not-started");
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    setStatus("in-progress");

    if (isNil(file)) return;

    // Download files
    const downloadNow = async () => {
      try {
        await downloadFile(file, onDownloadProgress);
        setStatus("complete");
      } catch (err) {
        console.log(err);
        setStatus("error");
      }
    };
    downloadNow();
  }, [open]);

  const onDownloadProgress = (ev) => {
    const total = file.fileSize;
    const _downloadProgress = Math.round((100 * ev.loaded) / total);
    setDownloadProgress(_downloadProgress);
  };

  return (
    <>
      <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
        {/* <CRXDialogTitle onClick={handleClose} title="Download Confirmation" /> */}
        <form>
          <DialogContent>
            {status === "in-progress" && <div>Your exhibit is being downloaded.</div>}
            {status === "complete" && (
              <div>Your exhibit has been downloaded. Please check your Downloads folder.</div>
            )}
            <Box width={1} my={2} display="flex" flexDirection="row" justifyContent="space-between">
              {/* <BorderLinearProgress
                variant={status === "complete" ? "determinate" : "indeterminate"}
              /> */}
              <BorderLinearProgress variant="determinate" value={downloadProgress} />
              {status === "complete" && <CheckIcon style={{ color: "green" }} />}
              {status === "in-progress" && <CircularProgress size={20} />}
              {status === "error" && <ErrorIcon style={{ color: "red" }} />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { DownloadExhibitDialog };
