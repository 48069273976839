/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useContext } from "react";
import { Button, Box, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { forEach, isEmpty, isNil, merge } from "lodash";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShareIcon from "@mui/icons-material/Share";
import { useRecordingsService } from "../../../services/RecordingsService";
import RecordingMenuButton from "./RecordingMenuButton";
import SearchBar from "./SearchBar";
import { useDialog } from "../../common/providers/DialogProvider";

const LIST_PAGE_LENGTH = 15;

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "4px",
  },
  buttonBar: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recordingListBar: {
    height: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recordingListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "calc(100vh - 85px)", // 8px from padding
  },
  dropzoneContainer: {
    width: "100%",
  },
  dropzone: {
    backgroundColor: "#e4e4e4",
    padding: "0px",
    borderColor: "#999999",
    borderWidth: "0px",
    // borderStyle: "dashed",
    borderStyle: "none",
    textAlign: "center",
    height: "100%",
    width: "100%",
  },
  button: {
    fontSize: "10pt",
  },
  link: {
    display: "flex",
    fontSize: 12,
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
}));

const getFileSize = (fileSize) => {
  const BYTES_TO_MB = 1000000; //1048576;
  const BYTES_TO_KB = 1000;

  if (fileSize < 1000000) return Number.parseFloat(fileSize / BYTES_TO_KB).toFixed(2) + "KB";
  else return Number.parseFloat(fileSize / BYTES_TO_MB).toFixed(2) + "MB";
};

const Recordings = ({ onRecordingSelected, eventId }) => {
  const classes = useStyles();
  const { data, setData, listRecordings, getRecording } = useRecordingsService();
  // console.log('data after call is',data);
  const {
    setDownloadRecordings,
    setDeleteRecordings,
    setShareRecordings,
    setRecordingPermissions,
  } = useDialog();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLength, setPageLength] = useState(LIST_PAGE_LENGTH);
  const [pagination, setPagination] = useState(0);
  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const [sortParam, setSortParam] = useState("name:asc");
  const [readOnly, setReadOnly] = useState(false);
  const [recordingsMap, setRecordingsMap] = useState(new Map());
  const [filteredRecordings, setFilteredRecordings] = useState([]);
  const [loading, setLoading] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [forbidden, setForbidden] = useState(false);

  const recordingColumns = [
    {
      field: "name",
      headerName: "Recording",
      width: 580,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <RecordingMenuButton
            recordingId={params.row.fileId}
            recordingName={params.row.name}
            description={params.row.description}
            fileSize={params.row.fileSize}
            eventId={params.row.hcResourceId}
          />
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      valueGetter: (params) => moment(params.value).format("MM/DD/YYYY HH:mmA"),
    },
    // {
    //   field: "duration",
    //   headerName: "Duration",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   disableClickEventBubbling: true,
    //   valueGetter: (params) => moment(params.value).format("MM/DD/YYYY HH:mmA"),
    // },
    {
      field: "fileSize",
      headerName: "File Size",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if (params.row.fileType === 2) {
          return <span>{`${getFileSize(params.row.fileSize)}`}</span>;
        }
      },
      valueGetter: (params) => params.value,
      sortComparator: (v1, v2, param1, param2) => {
        const sortDirection = param1.api.state.sorting.sortModel[0].sort;
        if (sortDirection === "asc") {
          return v1 > v2 ? 1 : -1;
        } else {
          return v1 < v2 ? -1 : 1;
        }
      },
    },
  ];

  useEffect(() => {
    let recordingsList = data.recordings;

    const recordings = !isNil(recordingsList)
      ? recordingsList.map((ex) =>
          merge(ex, {
            recordingId: `${ex.fileId}`,
            recordingName: ex.name,
            // witnessName: ex.witnessVO.fullName,
          })
        )
      : [];

    // // Map the selectedIds to selectedRecordings.  This wasn't necessary
    // // before with DataGrid, but for some reason we have to use
    // // onSelectionModelChange, which only returns a list of ids.
    // // useEffect(() => {
    let _recordingsMap = new Map();
    // console.log('data.recordings1 is',data.recordings);
    // console.log('data.currentFolder1 is',data);
    forEach(data.recordings, (recording) => {
      _recordingsMap.set(recording.fileId.toString(), recording);
    });
    // console.log({ recordingsMap, selectedIds });
    setRecordingsMap(_recordingsMap);

    setFilteredRecordings(recordings);
  }, [data.recordings]);

  useEffect(() => {
    if (data && data.recordingsListParams) {
      setPagination(data.recordingsListParams.pagination.totalElements);
    }
  }, [data.recordingsListParams]);

  useEffect(() => {
    const _selectedRecordings = selectedIds.map((id) => recordingsMap.get(id));
    setSelectedRecordings(_selectedRecordings);
    // console.log(_selectedRecordings);
  }, [selectedIds]);

  async function refreshRecordings() {
    let _recordings;
    const fetchData = async () => {
      setLoading(true);
      if (sortModel?.sortModel?.length > 0) {
        const _sortParam = `${sortModel.sortModel[0].field}:${sortModel.sortModel[0].sort}`;
        setSortParam(_sortParam);
      }
      _recordings = await getAllRecordings(
        data.eventId,
        setForbidden,
        _recordings,
        listRecordings,
        searchQuery,
        pageIndex,
        pageLength,
        sortParam,
        setForbidden
      );
      // setPagination(_recordings.totalElements);
      // setFilteredRecordings(_recordings);
      setLoading(false);
    };
    fetchData();
    setSelectedIds([]);
  }

  useEffect(() => {
    refreshRecordings();
  }, [searchQuery, pageIndex, pageLength, sortModel]);

  const handleListRecordings = async (recordingId) => {
    try {
      setLoading(true);

      // //  // get current folder
      setData({ clear: "currentFolder" });
      const currentFolder = await getRecording(recordingId);
      setData({
        currentFolder: currentFolder,
      });

      //  refreshrecordings();
      await getAllRecordings(eventId, setForbidden);
    } finally {
      setLoading(false);
    }
  };

  const handleRecordingSelected = (ev) => {
    // console.log("handleRecordingSelected", ev);
    onRecordingSelected(ev);
  };

  const handleDownloadRecordingsClick = () => {
    setDownloadRecordings({
      open: true,
      selectedRecordings: selectedRecordings,
      folderId: currentFolder != null ? currentFolder.fileId : null,
    });
  };

  const handleShareRecordingsClick = (e) => {
    setShareRecordings({
      open: true,
      selectedRecordings: selectedRecordings,
    });
  };

  const handleRecordingPermissionsClick = (e) => {
    setRecordingPermissions({
      open: true,
      eventId: data.eventId,
    });
  };

  const handleDeleteRecordingsClick = (e) => {
    setDeleteRecordings({
      open: true,
      selectedRecordings: selectedRecordings,
      eventId: data.eventId != null ? data.eventId : null,
    });
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  return (
    <>
      {forbidden && (
        <Box
          style={{
            color: "#000000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/error_403.jpeg" height="25%" width="25%" />
          <br />
          <br />
          <h2 style={{ width: "500px", textAlign: "center" }}>
            Unfortunately, you do not have permissions to view this recordings.
            <br />
            <br />
            Please contact Huseby Support for assistance.
          </h2>
        </Box>
      )}
      <Box className={classes.container}>
        {/* ButtonBar */}
        {!forbidden && (
          <Box className={classes.buttonBar}>
            <Box width={1} component="span" display="inline">
              <Box component="span" display="inline" p={0.5}></Box>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                startIcon={<DownloadIcon />}
                disabled={isEmpty(selectedIds)}
                onClick={handleDownloadRecordingsClick}
              >
                Download
              </Button>
              <Box component="span" display="inline" p={0.5}></Box>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                startIcon={<ShareIcon />}
                disabled={isEmpty(selectedIds)}
                onClick={handleShareRecordingsClick}
              >
                Share
              </Button>
              <Box component="span" display="inline" p={0.5}></Box>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                startIcon={<PeopleAltIcon />}
                onClick={handleRecordingPermissionsClick}
              >
                Permissions
              </Button>
              <Box component="span" display="inline" p={0.5}></Box>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                startIcon={<DeleteIcon />}
                disabled={readOnly || isEmpty(selectedIds)}
                onClick={handleDeleteRecordingsClick}
              >
                Delete
              </Button>
              <Box component="span" display="inline" p={0.5}></Box>
            </Box>

            <Box width={450} component="span" display="inline">
              <SearchBar onQueryChange={(searchQuery) => setSearchQuery(searchQuery)} />
            </Box>
          </Box>
        )}
        {/* ButtonBar */}
        {!forbidden && (
          <Box>
            <Box component="span" display="inline" p={1}></Box>
            <Tooltip title="Refresh Recording List" placement="right-end">
              <IconButton
                aria-label=""
                size="small"
                onClick={refreshRecordings}
                style={{ float: "right" }}
              >
                <img src="/images/icon_redo.png" alt="" height="20" width="20" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {/* RecordingListContainer */}
        {!forbidden && (
          <Box className={classes.recordingListContainer}>
            <DataGridPro
              className={classes.paper}
              rows={filteredRecordings}
              columns={recordingColumns}
              rowHeight={48}
              paginationMode="server"
              pageSize={pageLength}
              rowCount={pagination}
              onPageChange={(newPage) => setPageIndex(newPage.page)}
              onPageSizeChange={(newPageSize) => {
                setPageLength(newPageSize.pageSize);
              }}
              checkboxSelection
              disableSelectionOnClick
              // isRowSelectable={params =>  (params.row.readOnly === false)}
              onSelectionModelChange={(value) => {
                setSelectedIds(value);
              }}
              getRowId={(row) => row.recordingId}
              loading={loading}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
            />
          </Box>
        )}
        {/* // RecordingListContainer */}
      </Box>
    </>
  );
};

export default Recordings;
async function getAllRecordings(
  eventId,
  setForbidden,
  _recordings = null,
  listRecordings,
  searchQuery = null,
  pageIndex = null,
  pageLength = null,
  sortParam = null
) {
  try {
    _recordings = await listRecordings(eventId, searchQuery, pageIndex, pageLength, sortParam);
  } catch (error) {
    console.log("Error detected while trying to list recordings!", error);
    if (error?.response?.status === 403) {
      setForbidden(true);
    }
  }
  return _recordings;
}
