import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { makeStyles } from "@mui/styles";
import CRXDialogTitle from "../../common/dialog/CRXDialogTitle";
import { forEach, isEmpty } from "lodash";
import { ExhibitsContext } from "../../../services/ExhibitsService";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const BookmarkExhibitsDialog = ({ open, handleClose, onSuccess, selectedFiles }) => {
  const classes = useStyles();
  const [selectedFilesUI, setSelectedFilesUI] = useState([]);
  const { addBookmark } = React.useContext(ExhibitsContext);

  useEffect(() => {
    let tmp = [];
    forEach(selectedFiles, (file) => {
      tmp.push(
        <div style={{ width: "100%" }}>
          {file.fileType === "0" && <FolderIcon className={classes.icon} color="primary" />}
          {file.fileType === "1" && <PictureAsPdfIcon className={classes.icon} color="primary" />}
          {file.name}
        </div>
      );
    });

    setSelectedFilesUI(tmp);
  }, [open]);

  const handleConfirmBookmark = async (e) => {
    console.log("handleConfirmBookmark");
    e.preventDefault();
    try {
      let ids = [];
      forEach(selectedFiles, (file) => {
        ids.push(file.fileId);
      });
      console.log("ids", ids);
      await addBookmark(ids);
      handleClose();

      onSuccess({
        severity: "success",
        message: `The selected files have been bookmarked.`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} handleClose={handleClose}>
        <CRXDialogTitle onClick={handleClose} title="Bookmark Exhibits" />
        <form
          onSubmit={handleConfirmBookmark}
          style={{
            width: "100%",
          }}
        >
          <DialogContent>
            <Box style={{ margin: "10px" }}>
              <div>
                The following Exhibits will be bookmarked. Bookmarked Exhibits will appear under the
                "Bookmarks" menu in your HusebyConnect meetings.
              </div>
              <Box style={{ padding: "20px", width: "100%" }}>{selectedFilesUI}</Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Bookmark
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { BookmarkExhibitsDialog };
