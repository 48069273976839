import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import { isNil } from "lodash";
import { useRecordingsService } from "../../services/RecordingsService";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    width: "100%",
    margin: 2,
    marginRight: 10,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const DownloadRecordingDialog = ({ open, handleClose, recording }) => {
  const classes = useStyles();
  console.log(
    "Inside downloadRecordingDialog before useRecording set state",
    recording,
    open,
    handleClose
  );
  const [status, setStatus] = useState("not-started");
  console.log(
    "Inside downloadRecordingDialog before useRecording service",
    recording,
    open,
    handleClose
  );
  const { data, listRecordings, downloadRecording } = useRecordingsService();
  console.log("Inside downloadRecordingDialog before useState 0", recording, open, handleClose);
  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    console.log("Inside downloadRecordingDialog in useEffect");
    setStatus("in-progress");
    console.log("Inside use Effect file is", recording);
    if (isNil(recording)) return;

    // Download files
    const downloadNow = async () => {
      try {
        console.log("Inside downloadNow file is", recording);
        await downloadRecording(recording, onDownloadProgress);
        setStatus("complete");
      } catch (err) {
        console.log(err);
        setStatus("error");
      }
    };
    downloadNow();
  }, [open]);

  const onDownloadProgress = (ev) => {
    console.log("Inside onDownloadProgress", ev);
    console.log("Inside onDownloadProgress recording is", recording);
    const total = recording.fileSize;
    const _downloadProgress = Math.round((100 * ev.loaded) / total);
    setDownloadProgress(_downloadProgress);
  };

  console.log("Inside downloadRecordingDialog after", recording, open, handleClose);
  return (
    <>
      <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
        {/* <CRXDialogTitle onClick={handleClose} title="Download Confirmation" /> */}
        <form
          style={{
            width: "600px",
          }}
        >
          <DialogContent>
            {status === "in-progress" && <div>Your recording is being downloaded.</div>}
            {status === "complete" && (
              <div>Your recording has been downloaded. Please check your Downloads folder.</div>
            )}
            <Box width={1} my={2} display="flex" flexDirection="row" justifyContent="space-between">
              {/* <BorderLinearProgress
                variant={status === "complete" ? "determinate" : "indeterminate"}
              /> */}
              <BorderLinearProgress variant="determinate" value={downloadProgress} />
              {status === "complete" && <CheckIcon style={{ color: "green" }} />}
              {status === "in-progress" && <CircularProgress size={20} />}
              {status === "error" && <ErrorIcon style={{ color: "red" }} />}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export { DownloadRecordingDialog };
