/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ExhibitsContext } from "../../../services/ExhibitsService";
import { useDialog } from "../../common/providers/DialogProvider";

const useStyles = makeStyles(() => ({
  nameColumn: {
    width: "100%",
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const FolderMenuButton = ({
  fileId,
  exhibitName,
  description,
  permissions,
  fileName,
  fileType,
  folderId,
  onEditFolderSuccess,
  isDeleteEnabled,
  isUnderFinalExhibit,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { setEditFolder, setSelectedFiles, setDownloadExhibits, setDeleteExhibits } = useDialog();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditFolder = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setEditFolder({
      open: true,
      folder: {
        fileId: fileId,
        folderId: folderId,
        name: exhibitName,
        description: description,
        // permissions: permissions,
      },
      onSuccess: onEditFolderSuccess,
      onClose: handleMenuClose,
    });
  };

  const handleDownloadFolder = () => {
    console.log("handleDownloadFolder");
    setAnchorEl(null);
    setSelectedFiles([
      {
        fileId: fileId,
        name: exhibitName,
        description: description,
        fileType: fileType,
      },
    ]);
    setDownloadExhibits({
      open: true,
      selectedFiles: [
        {
          fileId: fileId,
          name: exhibitName,
          description: description,
          fileType: fileType,
        },
      ],
    });
  };

  const handleDelete = () => {
    console.log("handleDelete");
    setAnchorEl(null);
    // setSelectedFiles([
    //   {
    //     fileId: fileId,
    //     name: exhibitName,
    //     description: description,
    //     fileType: fileType,
    //   },
    // ]);
    setDeleteExhibits({
      open: true,
      selectedFiles: [
        {
          fileId: fileId,
          name: exhibitName,
          description: description,
          fileType: fileType,
          folderId: folderId,
        },
      ],
      folderId: folderId,
    });
  };

  const handleListExhibits = async (e) => {
    e.preventDefault();

    history.push(`?folderId=${fileId}`);
  };

  const hasAnyPermissions = () => {
    return permissions?.grantCreateUpdate || permissions?.grantShare || permissions?.grantDelete;
  };

  return (
    <>
      <Box id={`folder:${fileId}`} className={classes.nameColumn}>
        <Box
          id={`folder:${fileId}`}
          style={{
            display: "flex",
            alignItems: "center",
            width: "500px",
          }}
          draggable={permissions?.grantCreateUpdate}
        >
          <IconButton
            color="primary"
            aria-label="list-exhibits"
            component="span"
            size="small"
            onClick={handleListExhibits}
          >
            <FolderIcon />
          </IconButton>
          <Link id={`folder:${fileId}`} className={classes.link} onClick={handleListExhibits}>
            {exhibitName}
          </Link>
        </Box>

        {hasAnyPermissions() && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80px",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="More options" placement="right-start">
              <IconButton
                color="primary"
                aria-label="exhibit-menu"
                component="span"
                size="small"
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Menu
        id="folder-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {permissions?.grantCreateUpdate && (
          <MenuItem onClick={handleEditFolder}>
            <ListItemIcon>
              <EditIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        )}

        {permissions?.grantDownload && (
          <MenuItem
            onClick={handleDownloadFolder}
            divider={isDeleteEnabled && permissions?.grantDelete}
          >
            <ListItemIcon>
              <CloudDownloadIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </MenuItem>
        )}

        {isDeleteEnabled && permissions?.grantDelete && (
          <MenuItem onClick={handleDelete} disabled={isUnderFinalExhibit}>
            <ListItemIcon>
              <DeleteIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
FolderMenuButton.defaultProps = {
  isDeleteEnabled: true,
};
export default FolderMenuButton;
