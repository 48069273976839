import React, { useEffect, useState } from "react";
import { useHistory, useParams, Route, Switch } from "react-router-dom";
// import { findDOMNode } from 'react-dom'
import { Box } from "@mui/material";
import ReactPlayer from "react-player/lazy";
// import screenfull from "screenfull";
import { useRecordingsService } from "../../../services/RecordingsService";

const RecordingPlayer = (props) => {
  const { recordingId } = useParams();
  const { playRecording } = useRecordingsService();
  const [recordingUrl, setRecordingUrl] = useState();
  const [forbidden, setForbidden] = useState(false);
  const [recordingNotFound, setRecordingNotFound] = useState(false);

  React.useEffect(() => {
    // console.log("XXX RecordingPlayer", recordingId);
    const initPlayer = async () => {
      try {
        console.log("Fetching recording information...");
        const recording = await playRecording(recordingId);
        console.log("recording returned.", recording);
        setRecordingUrl(recording.s3Url);
      } catch (error) {
        console.log("Error detected while trying to play recording!", error);
        if (error?.response?.status === 403) {
          setForbidden(true);
        }
        if (error?.response?.status === 404) {
          setRecordingNotFound(true);
        }
      }
    };
    initPlayer();
  }, []);

  return (
    <Box
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        margin: 0,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!forbidden && <ReactPlayer url={recordingUrl} controls={true} height="100%" width="100%" />}
      {forbidden && (
        <Box
          style={{
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/error_403.jpeg" height="25%" width="25%" />
          <br />
          <br />
          <h2 style={{ width: "500px", textAlign: "center" }}>
            Unfortunately, you do not have permissions to view this recording.
            <br />
            <br />
            Please contact Huseby Support for assistance.
          </h2>
        </Box>
      )}
      {recordingNotFound && (
        <Box
          style={{
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "400px",
          }}
        >
          <img src="/images/error_404.jpg" height="25%" width="25%" />
          <br />
          <br />
          <h2
            style={{
              width: "500px",
              textAlign: "center",
              textAlignVertical: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Unfortunately, this recording is deleted or not available.
          </h2>
        </Box>
      )}
    </Box>
  );
};

export { RecordingPlayer };
